.drive-drawer-container{
    float: left;
    position: relative;
    width: 100%;
    padding-top: 20px;
}

@media(max-width: 959px) {
    .drive-drawer-container {
        padding-top: 60px;
        width: 280px;
    }
}

@media(max-width: 350px) {
    .drive-drawer-container {
        padding-top: 60px;
        width: 75vw;
        min-width: 210px;
    }
}

.drive-user-info{
    float: left;
    position: relative;
    width: 100%;
    padding: 23px 20px;
    height: 95px;
    border-bottom: rgba(200, 199, 204, 0.29) 1px solid;
    margin-bottom: 20px;
}

.drive-user-profile{
    float: left;
    position: relative;
    width: 50px;
    height: 50px;
    background-repeat: no-repeat;
    background-size: 100%;
    border: 2px solid white;
    background-position: center;
    border-radius: 50%;
}

.drive-user-name{
    float: left;
    position: relative;
    width: calc(100% - 60px);
    height: 100%;
    align-items: center;
    display: flex;
    padding-left: 20px;
    font-size: 20px;
}

.drive-link-container{
    float: left;
    position: relative;
    width: 100%;
    height: 60px;
    align-items: center;
    display: flex;
    padding: 23px 20px;
    cursor: pointer;
    color: #676767;
}

.drive-link-container a{
    color: #676767;
}

.drive-link-container.selected {
    font-weight: bold;
    color: black!important;
}

.drive-link-container.selected a{
    font-weight: bold;
    color: black!important;
}

.drive-link-container:hover{
    color: black!important;
}

.drive-link-container:hover a{
    color: black!important;
}

.drive-link-logo{
    margin-left: 15px;
    margin-right: 33px;
}

.drive-link {
    font-size: 18px;
}

.drive-content {
    float: left;
    position: relative;
    padding: 13px;
    width: calc(100% - 250px);
}

@media (max-width: 960px) {
    .drive-content {
        width: 100%;
    }
}

.drive-drawer-container a:focus {
    color: black;
}

.drive-topbar-container {
    float: left;
    /*position: relative;*/
    width: 100%;
    background-color: #ffffff;
    height: 70px;
    z-index: 199;
    padding: 0;
    position: fixed;
    top: 0;
    box-shadow: 0 1px 10px 0 rgba(0,0,0,.1);
}

.topbar-container {
    position: relative;
    float: left;
    width: 100%;
}

.topbar-menu-button {
    position: relative;
    float: left;
    margin: 10px 0 0 15px!important;
}

.account-drawer-container {
    height: 97vh;
}

.tags-section-container {
    height: calc(100% - 355px);
    max-height: 50vh;
    overflow: hidden;
    margin: 0 10px;
}

.tags-container {
    position: relative;
    float: left;
    height: 62%;
    width: 100%;
    height: 35vh;
    overflow-y: scroll;
}

@media (min-width:769px) {
    .file-container {
        display: grid;
        width: 100%;
        grid-gap: 10px;
        grid-template-columns: repeat(3, 33%);
    }
}

@media (min-width:450px) AND (max-width: 768px) {
    .file-container {
        display: grid;
        width: 100%;
        grid-gap: 10px;
        grid-template-columns: repeat(2, 50%);
    }
}
.file-uploader-tags-container{
    position: relative;
    float: left;
    max-height: 50vh;
    width: 100%;
    min-height: 30vh;
}

.clear-share {
    position: absolute;
    top: 27px;
    left: 107px;
    color: red;
    cursor: pointer;
}

.clear-share:hover {
    background: rgba(145, 5, 0, 0.47);
    height: 18px;
    width: 18px;
    border-radius: 50%;
}

.clear-share > svg {
    font-size: 19px!important;
}

.quota-chart {
    margin-left: calc(100% - 242px);
}

@media (max-width:960px) {
    .quota-chart {
        margin-left: calc(100% - 267px);
    }
}

.mt-5 {
    text-align: center;
    margin-left: 15px;
}

@media (max-width:960px) {
    .mt-5 {
        /*margin-left: -17px;*/
    }
}