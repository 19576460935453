/* ------------------------------------------------------- */
/* -------------- COURSES ------------------------------- */
/* ------------------------------------------------------- */
@media (max-width: 600px) {
  .items-content {
    max-height: 185px!important;
  }

  .items-content > .info .title {
    -webkit-line-clamp: 4;
    -moz-line-clamp: 4;
    -ms-line-clamp: 4;
    -o-line-clamp: 4;
    line-clamp: 4;
  }

  .items-content > .info > .text{
    display: none!important;
  }

  .items-content > .photo-container > .photo {
    top: 0px!important;
    bottom: 0px!important;
    height: 200px;
  }

  .items-content > .icons > .star.active > svg polygon {
    display: block;
    fill: var(--title-hover, #FFFFFF);
  }
}

.event-dates {
  position: absolute;
  bottom: 20px;
  left: 40px;
  height: 35px;
}

.items-content {
  position: relative;
  float: left;
  width: 100%;
  max-height: 275px;
  display: flex;
  flex-direction: row;
  overflow: hidden;
  cursor: pointer;
  z-index: 1;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.items-content:before {
  pointer-events: none;
  content:'';
  position: absolute;
  z-index: 1;
  top: 0%;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(var(--card-hover-rgb, 0,0,0), 0.7);
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}


.items-content > .photo-container {
  position: relative;
  float: left;
  width: 450px;
  height: 250px;
  overflow: hidden;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.items-content > .photo-container > .photo {
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  z-index: 1;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.items-content:hover .photo-container > .photo{
  transform: scale(1.3)
}

.items-content > .info {
  position: relative;
  width: 100%;
  z-index: 2;
  padding: 20px;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  visibility: visible;
  font-weight: bold;
  white-space: initial;
  display: flex;
  flex-direction: row;
}

.items-content > .info  .data {
  width: 50%;
}

@media (max-width: 1000px) {
  .items-content > .info {
    flex-direction: column;
    height: 250px;
    overflow: hidden;
  }
  .items-content > .info  .data {
    width: 100%;
  }
}

@media (max-width: 800px) {
  .items-content > .info .title {
    padding: 5px 10px 5px 10px;
    font-size: 14px;
  }

  .items-content > .info .section {
    font-size: 10px;
  }

  .items-content > .info .author {
    font-size: 10px;
  }
}

@media (max-width: 700px) and (min-width: 501px) {
  .items-content > .info {
    height: 185px;
  }
  .items-content > .info  .data {
    width: 100%;
    height: 185px;
  }
  .items-content > .photo-container {
    height: 185px;
    width: 300px;
  }
  .items-content > .photo-container .photo {
    height: 185px;
    width: 185px;
  }
}

@media (max-width: 500px) {
  .items-content > .info {
    height: 185px;
  }
  .items-content > .info  .data {
    width: 100%;
    height: 185px;
  }
  .items-content > .info   {
    background-color: rgba(0, 0, 0, 0.3);
    padding: 0 20px;
  }
  .items-content > .photo-container {
    position: absolute;
    width: 100%;
  }
}

.items-content > .info .title {
  display: none;
  position: relative;
  float: left;
  width: 100%;
  padding: 5px 20px 5px 20px;
  font-size: 20px;
  font-weight: bold;
  color: var(--title-hover);
  text-align: left;
  line-height: initial;
  /* autoprefixer: off */
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 5;
  -moz-line-clamp: 5;
  -ms-line-clamp: 5;
  -o-line-clamp: 5;
  line-clamp: 5;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
}

.items-content > .info .section {
  display: block;
  position: relative;
  float: left;
  font-weight: 100;
  width: 100%;
  padding: 5px 20px 5px 20px;
  font-size: 12px;
  color: var(--title-hover);
  text-align: left;
  line-height: initial;
  /* autoprefixer: off */
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -moz-line-clamp: 2;
  -ms-line-clamp: 2;
  -o-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.items-content > .info .author {
  display: block;
  position: relative;
  float: left;

  width: 100%;
  padding: 5px 20px 5px 20px;
  font-size: 12px;
  font-weight: bold;
  color: var(--title-hover);
  text-align: left;
  line-height: initial;
  /* autoprefixer: off */
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -moz-line-clamp: 2;
  -ms-line-clamp: 2;
  -o-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.items-content > .info .text{
  width: 100%;
  padding: 10px 20px 20px 20px;
  display: none;
  position: relative;
  float: left;
  font-size: 13px;
  color: var(--text-hover);
  text-align: left;
  font-weight: 100;
  overflow: hidden;
  height: 180px;
  opacity: 1;
  display: -webkit-box;
  line-height: 1.2em;
  /* autoprefixer: off */
  -webkit-line-clamp: 8;
  line-clamp: 8;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
}

.items-content > .info .text > p {
  line-height: 30px;
}



.items-content > .icons {
  position: absolute;
  right: 20px;
  width: 150px;
  text-align: right;
  transition: all .4s ease-in-out;
  z-index: 3;
  bottom: 20px;
}
.items-content:hover > .icons {
  opacity: 1;
  visibility: visible;
  bottom: 20px;
  transform: translateY(0);
}

.items-content > .icons > .star {
  position: relative;
  float: right;
  z-index: 3;
  width: 30px;
  margin-left: 10px;
  cursor: pointer;
  transition: all .4s ease-in-out;
}

.items-content > .icons > .star > svg polygon {
  display: none;
}
.items-content > .icons > .star > svg path {
  fill: var(--title-hover, #FFFFFF);
}

@media (min-width: 600px) {
  .items-content > .icons > .star:hover > svg polygon,
  .items-content > .icons > .star.active > svg polygon {
    display: block;
    fill: var(--title-hover, #FFFFFF);
  }
}

.items-content > .icons > .list {
  position: relative;
  float: right;
  z-index: 3;
  width: 30px;
  margin-left: 10px;
  cursor: pointer;
  transition: all .4s ease-in-out;
}

.items-content > .icons > .list > svg path {
  fill: var(--title-hover, #FFFFFF);
}
.items-content > .icons > .list:hover > svg path {
  stroke: var(--title-hover, #FFFFFF);;
}

.limit-reached {
  position: absolute;
  z-index: 10;
  right: 5px;
  fill: var(--title-hover, #FFFFFF);
  width: 40px;
  height: 40px;
}