
.home-banner-background-image {
  height: inherit;
  position: absolute;
  width: 100%;
  overflow: hidden;
  max-height: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.home-banner-video {
  position: relative;
  /* height: calc(100vh - 65px); */
  height: calc(100vw * 9 / 16);
  min-height: 300px;
  /* margin-top: 25px; */
}

.home-banner-video-half-height {
  height: calc(50vw * 9 / 16)!important;
}

.home-banner-video ul {
  list-style: none;
  padding: 0;
  background: transparent !important;
  height: inherit;
  margin-top: 0;
  margin: 0;
}

.home-banner-video ul .item-home-banner {
  height: inherit;
  position: absolute;
  width: 100%;
  overflow: hidden;
  max-height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.home-banner-video ul .item-home-banner .video-caption {
  color: lightgray;
  text-align: center;
  display: inline-block;
  text-decoration: none;
  background: transparent !important;
  transition: .6s background;
  bottom: 0;
  top: 0;
  position: absolute;
  z-index: 20 !important;
  border-right: 1px solid rgba(255, 255, 255, 0.25);
}

@media screen and (max-width: 767px) {
  .home-banner-video {
    max-height: 400px;
    /* height: calc(100vw / 1.61)!important; */
    margin-top: 0px!important;
    min-height: 200px;
  }

  .default-home-banner-video-button {
    height: 35px!important;
    width: 35px!important;
    position: absolute;
    /* top: calc(100vh - 50% - 150px - 50px); */
    top: 10px!important;
    right: 10px!important;
    border: solid 2px grey;
    border-radius: 50%;
    cursor: pointer;
    background: grey;
    opacity: .7;
  }

  .default-home-banner-video-button > .default-home-banner-video-icon {
    margin-top: 5px!important;
    font-size: 20px!important;
  }

  .slick-slider:hover .banner-arrow {
    display: block!important;
    transform: translate(0px, -55%)!important;
  }
  .home-banner-item:hover .over-action {
    margin-bottom: 0px!important;
  }
}

@media screen and (min-width: 767px) and (max-width: 991px) {
  .home-banner-video {
    /* height: calc(100vw / 1.77)!important; */
    margin-top: 0px!important;
    min-height: 200px;
  }

  .home-banner-item:nth-child(n+4) > li .video-caption {
    display: none !important;
  }

  .home-banner-item:nth-child(1) > li .video-caption {
    left: 0 !important;
  }

  .home-banner-item:nth-child(2) > li .video-caption {
    left: 33.3% !important;
  }

  .home-banner-item:nth-child(3) > li .video-caption {
    left: 66.6% !important;
  }

  .video-caption {
    width: 33.3% !important;
  }
}

@media screen and  (min-width: 550px) and (max-width: 766px) {
  .home-banner-item:nth-child(n+3) > li .video-caption {
    display: none !important;
  }

  .home-banner-item:nth-child(1) > li .video-caption {
    left: 0 !important;
  }

  .home-banner-item:nth-child(2) > li .video-caption {
    left: 50% !important;
  }

  .video-caption {
    width: 50% !important;
  }
}

@media screen and  (max-width: 550px) {
  .home-banner-item:nth-child(n+2) > li .video-caption {
    display: none !important;
  }

  .home-banner-item:nth-child(1) > li .video-caption {
    left: 0 !important;
  }

  .video-caption {
    width: 100% !important;
  }
}

.home-banner-video ul .item-home-banner:not(:last-child) .home-banner-video-title {
  border-right: 1px solid rgba(255, 255, 255, 0.25);
}

.home-banner-video ul .item-home-banner .video-caption .home-banner-video-title {
  height: 100%;
  transition: .5s;
  position: relative;
}

.home-banner-video ul .item-home-banner .video-caption .home-banner-video-title .home-banner-video-title-content {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 10px;
  transition: .5s;
}

@media (max-width: 769px) {
  .home-banner-video ul .item-home-banner .video-caption .home-banner-video-title .home-banner-video-title-content {
    bottom: 10px!important
  }
}

.home-banner-video ul .item-home-banner .video-caption .home-banner-video-title .home-banner-video-title-content .home-banner-title {
  color: rgba(255, 255, 255, 0.5);
  margin-top: 0;
  font-size: 1.4375rem;
}

.home-banner-video ul .item-home-banner .video-caption .home-banner-video-title .home-banner-video-title-content .home-banner-subtitle {
  color: rgba(255, 255, 255, 0.5);
  margin-bottom: 15px;
  margin-top: 0;
  font-size: 1.1875rem;
}

.item-home-banner:hover > .opacity-home-banner {
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: #000;
  opacity: 0.5;
  z-index: 15;
}

.video-caption:hover > .home-banner-video-title .home-banner-video-title-content {
  margin-bottom: 30px;
}

.home-banner-video ul .item-home-banner .video-caption .home-banner-video-title:hover .home-banner-title, .home-banner-video ul .item-home-banner .video-caption .home-banner-video-title:hover .home-banner-subtitle small {
  color: white;
  text-shadow: 1px 0px 0px #969696;
}

.home-banner-video ul .item-home-banner .video-caption .home-banner-video-title .home-banner-video-title-content .home-banner-video-link {
  opacity: 0;
  transition: .3s;
  border: 2px solid rgba(255, 255, 255, 0.25);
  border-radius: 2px;
  color: rgba(255, 255, 255, 0.5);
  padding: 10px 15px 7px;
  margin-top: 10px;
  text-decoration: none;
  cursor: pointer;
  font-weight: bold;
  font-size: 12px;
}

.home-banner-video ul .item-home-banner .video-caption .home-banner-video-title .home-banner-video-title-content .home-banner-video-link:hover {
  background-color: white;
  color: black;
}


.home-banner-background-image {
  height: inherit;
  position: absolute;
  width: 100%;
  overflow: hidden;
  max-height: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.default-home-banner-video {
  min-width: 100%;
  min-height: 100%;
  left: 50%;
  position: absolute;
  top: 50%;
  width: 450px !important;
  height: auto !important;
  background-size: cover;
  transition: 1s opacity;
  z-index: -1;
  transform: translate(-50%, -50%);
}

.default-home-banner-video-button {
  height: 50px;
  width: 50px;
  position: absolute;
  /* top: calc(100vh - 50% - 150px - 50px); */
  top: 30px;
  right: 30px;
  border: solid 2px grey;
  border-radius: 50%;
  cursor: pointer;
  background: grey;
  opacity: .7;
}

.default-home-banner-video-container {
  width: 100%!important;
}

.default-home-banner-cover {
  float: left;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  z-index: 12;
}

.default-home-banner-cover:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  /* background-color: rgba(0, 0, 0, 0.3); */
}

.default-home-banner-video-icon {
  color: white!important;
  font-size: 24px!important;
  margin-top: 10px!important;
}

@media (max-width: 769px) {
  .home-banner-subtitle small {
    color: white;
    text-shadow: 1px 0px 0px #969696;
  }
  .home-banner-title  {
    color: rgba(255,255,255,1) !important;
    text-shadow: 1px 0px 0px #969696;
  }
}


.over-home-banner-video {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  width: 100%;
  height: 0;
  transition: .5s ease;
}

.home-banner-item:hover  .over-home-banner-video {
  height: 30%;
  z-index: 1000;
}

.over-action {
  position: absolute;
  bottom: 0;
  right: 0;
  left: calc(50% - 20px);
  overflow: hidden;
  width: 100%;
  height: 0;
  transition: .5s ease;
  color: white;
}

.home-banner-item:hover  .over-action {
  height: 50%;
  z-index: 1000;
  left: calc(50% - 20px);
}


.default-home-banner-link-button {
  background: white;
  cursor: pointer;
  z-index: 100;
  color: black;
  display: none;
  padding: 2px 4px;
  -webkit-box-shadow: 6px 9px 18px -8px rgba(0,0,0,0.75);
  -moz-box-shadow: 6px 9px 18px -8px rgba(0,0,0,0.75);
  box-shadow: 6px 9px 18px -8px rgba(0,0,0,0.75);
}

.default-home-banner-link-button:hover {
  color: black;
  font-weight: 800;
}

.home-banner-item:hover .default-home-banner-link-button {
  display: flex!important;
  flex-direction: row;
}

@media (max-width: 769px) {
  .home-banner-video ul .item-home-banner .video-caption .home-banner-video-title .home-banner-video-title-content {
    bottom: 10px!important
  }
  .default-home-banner-link-button {
    display: flex !important;
    flex-direction: row;
  }
}
.home-banner-item:hover  .over-home-banner-video {
  height: 30%;
  z-index: 1000;
}

.home-banner-title-image-banner {
  font-size:40px;
}
.home-banner-subtitle-image-banner {
  font-size: 28px;
}

.banner-link-button {
  position: relative;
  float: left;
  min-width: 100px;
  border-radius: 30px;
  z-index: 100;
  -webkit-box-shadow: 6px 9px 18px -8px rgba(0,0,0,0.75);
  -moz-box-shadow: 6px 9px 18px -8px rgba(0,0,0,0.75);
  box-shadow: 6px 9px 18px -8px rgba(0,0,0,0.75);
  display: flex;
  align-items: center;
}

.banner-link-button-square{
  border-radius: 3px!important;
}

.banner-link-button:hover {
  font-weight: bold;
}

.banner-top-center-link-button-container {
  position: absolute;
  top: 30px;
  display: flex;
  width: 100%;
  justify-content: center;
}

@media (max-width: 769px) {
  .banner-top-center-link-button-container {
    top: 10px!important;
  }
}