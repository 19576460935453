.smart-search-tag-column.horizontal {
  width: 100% !important;
}

.smart-search-tag-containter.horizontal {
  width: auto!important;
}
.smart-search-tag.horizontal{
  width: auto!important;
  margin-left: 10px!important;
  margin-right: 10px!important;
}

.slider-with-background,
.mobile-modal-slider-with-background {
  width: calc(100vw - 280px);
}

.modal-slider-with-background {
  width : calc(100% - 70px);
}

@media (max-width: 600px) {
  .smart-search-tag-column {
    width: 100% !important;
  }

  .smart-search-tag-containter {
    width: auto!important;
  }
  .smart-search-tag {
    width: auto!important;
    margin-left: 10px!important;
    margin-right: 10px!important;
  }
  .slider-with-background {
    width: calc(100vw - 140px);
  }
  .mobile-modal-slider-with-background {
    width: calc(100% - 70px);
  }
}

