.audiovisual-tag-selector-search::placeholder {
    color: var(--secondary-font-color, rgba(0,0,0,0.6))!important;
}

.blurred-container {
    -webkit-filter: blur(10px);
    -moz-filter: blur(10px);
    -o-filter: blur(10px);
    -ms-filter: blur(10px);
    filter: blur(10px);
}

.content-creation-overlay-container {
    position: absolute;
    width: calc(100% - 60px);
    height: 100%;
    padding: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 3;
}
  
.content-creation-inappropriate {
    background-color: var(--main-color, white);
    color: var(--main-font-color, black);
    display: flex;
    flex-direction: column;
    font-size: 24px;
    padding: 30px;
    align-items: center;
    border-radius: 10px;
    max-width: 70%;
}
  
.content-creation-inappropriate > p {
    margin-bottom: 20px!important;
}
  
.content-creation-inappropriate-buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
}
  
@media (max-width: 650px) {  
    .content-creation-overlay-container {
        width: 100%;
        padding: 0px;
    }

    .content-creation-inappropriate {
      font-size: 10px;
      max-width: 80%;
      padding: 10px;
      margin-top: 10px;
    }
  
    .content-creation-inappropriate > p {
      margin-bottom: 0px!important;
    }
  
    .content-creation-inappropriate-button {
      font-size: 8px!important;
      padding: 4px 10px!important;
      margin-bottom: 0px!important;
    } 
  
    .content-creation-inappropriate-buttons {
      flex-direction: column;
    }
}