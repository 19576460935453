.soundbar {
  padding: 5px 3%;
  opacity: 1;
  visibility: visible;
  transition: 1s;
}

.soundbar.hide {
  padding: 0;
  opacity: 0;
  visibility: hidden;
}

.soundbar-button {
  cursor: pointer;
}

.soundbar-button:hover {
  animation: soundbar-button-on-hover 100ms forwards
}

@keyframes soundbar-button-on-hover {
  form { transform: scale(1.0) }
  to { transform: scale(1.05) }
}