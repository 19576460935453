@font-face {
    font-family: "avenir-font";
    src: url("/static/fonts/avenir-font.woff") format("woff");
}

@font-face {
    font-family: "gotham-font";
    src: url("/static/fonts/gotham-font.woff") format("woff");
}

@font-face {
    font-family: "gotham_medium-font";
    src: url("/static/fonts/gotham_medium-font.woff") format("woff");
}

@font-face {
    font-family: "gotham_book-font";
    src: url("/static/fonts/gotham_book-font.woff") format("woff");
}

@font-face {
    font-family: "gotham_light-font";
    src: url("/static/fonts/gotham_light-font.woff") format("woff");
}

@font-face {
    font-family: "roboto-font";
    src: url("/static/fonts/roboto-font.woff") format("woff");
}

@font-face {
    font-family: "baskerville-font";
    src: url("/static/fonts/baskerville-font.woff") format("woff");
}

@font-face {
    font-family: "bodoni-font";
    src: url("/static/fonts/bodoni-font.woff") format("woff");
}

@font-face {
    font-family: "encode-font";
    src: url("/static/fonts/encode-font.woff") format("woff");
}

@font-face {
    font-family: "helvetica-font";
    src: url("/static/fonts/helvetica-font.woff") format("woff");
}

@font-face {
    font-family: "malba-font";
    src: url("/static/fonts/malba-font.woff") format("woff");
}

@font-face {
    font-family: "plex-font";
    src: url("/static/fonts/plex-font.woff") format("woff");
}

@font-face {
    font-family: "playfair_bold-font";
    src: url("/static/fonts/playfair_bold-font.woff") format("woff");
}

@font-face {
    font-family: "playfair-font";
    src: url("/static/fonts/playfair-font.woff") format("woff");
}

@font-face {
    font-family: "boing-font";
    src: url("/static/fonts/boing-font.woff") format("woff");
}

@font-face {
    font-family: "syne-font";
    src: url("/static/fonts/syne-font.woff") format("woff");
}

@font-face {
    font-family: "syne-extra-bold-font";
    src: url("/static/fonts/syne-extra-bold-font.woff") format("woff");
}

@font-face {
    font-family: "syne-bold-font";
    src: url("/static/fonts/syne-bold-font.woff") format("woff");
}

@font-face {
    font-family: "futura_pt-font";
    src: url("/static/fonts/futura_pt-font.woff") format("woff");
}

@font-face {
    font-family: "futura_pt_book-font";
    src: url("/static/fonts/futura_pt_book-font.woff") format("woff");
}

@font-face {
    font-family: "morganite-medium-font";
    src: url("/static/fonts/morganite-medium-font.woff") format("woff");
}

@font-face {
    font-family: "monument-extended-ultrabold-font";
    src: url("/static/fonts/monument-extended-ultrabold-font.woff") format("woff");
}

@font-face {
    font-family: "monument-extended-regular-font";
    src: url("/static/fonts/monument-extended-regular-font.woff") format("woff");
}

@font-face {
    font-family: "calluna-font";
    src: url("/static/fonts/calluna-font.woff") format("woff");
}

@font-face {
    font-family: "knockout-sumo-font";
    src: url("/static/fonts/knockout-sumo-font.woff") format("woff");
}

@font-face {
    font-family: "merriweather-font";
    src: url("/static/fonts/merriweather-font.woff") format("woff");
}

@font-face {
    font-family: "heebo-font";
    src: url("/static/fonts/heebo-font.woff") format("woff");
}

@font-face {
    font-family: "exo-font";
    src: url("/static/fonts/exo-font.woff") format("woff");
}

@font-face {
    font-family: "bungee-font";
    src: url("/static/fonts/bungee-font.woff") format("woff");
}

@font-face {
    font-family: "galano-grotesque-bold-font";
    src: url("/static/fonts/galano-grotesque-bold-font.woff") format("woff");
}

@font-face {
    font-family: "galano_grotesque-font";
    src: url("/static/fonts/galano_grotesque-font.woff") format("woff");
}


@font-face {
    font-family: "league_spartan-font";
    src: url("/static/fonts/league_spartan-font.woff") format("woff");
}

@font-face {
    font-family: "lust_regular-font";
    src: url("/static/fonts/lust_regular-font.woff") format("woff");
}


@font-face {
    font-family: "unbounded-font";
    src: url("/static/fonts/unbounded-font.woff") format("woff");
}

@font-face {
    font-family: "dinn_next_lt_bold-font";
    src: url("/static/fonts/dinn_next_lt_bold-font.woff") format("woff");
}

@font-face {
    font-family: "dinn_next_lt_regular-font";
    src: url("/static/fonts/dinn_next_lt_regular-font.woff") format("woff");
}

@font-face {
    font-family: "ralaway_bold-font";
    src: url("/static/fonts/ralaway_bold-font.woff") format("woff");
}

@font-face {
    font-family: "sf-font";
    src: url("/static/fonts/sf-font.woff") format("woff");
}

@font-face {
    font-family: "sf-rounded-font";
    src: url("/static/fonts/sf-rounded-font.woff") format("woff");
}

@font-face {
    font-family: "ralaway_italic-font";
    src: url("/static/fonts/ralaway_italic-font.woff") format("woff");
}

@font-face {
    font-family: "inter-font";
    src: url("/static/fonts/inter-font.woff") format("woff");
}

@font-face {
    font-family: "soho-heavy-font";
    src: url("/static/fonts/soho-heavy-font.woff") format("woff");
}

@font-face {
    font-family: "collegiate-font";
    src: url("/static/fonts/collegiate-font.woff") format("woff");
}

@font-face {
    font-family: "akzidenz-grotesk-font";
    src: url("/static/fonts/akzidenz-grotesk-font.woff") format("woff");
}

@font-face {
    font-family: "montserrat_bold-font";
    src: url("/static/fonts/montserrat_bold-font.woff") format("woff");
}

@font-face {
    font-family: "montserrat_regular-font";
    src: url("/static/fonts/montserrat_regular-font.woff") format("woff");
}

@font-face {
    font-family: "montserrat_semibold-font";
    src: url("/static/fonts/montserrat_semibold-font.woff") format("woff");
}



.avenir-font {
    font-family: "avenir-font", serif!important;
}

.roboto-font {
    font-family: "roboto-font", sans-serif!important;
}

.gotham-font {
    font-family: "gotham-font", serif!important
}

 .gotham_medium-font {
     font-family: "gotham_medium-font", serif!important
 }

 .gotham_book-font {
    font-family: "gotham_book-font", serif!important
}

.gotham_light-font {
    font-family: "gotham_light-font", serif!important
}

.baskerville-font {
    font-family: "baskerville-font", serif!important
}

.bodoni-font {
    font-family: "bodoni-font", serif!important
}

.malba-font {
    font-family: "malba-font", serif!important
}

.helvetica-font {
    font-family: "helvetica-font", serif!important
}

.encode-font {
    font-family: "encode-font", serif!important
}

.plex-font {
    font-family: "plex-font", serif!important
}

.playfair_bold-font {
    font-family: "playfair_bold-font", serif!important
}

.playfair-font {
    font-family: "playfair-font", serif!important
}

.boing-font {
    font-family: "boing-font", serif!important;
}

.syne-font {
    font-family: "syne-font", serif!important;
}

.syne-bold-font {
    font-family: "syne-bold-font", serif!important;
}

.syne-extra-bold-font {
    font-family: "syne-extra-bold-font", serif!important;
}

.calluna-font {
    font-family: "calluna-font", serif!important;
}

.knockout-sumo-font {
    font-family: "knockout-sumo-font", serif!important;
}

.futura_pt-font {
    font-family: "futura_pt-font", serif!important;
}

.futura_pt_book-font {
    font-family: "futura_pt_book-font", serif!important;
}

.morganite-medium-font {
    font-family: "morganite-medium-font", serif!important;
}

.monument-extended-ultrabold-font {
    font-family: "monument-extended-ultrabold-font", serif!important;
}

.monument-extended-regular-font {
    font-family: "monument-extended-regular-font", serif!important;
}

.bungee-font {
    font-family: "bungee-font", serif!important;
}


 .galano-grotesque-bold-font {
     font-family: "galano-grotesque-bold-font", serif!important;
 }

.galano_grotesque-font {
    font-family: "galano_grotesque-font", serif!important;
}

.exo-font {
    font-family: "exo-font", serif!important;
}

.heebo-font {
    font-family: "heebo-font", serif!important;
}

.merriweather-font {
    font-family: "merriweather-font", serif!important;
}

.unbounded-font {
    font-family: "unbounded-font", serif!important;
}

.league_spartan-font {
    font-family: "league_spartan-font", serif!important;
}

.lust_regular-font {
    font-family: "lust_regular-font", serif!important;
}

.dinn_next_lt_bold-font {
    font-family: "dinn_next_lt_bold-font", serif!important;
}

.dinn_next_lt_regular-font {
    font-family: "dinn_next_lt_regular-font", serif!important;
}

.ralaway_italic-font {
    font-family: "ralaway_italic-font", serif!important;
}

.ralaway_bold-font {
    font-family: "ralaway_bold-font", serif!important;
}

.sf-font {
    font-family: "sf-font", serif!important;
}

.sf-rounded-font {
    font-family: "sf-rounded-font", serif!important;
}

.inter-font {
    font-family: "inter-font", serif!important;
}

.soho-heavy-font {
    font-family: "soho-heavy-font", serif!important;
}

.collegiate-font {
    font-family: "collegiate-font", serif!important;
}

.akzidenz-grotesk-font {
    font-family: "akzidenz-grotesk-font", serif!important;
}

.montserrat_bold-font {
    font-family: "montserrat_bold-font", serif!important;
}

.montserrat_regular-font {
    font-family: "montserrat_regular-font", serif!important;
}

.montserrat_semibold-font {
    font-family: "montserrat_semibold-font", serif!important;
}