.spotlight-container {
  float: left;
  position: relative;
  text-align: center;
  width: 100%;
  padding-left: 100px;
  padding-right: 100px;
}

@media (max-width: 1200px) {
  .spotlight-container {
    padding-left: 50px;
    padding-right: 50px;
  }
}

@media (max-width: 800px) {
  .spotlight-container {
    padding-left: 30px;
    padding-right: 30px;
  }
}

.spotlight-container h3 {
  margin-top: 40px;
  margin-bottom: 40px;
  font-family: Warnock, serif!important;
}

.spotlight-title {
  font-weight: 600;
  font-family: Warnock, serif!important;
}

.spotlight-video-container {
  float: left;
  position: relative;
  width: 100%;
  background-color: rgba(30, 30, 30, 0.04);
  margin-bottom: 20px;
}

.spotlight-video {
  float: left;
  position: relative;
  width: 50%;
  text-align: center;
}

.original-music-video-wrapper {
  display: inline-block;
  max-width: 600px;
  height: 350px;
  align-items: center;
  margin: auto;
}

@media (max-width: 800px) {
  .original-music-video-wrapper {
    height: auto;
  }
}

.spotlight-text-right {
  float: left;
  width: 50%;
  padding: 20px;
  text-align: left;
  position: relative;
}

@media (min-width: 800px) {
  .spotlight-text-right .see-more p {
    display: block!important;
  }

  .spotlight-text-right p{
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 13;
    /* autoprefixer: off */
    -webkit-box-orient: vertical;
    /* autoprefixer: on */
    text-overflow: ellipsis;
  }

  .spotlight-text-left .see-more p {
    display: block!important;
  }

  .spotlight-text-left p{
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 13;
    /* autoprefixer: off */
    -webkit-box-orient: vertical;
    /* autoprefixer: on */
    text-overflow: ellipsis;
  }
}


.spotlight-text-left {
  float: right;
  position: relative;
  width: 50%;
  padding: 20px;
  text-align: right;
}

@media (max-width: 800px) {
  .spotlight-video {
    width: 100%;
  }

  .spotlight-text-right {
    float: left;
    position: relative;
    width: 100%;
    text-align: center;
  }

  .spotlight-text-left {
    width: 100%;
    text-align: center;
  }
}

.spotlight-container .card-grid-pagination-root {
  overflow: initial!important;
}
