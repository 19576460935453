@font-face {
  font-family: "Warnock";
  src: url("../../assets/fonts/Warnock.woff") format("woff");
}

@font-face {
  font-family: "Warnock-bold";
  src: url("../../assets/fonts/WarnockBold.woff") format("woff");
}


@font-face {
  font-family: "Thorne-Normal";
  src: url("../../assets/fonts/Thorne-Normal.woff") format("woff");
}

html {
  scroll-behavior: smooth;
}

.material-icons {
  font-family: 'Material Icons'!important;
}

::placeholder {
  color: lightgrey !important;
}

.coverflow__text__TAisD {
  display: none;
}

@media (max-width: 991px) {
  body, html {
    position: initial !important;
    overflow-x: hidden !important;
  }
}

.footer-logo {
  display: inline-flex;
  place-self: center;
  margin-left: -50px;
}

@media(max-width: 900px) {
  .footer-logo{
    display: none!important;
  }
}

.navbar {
  background-color: #ffffff;
  padding: 1.5625rem 0 1.375rem 0;
  position: fixed;
  top: 0;
  width: 25%;
  min-width: 300px;
  z-index: 200;
  height: 80px;
  transition: .25s ease-in-out;
}

.navbar-sm {
  background: rgba(255, 255, 255, 0.9);
  padding: 0.4375rem 0 0 0;
  height: 60px;
  transition: .25s ease-in-out;
}

@media (min-width: 1200px) {
  .container {
    width: 1170px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .container {
    width: 970px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {

  .container {
    width: 750px;
  }
}

.navbar-header {
  margin-right: 0;
  margin-left: 0;
  float: left;
}

.navbar-brand {
  margin-left: 15px;
}

.container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.navbar-header {
  margin-right: -15px;
  margin-left: -15px;
}

.navbar-brand {
  width: 190px;
  padding: 10px 10px 10px 10px;
  margin: 0;
  float: left;
}

.navbar-brand img {
  max-width: 100%;
  display: block;
  border: 0;
}

.navbar .navbar-collapse {
  width: 100%;
  text-align: center;
}

.navbar .navbar-collapse ul li a {

}

@media (max-width: 768px) {
  .navbar-collapse {
    display: none
  }
}

@media (min-width: 768px) {
  .container > .navbar-header, .jp-audio .jp-interface > .navbar-header, .jp-playlist li > div:not(.modal) > .navbar-header, .container-fluid > .navbar-header, .container > .navbar-collapse, .jp-audio .jp-interface > .navbar-collapse, .jp-playlist li > div:not(.modal) > .navbar-collapse, .container-fluid > .navbar-collapse {
    margin-right: 0;
    margin-left: 0;
  }

  .container > .navbar-header, .jp-audio .jp-interface > .navbar-header, .jp-playlist li > div:not(.modal) > .navbar-header, .container-fluid > .navbar-header, .container > .navbar-collapse, .jp-audio .jp-interface > .navbar-collapse, .jp-playlist li > div:not(.modal) > .navbar-collapse, .container-fluid > .navbar-collapse {
    margin-right: -15px;
    margin-left: -15px;
  }

  .navbar-fixed-top .navbar-collapse, .navbar-fixed-bottom .navbar-collapse {
    max-height: 340px;
  }
}

@media (min-width: 768px) {
  .navbar-fixed-top .navbar-collapse, .navbar-static-top .navbar-collapse, .navbar-fixed-bottom .navbar-collapse {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 768px) {
  .navbar-collapse.collapse {
    display: block !important;
    height: auto !important;
    padding-bottom: 0;
    overflow: visible !important;
  }

  .collapse {
    visibility: visible;
  }
}

@media (min-width: 768px) {
  .navbar-collapse {
    width: auto;
    border-top: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  .navbar-collapse {
    padding-right: 15px;
    padding-left: 15px;
    overflow-x: visible;
    -webkit-overflow-scrolling: touch;
    border-top: 1px solid transparent;
    -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1);
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1);
  }

  .collapse {
    display: none;
    visibility: hidden;
  }
}

.nav.navbar-nav {
  text-align: center;
  display: inline-block;
  float: none;
}

@media (min-width: 768px) {
  .navbar-nav {
    float: left;
    margin: 0;
  }
}

.navbar-nav {
  margin: 7.5px -15px;
}

.nav {
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

@media (min-width: 768px) {
  .navbar-nav > li {
    float: left;
  }
}

.nav > li {
  position: relative;
  display: block;
}

.navbar .nav.navbar-nav a:hover, .navbar .nav.navbar-nav .nav-item:hover, .navbar .navbar-header a:hover {

  font-weight: bold;
}

.nav > li > a:hover, .nav > li > a:focus {
  text-decoration: none;

}

.nav a:hover, .nav a.active {
  color: #000000;
}

.nav.navbar-nav a, .navbar-header a {
  text-decoration: none;
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
  margin: 0;
}

@media screen and (max-width: 1199px) {
  .nav.navbar-nav a, .nav.navbar-nav .nav-item, .navbar-header a {
    font-size: 0.75rem;
  }
}

.nav.navbar-nav a, .nav.navbar-nav .nav-item, .navbar-header a {
  font-size: 0.75rem;
  font-weight: 400;
  color: #262626;
}

@media (min-width: 768px) {
  .navbar-nav > li > a {
    padding-top: 15px;
    padding-bottom: 15px;
  }
}

.navbar-nav > li > a {
  padding-top: 10px;
  padding-bottom: 10px;
  line-height: 20px;

}

.nav > li > a {
  position: relative;
  display: block;
  padding: 10px 15px;

}

@media (min-width: 768px) {
  .navbar-right {
    float: right !important;
    margin-right: -15px;
  }
}

.nav-icon {
  margin-top: 0;
  transform: rotate(0deg);
  transition: .5s ease-in-out;
  cursor: pointer;
  position: absolute;
  right: 0;
  padding: 9px 10px;
  margin-right: 15px;
  margin-bottom: 8px;
  background-color: transparent;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 4px;
  cursor: pointer;
}

@media (min-width: 768px) {
  .nav-icon {
    display: none;
  }
}

@media (max-width: 768px) {
  .search-icon {
    display: none;
  }
}

.overlay {
  height: 0;
  width: 100%;
  position: fixed;
  z-index: 199;
  left: 0;
  top: 0;
  background-color: #ffffff;
  overflow: hidden;
  transition: 0.5s;
}

.overlay-content {
  position: relative;
  top: 25%;
  width: 100%;
  text-align: center;
}

.overlay a {
  padding: 8px;
  text-decoration: none;
  font-size: 20px;
  display: block;
  transition: 0.3s;
  color: #262626
}

.banner-video {
  position: relative;
  height: 100vh;
  margin-top: 20px;
}

.over-banner-video {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  width: 100%;
  height: 0;
  transition: .5s ease;
}

.over-action {
  position: absolute;
  bottom: 0;
  right: 0;
  left: calc(50% - 20px);
  overflow: hidden;
  width: 100%;
  height: 0;
  transition: .5s ease;
  color: white;
}


.banner-video ul {
  list-style: none;
  padding: 0;
  background: transparent !important;
  height: inherit;
  margin-top: 0;
}

.banner-video ul .item-banner {
  height: inherit;
  position: absolute;
  width: 100%;
  overflow: hidden;
  max-height: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.banner-video ul .item-banner .video-caption {
  color: lightgray;
  text-align: center;
  display: inline-block;
  text-decoration: none;
  background: transparent !important;
  transition: .6s background;
  bottom: 0;
  top: 0;
  position: absolute;
  z-index: 20 !important;
  border-right: 1px solid rgba(255, 255, 255, 0.25);
}

@media screen and (max-width: 767px) {
  .banner-video {
    height: calc(100vw / 1.77)!important;
  }
  .banner-video{
    margin-top: 0px!important;
  }

  .default-banner-video-button {
    height: 50px!important;
    width: 50px!important;
    left: calc(50% - 25px)!important;
    top: calc( calc(50vw / 1.77) - 25px) !important;
  }

  .default-banner-video-icon {
    font-size: 24px!important;
    margin-top: 11px!important;
  }
}

@media screen and (min-width: 767px) and (max-width: 991px) {
  .home-banner-item:nth-child(n+4) > li .video-caption {
    display: none !important;
  }

  .home-banner-item:nth-child(1) > li .video-caption {
    left: 0 !important;
  }

  .home-banner-item:nth-child(2) > li .video-caption {
    left: 33.3% !important;
  }

  .home-banner-item:nth-child(3) > li .video-caption {
    left: 66.6% !important;
  }

  .video-caption {
    width: 33.3% !important;
  }
}

@media screen and  (min-width: 550px) and (max-width: 766px) {
  .home-banner-item:nth-child(n+3) > li .video-caption {
    display: none !important;
  }

  .home-banner-item:nth-child(1) > li .video-caption {
    left: 0 !important;
  }

  .home-banner-item:nth-child(2) > li .video-caption {
    left: 50% !important;
  }

  .video-caption {
    width: 50% !important;
  }
}

@media screen and  (max-width: 550px) {
  .home-banner-item:nth-child(n+2) > li .video-caption {
    display: none !important;
  }

  .home-banner-item:nth-child(1) > li .video-caption {
    left: 0 !important;
  }

  .video-caption {
    width: 100% !important;
  }
}

.banner-video ul .item-banner:not(:last-child) .banner-video-title {
  border-right: 1px solid rgba(255, 255, 255, 0.25);
}

.banner-video ul .item-banner .video-caption .banner-video-title {
  height: 100%;
  transition: .5s;
  position: relative;
}

.banner-video ul .item-banner .video-caption .banner-video-title .banner-video-title-content {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 10px;
  transition: .5s;
}

@media (max-width: 769px) {
  .banner-video ul .item-banner .video-caption .banner-video-title .banner-video-title-content {
    bottom: 10px!important
  }
}



.banner-video ul .item-banner .video-caption .banner-video-title .banner-video-title-content .banner-title {
  color: rgba(255, 255, 255, 0.5);
  margin-top: 0;
  font-size: 1.4375rem;
}

.banner-video ul .item-banner .video-caption .banner-video-title .banner-video-title-content .banner-subtitle {
  color: rgba(255, 255, 255, 0.5);
  margin-bottom: 15px;
  margin-top: 0;
  font-size: 1.1875rem;
}

.item-banner:hover > .opacity-banner {
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: #000;
  opacity: 0.5;
  z-index: 15;
}

.default-banner-video-container:hover > .banner-video-title .banner-video-title-content {
  margin-bottom: 30px;
}

.banner-arrow {
  display: none!important;
  transition: .3s;
}

.slick-slider:hover .banner-arrow {
  transition: .3s;
  display: block!important;
}

.home-banner-item:hover  .over-banner-video {
  height: 30%;
  z-index: 1000;
}

.home-banner-item:hover  .over-action {
  height: 50%;
  z-index: 1000;
  left: calc(50% - 20px);
}

.banner-video ul .item-banner .video-caption .banner-video-title:hover .banner-title, .banner-video ul .item-banner .video-caption .banner-video-title:hover .banner-subtitle small {
  color: white;
  text-shadow: 1px 0px 0px #969696;
}

.banner-video ul .item-banner .video-caption .banner-video-title .banner-video-title-content .banner-video-link {
  opacity: 0;
  transition: .3s;
  border: 2px solid rgba(255, 255, 255, 0.25);
  border-radius: 2px;
  color: rgba(255, 255, 255, 0.5);
  padding: 10px 15px 7px;
  margin-top: 10px;
  text-decoration: none;
  cursor: pointer;
  font-weight: bold;
  font-size: 12px;
}

.banner-video ul .item-banner .video-caption .banner-video-title .banner-video-title-content .banner-video-link:hover {
  background-color: white;
  color: black;
}

.row {
  width: 100%;
  text-align: center;
  background-color: #FFFFFF;
}

.arrow-down {
  margin-bottom: 30px;
  margin-top: 30px;
  font-size: 40px !important;
}

.footer-top {
  background-color: var(--footer-color, #262626);
  padding: 3.125rem 0;
  float: left;
  width: 100%;
  position: relative;
  color: var(--footer-font-color, inherit);
}

.row-footer {
  margin-right: -15px;
  margin-left: -15px;
  float: left;
  position: relative;
}

.footer-top img {
  max-height: 3.75rem;
  width: 140px;
  margin-bottom: 20px;
  vertical-align: middle;
  border: 0;
}

.footer-top h6 {
  font-size: 0.8125rem;
  margin: 0;
}

footer .footer-top ul {
  list-style: none;
}

.nowrap {
  white-space: nowrap;
  float: right;
}

@media (max-width: 768px) {
  .text-left {
    width: 100%;
    float: left;
    position: relative;
  }

  .text-right {
    width: 100%;
    float: right;
    position: relative;
  }
}

@media (min-width: 768px) {
  .text-left {
    width: 60%;
    float: left;
    position: relative;
  }

  .text-right {
    width: 40%;
    float: left;
    position: relative;
  }
}

.category[data-max-per-row="4"] {
  padding-left: 45.5px;
  padding-right: 45.5px;
  padding-bottom: 22.5px;
}

@media (max-width: 600px) {
  .category[data-max-per-row="6"] {
    padding-left: 25px !important;
    padding-right: 25px !important;
    padding-bottom: 22.5px;
  }

  .category[data-max-per-row="4"] {
    padding-left: 0 !important;
    padding-right: 0 !important;
    padding-bottom: 20px;
  }
}

.category {
  text-align: center;
  margin-bottom: 30px;
  padding-top: 0;
  position: relative;
  float: left;
  width: 100%;
}

.category .title {
  margin: 10px 0;
  font-size: 1.6875rem;
}

.bold {
  font-weight: bold;
}

@media screen and (max-width: 830px) and (min-width: 561px) {
  .category[data-max-per-row="4"] .album-grid-cell:not([data-format="carousel"]) {
    width: 33.33333333%;
  }
}

@media screen and (min-width: 831px) {
  .category[data-max-per-row="4"] .album-grid-cell:not([data-format="carousel"]) {
    width: 25%;
  }
}

@media screen and (max-width: 560px) and (min-width: 291px) {
  .category[data-max-per-row="4"] .album-grid-cell:not([data-format="carousel"]) {
    width: 50%;
  }
}

.album-grid-cell {
  float: left;
  position: relative;
}

.opacity1 {
  opacity: 1;
}

article {
  display: block;
}

.category[data-max-per-row="4"] .album-grid-img {
  padding: 30px 22.5px;
  height: 100%;
}

@media (max-width: 470px){
  .category[data-max-per-row="4"] .album-grid-img {
    padding: 12px;
    height: 100%;
  }
}

.category-item {
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, .1), 0 -1px 2px 0 rgba(0, 0, 0, .1)
}

.category-item:hover {
  box-shadow: 0 8px 20px 0 rgba(0, 0, 0, .16);
}

.category-item:hover .album-fav {
  display: block !important;
}

@media (max-width: 470px) {
  .category-item:hover .album-fav {
    display: none!important;
  }
}

.album-fav:hover button {
  color: var(--main-font-color, rgb(0, 0, 0))!important;
}

.album-grid-cell.is-collapsed .album-grid-img {
  cursor: pointer;
}

.category .category-item {
  height: 100%;
  background-color: #e8e8e8;
  position: relative;
  float: left;
}

.basic-img {
  display: block;
  width: 100%;
  height: auto;
  margin: 0 auto;
}

.category .category-item .category-info {
  padding: 15px 20px 0;
  opacity: 1;
  float: left;
  position: relative;
  width: 100%;
}

@media (max-width: 470px){
  .category .category-item .category-info {
    padding: 10px;
  }
}

.album-info {
  transition: 0.3s;
  opacity: 0;
}

.text-left {
  text-align: left;
}

.mt0 {
  margin-top: 0;
}

.mb0 {
  margin-bottom: 0;
}

@media screen and (max-width: 543px) {
  h6 {
    font-size: 0.8125rem;
  }
}

@media screen and (max-width: 767px) {
  h6 {
    font-size: 0.8125rem;
  }
}

@media screen and (max-width: 991px) {
  h6 {
    font-size: 0.8125rem;
  }
}

@media screen and (max-width: 1199px) {
  h6 {
    font-size: 0.8125rem;
  }
}

h6 {
  font-size: 0.8125rem;
}

.category .category-item .caption-most-voted {
  background-repeat: no-repeat;
  background-size: contain;
  width: 60px;
  height: 130px;
  position: absolute;
  top: -5px;
  right: 5px;
}

.category-item .album-description {

  height: 65px;
  margin: 0 auto 15px;
  font-size: 16px;
  line-height: 1;
  -webkit-line-clamp: 4;
  /* autoprefixer: off */
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  position: relative;
  float: left;
  width: 100%;
  padding: 0 20px;
  overflow: hidden;
  display: -webkit-box;
  text-align: left;
}

@media (max-width: 470px){
  .category-item .album-description {
    display: none;
  }
}

@media screen and (min-width: 1371px) {
  .category[data-max-per-row="6"] .album-grid-cell {
    width: 16.66666667%;
  }
}

@media screen and (max-width: 1370px) and (min-width: 1101px) {
  .category[data-max-per-row="6"] .album-grid-cell {
    width: 20%;
  }
}

@media screen and (max-width: 1100px) and (min-width: 831px) {
  .category[data-max-per-row="6"] .album-grid-cell {
    width: 25%;
  }
}

@media screen and (max-width: 830px) and (min-width: 561px) {
  .category[data-max-per-row="6"] .album-grid-cell {
    width: 33.33333333%;
  }
}

@media screen and (max-width: 560px) {
  .category[data-max-per-row="6"] .album-grid-cell {
    width: 50%;
  }
}

.album-grid-cell {
  float: left;
  position: relative;
}

.category[data-max-per-row="6"] {
  padding-left: 56px;
  padding-right: 56px;
  padding-bottom: 22.5px;
}

.album-grid-cell.is-collapsed .album-grid-img {
  cursor: pointer;
}

.category .album-grid-img {
  height: 100%;
}

.album-grid-img {
  padding: 10px;
  height: 100%;
  position: relative;
  float: left;
}

.category .category-item {
  height: 100%;
  background-color: #f4f4f4;
  position: relative;
}

#wrapper {
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100.0%;
  height: 100.0%;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 70px 0 0;
}

#inner {
  position: relative;
  float: left;
  width: 100.0%;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  min-height: 100vh;
  background-color: var(--main-color, white);
}

#innerinner {
  position: relative;
  float: left;
  width: 100.0%;
  flex: 1;
  background-color: var(--main-color, white);
  color: var(--main-font-color, inherit);
  min-height: 100vh;
}

.album-buttons {
  float: left;
  position: relative;
  margin: 15px 0 0 30px;
}

.album-header {
  flex: 1;
}

@media (min-width: 768px) {
  .image {
    width: 200px;
    min-width: 200px;
    float: left;
    position: relative;
    padding: 10px;
  }

  .album-header {
    /* width: calc(100% - 248px); */
    position: relative;
    float: left;
    padding-left: 30px;
  }

  .album-social {
    width: calc(100% - 144px);
    position: relative;
    float: left;
    padding-left: 30px;
  }
}

@media (max-width: 768px) {
  .image {
    max-width: 140px;
    width: 30%;
    float: left;
    position: relative;
  }

  .album-header {
    /* width: calc(100% - 188px); */
    position: relative;
    float: left;
    padding-left: 20px;
  }

  .album-social {
    width: calc(100% - 144px);
    position: relative;
    float: left;
    padding-left: 30px;
  }
}

@media (max-width: 600px) {
  .album-header {
    /* width: calc(70% - 48px); */
    position: relative;
    float: left;
    padding-left: 20px;
  }

  .album-buttons {
    margin: 0
  }
}

.image img {
  float: left;
  position: relative;
  width: 100%;
}

.album-title {
  float: left;
  position: relative;
  color: black;
  font-weight: 500;
  overflow: hidden;
  height: 50px;
  line-height: 1
}
@media (max-width: 470px) {
  .album-title {
    width: 100%;
    font-size: 1.15em;
  }
}

.home-album-title {
  float: left;
  position: relative;
  color: var(--main-font-color, rgb(0, 0, 0));
  font-weight: 500;
  text-overflow: ellipsis;
  /* white-space: nowrap; */
  overflow: hidden;
  width: auto;
  max-width: 100%;
  margin-top: 10px!important;
  margin-bottom: 10px!important;
  line-height: 1.4em!important;
  font-size: 1.3em!important;
}

@media (max-width: 600px) {
  .home-album-title {
    font-size: 24px!important;
    color: var(--main-font-color, rgb(0, 0, 0));
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}

.album-description {
  position: relative;
  float: left;
  width: 100.0%;
  height: 106px;
  line-height: 22px;
  font-weight: 400;
  box-orient: vertical;
  line-clamp: 5;
  color: var(--main-font-color, rgb(0, 0, 0));
  overflow: hidden;
  padding-top: 15px;
  font-size: 16px;
}

.album-description-link {
  z-index: 100;
  color: darkgray;
}

.album-description-link:hover {
  color: black;
}

.album-subtitle {
  color: black;
  width: 100%;
  font-weight: 500;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

@media (max-width: 470px){
  .album-subtitle {
    display: none;
  }
}

.link-footer {
  color: var(--footer-font-color, #777);
}

.link-footer:hover {
  color: var(--footer-font-color, white);
}

.banner-background-image {
  height: inherit;
  position: absolute;
  width: 100%;
  overflow: hidden;
  max-height: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

footer .footer-bottom {
  padding: 0.55rem 0 0.625rem;
  background-color: var(--footer-color, #1E1E1E);
  position: relative;
  float: left;
  width: 100%;
  color: var(--footer-font-color, inherit);
}

.container-footer {
  padding-right: 15px;
  padding-left: 15px;
  margin-left: 4%;
  width: 90vw;
}

.row-footer-bottom {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-right: -15px;
  margin-left: -15px;
  width: 90vw;
}

@media (max-width: 750px) {
  .row-footer-bottom {
    text-align: center;
  }
}

@media (max-width: 500px) {
  .row-footer-bottom {
    text-align: center;
    /* grid-template-columns: auto auto; */
  }
}

.coverflow__actions__MMUtW {
  bottom: 0 !important;
}

.coverflow__actions__MMUtW button {
  cursor: pointer;
}

.coverflow__stage__15AAc {
  perspective: 800px !important;
}

@media (min-width: 1280px) {
  .coverflow__stage__15AAc {
    margin-left: 60px !important;
  }
}

#simple-popper > div[role="document"] {
  box-shadow: 0 0 0 transparent !important;
  top: 26px !important;
}

.search-result:empty {
  display: none;
}

.search-input input::placeholder {
  color: black !important;
}

.card-grid-pagination-container {
  padding: 0 !important;
}

.home-element-carousel-item-description {
  padding-right: 44.5px;
  padding-left: 44.5px;
}

@media ( max-width: 600px) {
  .home-element-carousel-item-description {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
}

.slick-dots {
  z-index: 100 !important;
  margin: 0!important;
  top: 0;
  position: relative!important;
}

.slick-next {
  color: var(--main-font-color)!important;
  font-size: 35px;
}
.slick-prev {
  color: var(--main-font-color)!important;
  font-size: 35px;
}

.slick-next:hover {
  color: var(--main-font-color)!important;
}
.slick-prev:hover {
  color: var(--main-font-color)!important;
}

@media ( max-width: 640px) {
  .slick-next {
    right: 10px!important;
    top: 40%!important;
    color: lightgray!important;
    font-size: 30px;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.4)!important;
    z-index: 3;
  }
  .slick-prev {
    left: 10px!important;
    top: 40%!important;
    color: lightgray!important;
    font-size: 30px;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.4)!important;
    z-index: 3;
  }
}

.footer-social-link {
  float: left;
  position: relative;
  margin-right: 10px;
  padding: 0 !important;
  color: var(--footer-font-color, black)
}

@media (max-width: 600px) {
  .footer-social-link {
    margin-right: 5px;
  }
}

.footer-social-link > svg > g > path {
  fill: var(--footer-font-color, black)!important;
}

.footer-social-link img {
  width: 30px;
  height: 30px;
  filter: brightness(0) invert(1);
  margin-bottom: 0 !important;
}

@media (max-width: 900px) {
  .home-item-description-tag-container {
    display: none;
  }
}

.home-item-description-tag-container:hover span {
  color: var(--main-font-color, black);
}

.home-playlists .rt-tbody {
  padding-bottom: 150px;
}

.home-playlists .row {
  visibility: hidden;
}

.-pageJump {
  width: 50px;
}

.rt-tbody:empty {
  min-height: 150px!important;
}

.ReactTable .rt-noData {
  top: 70%!important;
}

@media (min-width: 960px) {
  #header-link [role="tooltip"]  {
    left: auto!important;
    top: 55px!important;
    right: 100px!important;
    transform: translate3d(0, 0, 0)!important;
  }
}

#header-link li:hover  {
  box-shadow: 0 12px 20px -10px rgba(0,188,212,.28), 0 4px 20px 0 rgba(0,0,0,.12), 0 7px 8px -5px rgba(0,188,212,.2)!important;
  background-color: #00acc1!important;
}

div[role="alertdialog"] {
  background-color: black;
  box-shadow: 0 12px 20px -10px rgba(0, 0, 0, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(0, 0, 0, 0.2)
}

#public-playlist-tab > div:first-child > div:first-child {
  background: black!important;
  box-shadow: 0 12px 20px -10px rgba(0, 4, 4, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(0, 0, 0, 0.27);
}

p {
  margin: 0!important;
}

.home-show-more {
  display: inline-block;
  height: 30px;
  border: lightgray solid 1px;
  border-radius: 15px;
  padding: 5px;
  font-size: 15px;
  cursor: pointer;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, .1), 0 -1px 2px 0 rgba(0, 0, 0, .1)
}

.home-show-more:hover {
  box-shadow: 0 3px 5px 0 rgba(0, 0, 0, .16);
  font-weight: bold;
}

.home-show-more-square {
  display: inline-block;
  height: 30px;
  border: lightgray solid 1px;
  border-radius: 2px;
  padding: 5px;
  font-size: 15px;
  cursor: pointer;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, .1), 0 -1px 2px 0 rgba(0, 0, 0, .1)
}

.home-show-more-square:hover {
  box-shadow: 0 3px 5px 0 rgba(0, 0, 0, .16);
  font-weight: bold;
}

#music-table {
  font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

#music-table td, #music-table th {
  border: 1px solid #ddd;
  padding: 8px;
}

#music-table tr:nth-child(even){background-color: #f2f2f2;}

#music-table tr:hover {background-color: #ddd;}

#music-table th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #676767;
  color: white;
}

@media (max-width: 991px) {
  body, html {
    overflow-x: inherit!important;
  }
}

.home-artist-link:hover {
  color: black!important;
}

.album-container {
  float: left;
  width: 105%;
  left: -2.5%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: fixed;
  filter: blur(10px);
  transform: scale(1.04);
}

.album-container:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.4);
}

.album-card-container {
  padding: 90px 50px 50px 50px;
}

.album-card-body {
  padding: 40px!important;
}

@media (max-width: 600px) {
  .album-card-container {
    padding: 90px 10px 10px 10px;
  }

  .album-card-body {
    padding: 10px!important;
  }
}

.home-item-description-container {
  padding: 85px 25px;
  float: left;
  position: relative;
  width: 100%;
}

@media (max-width: 600px) {
  .home-item-description-container {
    padding: 85px 5px;
  }
}

.dashboard-graph {
  width: 33.3%;
  float: left;
  position: relative;
  text-align: center;
  margin-bottom: 30px;
  height: 420px;
}

.dashboard-graph .recharts-wrapper {
  margin-left: calc(50% - 250px);
}

@media (max-width: 600px) {
  .dashboard-graph .recharts-wrapper {
    margin: 0;
    max-width: 100%;
  }

  .dashboard-graph .recharts-wrapper svg{
    max-width: 100%;
  }

  .dashboard-graph .recharts-legend-wrapper {
    left: 0!important;
    margin-left: 0!important;
    max-width: 100%!important;
  }

  .dashboard-graph .recharts-legend {
    margin-left: calc(50% - 150px)!important;
  }
}

@media (max-width: 1700px) {
  .dashboard-graph {
    width: 50%;
  }
}

@media (max-width: 1200px) {
  .dashboard-graph {
    width: 100%;
  }
}

.default-banner-video-button {
  height: 100px;
  width: 100px;
  position: absolute;
  top: calc(100vh - 50% - 50px);
  left: calc(50% - 50px);
  border: solid 2px white;
  border-radius: 50%;
  cursor: pointer
}

.default-banner-video-container {
  width: 100%!important;
}

.default-banner-cover {
  float: left;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  z-index: 12;
}

.default-banner-cover:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
}

.default-banner-video-icon {
  color: white!important;
}

@media screen and (min-width: 770px) {
  .default-banner-video-icon {
    font-size: 40px!important;
    margin-top: 28px!important;
  }
}

.featured-image-title {
  /* font-family: "Thorne-Normal", serif!important; */
  font-weight: 800;

  font-size: 200%;
  letter-spacing: 0px;
  line-height: 30px;
  padding: 0 10%;
  text-align: center;
}


@media (max-width: 600px){
  .featured-image-title {
    font-size: 16px;
    line-height: 13px;
  }
}

.go-to-link {
  color: black!important;
  margin-left: 20px;
}

.go-to-link:hover {
  text-decoration: underline;
}

@media (max-width: 600px){
  .go-to-link {
    float: left;
    position: relative;
    width: 100%;
    margin-left: 8px;
    margin-bottom: 14px;
  }

  .links-filter {
    display: none!important;
  }
}

.row-category {
  float: left;
  position: relative;
  text-align: center;
  width: 100%;
}

.contact-modal {
  margin: 0!important;
  padding: 10px 5px 5px;
}

.catalog-client-info {
  margin-top: 4%!important;
  max-width: 800px;
  display: inline-block!important;
  text-align: left;
  padding: 10px 5px 5px;
}

@media (max-width: 800px) {
  .catalog-client-info {
    max-width: 96%;
    padding: 0;
  }
}

.home-item-description-album-name {
  float: left;
  position: relative;
  width: 100%;
  color: var(--main-font-color, #3C4858);
  font-weight: 600;
}

.home-item-description-album-name:hover {
  font-weight: 800;
}

.home-elements-add-to-playlist-button {
  position: absolute;
  z-index: 1;
  border-radius: 50%;
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.7);
  top: 60px;
  right: 5px;
}

.home-elements-with-description-add-to-playlist-button {
  position: absolute;
  z-index: 1;
  border-radius: 50%;
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.7);
  top: 65px;
  right: 10px;
}

.multiline-ellipsis {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  /* autoprefixer: off */
}

.content-creation-image-container {

}

.content-creation-image-container{
  position: relative;
}

.content-creation-image {
  position: relative;
  top: 0;
  left: 0;
  max-height: 70vh;
  width: auto;
  min-width: 300px;
  min-height: 300px;
  background: white;
  margin:0 auto;
  border: 5px solid #ababab;
}

@media (max-width: 650px) {
  .content-creation-image {
    height: auto;
    width: auto;
    min-width: 0;
    min-height: 0;
    border: none;
    cursor: pointer;
  }
  .content-creation-image-over-play {
    height: 100%;
    z-index: 10;
    transition: all 0.3s;
    background-color: rgba(0, 0, 0, .3);
    min-width: 300px;
    min-height: 100%;
    max-height: 70vh;
    overflow: hidden;
  }
  .content-creation-image-over-play-text {
    position: absolute!important;
    z-index: 11;
    width: 100%!important;
    top: calc( 50% - 25px ) !important;
  }

  .content-creation-image-over {
    height: 15%;
    z-index: 1000;
    padding: 40px;
    cursor: pointer;
  }
}

.content-creation-image-over-play{
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  overflow: hidden;
  font-size: 50px;
  height: 0;
  transition: .5s fade;
  color: white;
  margin: auto;
}

@media (min-width: 650px) {
  .content-creation-image-container:hover  .content-creation-image-over-play {
    height: 100%;
    z-index: 10;
    transition: all 0.3s;
    background-color: rgba(0, 0, 0, .3);
    min-width: 300px;
    min-height: 300px;
    max-height: 70vh;
  }

  .content-creation-image-container:hover  .content-creation-image-over-play > .content-creation-image-over-play-text {
    position: absolute;
    z-index: 11;
    width: 100%;
    top: calc( 50% - 25px );
  }

  .content-creation-image-container:hover  .content-creation-image-over {
    height: 15%;
    z-index: 1000;
    padding: 40px;
  }
}

.content-creation-image-over{
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  overflow: hidden;
  width: 100%;
  height: 0;
  transition: .5s ease;
  color: white;
}

.visit_button {
  border: 1px solid rgba(153, 153, 153, 0.5);;
  padding: 10px 25px;
  color: white;
  background: rgba(153, 153, 153, 0.5);
  border-radius: 30px;
  font-size: 20px;
  cursor: pointer;
}

.default-banner-video {
  min-width: 100%;
  min-height: 100%;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100% !important;
  height: auto !important;
}

.editorial-chip{
  width: auto;
  font-weight: 400;
  background-color: transparent;
  color: var(--secondary-font-color, #000)!important;
  cursor: pointer;
  height: 30px;
  margin-top: 3px;
  margin-bottom: 3px;
  border: 1px solid #c0c0c0;
  background-color: var(--secondary-color, #e0e0e0)!important;
}

.editorial-chip:hover{
  background-color: var(--secondary-font-color, rgba(0, 0, 0, 1)) !important;
  color: var(--secondary-color, rgba(255, 255, 255, 1))!important;
}


.editorial-scroll-button {
  position: fixed!important;
  background-color: var(--main-font-color, black)!important;
  bottom: 40px;
  right: 64px;
  z-index: 100;
}

.editorial-scroll-button svg {
  position: absolute;
  color: var(--main-color, white)!important;
  top: 12px;
}

.footer-column {
  display: flex;
  flex-direction: column;
}


@media (max-width: 600px) {
  .footer-column {
    margin-bottom: 20px;
  }
}
