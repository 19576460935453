.favorites-container {
  float: left;
  position: relative;
  text-align: center;
  width: 100%;
  padding-left: 100px;
  padding-right: 100px;
}

@media (max-width: 1200px) {
  .favorites-container {
    padding-left: 50px;
    padding-right: 50px;
  }
}

@media (max-width: 800px) {
  .favorites-container {
    padding-left: 30px;
    padding-right: 30px;
  }
}

.favorites-container h3 {
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 20px;
  float: left;
  position: relative;
  width: 100%;
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
}

.favorites-title {
  font-weight: bold;
}

.favorites-container .slick-track {
  margin: 0
}

.carousel-wrapper {
  width: calc(100% - 100px);
  margin-left: 50px;
  position: relative;
  float: left;
}

@media (max-width: 500px) {
  .carousel-wrapper {
    width: calc(100% - 60px);
    margin-left: 30px;
  }
}