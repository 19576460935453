.web-like-input,
.web-like-select,
.web-like-textarea {
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  margin-top: 6px;
  margin-bottom: 0;
}

.web-like-datepicker {
  width: 460px;
  padding: 12px;
}

.web-like-input:focus,
.web-like-select:focus,
.web-like-textarea:focus {
  border-color: #39a3f5;
  box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102,175,233,.6);
}

.web-like-error {
  color: red;
  border-color: red;
}

.image-error {
  margin: 0 0 8px 20px;
}

.web-like-textarea {
  resize: vertical;
}

.web-like-h1 {
  font-family: Warnock, Serif !important;
  font-size: 32px;
  font-weight: 700;
  margin: 30px 0 5px;
  text-align: center;
}

.web-like-h4 {
  font-family: Warnock, Serif !important;
  font-size: 20px;
  font-weight: 700;
  margin: 30px 0 5px;
}

.web-like-media-picker-container {
  background-color: #fff;
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  margin-top: 6px !important;
  margin-bottom: 0;
}

.web-like-media-picker-title {
  width: 100%;
  float: left;
  margin-bottom: 10px;
}

.web-like-submit {
  margin-top: 1rem !important;
  text-align: center;
}

.web-like-submit > div {
  background-color: #000;
  color: #fff;
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  display: inline-block;
}

.voice-talent-tag {
  color: dimgray;
  margin-right: 1rem;
  border-radius: 5px;
  padding: 0.1rem 1rem;
  border-color: dimgray;
  border-style: solid;
  border-width: 1px;

  margin-bottom: 6px;
}

.voice-talent-tag:hover {
  cursor: pointer;
  background-color: #ccc;
  transition: background-color 500ms;
}

.voice-talent-tag.selected {
  color: white;
  background-color: black;
  transition: background-color 1000ms, color 1000ms;
}
/* .w3-animate-zoom {animation:animatezoom 0.6s}@keyframes animatezoom{from{transform:scale(0)} to{transform:scale(1)}} */
.save-loader {
  animation: zoom-in 500ms;
}

.save-button {
  animation: zoom-out 500ms;
}

@keyframes zoom-in {
  from{transform:scale(0)}
  to{transform:scale(1)}
}

@keyframes zoom-out {
  from{transform:scale(1)}
  to{transform:scale(0)}
}

.demo-form {
  background-color: #f7f7f7;
  padding: 1px 0;
  max-width: 520px;
  margin: 35px auto;
  border-radius: 10px;
}

.font12 {
  font-size: 12px;
}