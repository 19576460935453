.live-container:hover .live-controls {
    display: block !important;
    transition: display 6s;
}

.live-controls {
    transition: .6s ease-in-out;
    transition-timing-function: ease-in-out;
}

.center .slick-center h3 {
    color: #a1e622;
    opacity: 1;
    -ms-transform: scale(1.08);
    transform: scale(1.08);
}

.center h3 {
    opacity: 0.8;
}

.center .slick-slide {
    /*max-width: 450px;*/
}

@media (max-width: 600px){
    .live-carousel-arrow-left {
        left: -33px!important;
    }

    .live-carousel-arrow-right {
        right: -33px!important;
    }
}