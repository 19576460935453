.wordwrap {
  line-height: 1em;
  min-width: 100px;
  text-align: center;
  white-space: pre-line;
  background: rgb(51, 51, 51);
  color: white;
  margin: 0px!important;
  border-right: .5px solid #8e8e8e;
}

.wordwrap-span {
  margin-top: 10px;
  text-align: center;
  line-height: 1em;
  white-space: pre-line;
  width: 100%;
  position: relative;
  float: left;
}

.catalog-table-text {
  line-height: 1em;
  text-align: center;
  white-space: pre-line;
  margin: 0;
}