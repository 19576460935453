/* ------------------------------------------------------- */
/* -------------- COURSES ------------------------------- */
/* ------------------------------------------------------- */

@media (max-width: 1000px) {
  .items-content > .info > .text{
    display: none!important;
  }

  .items-content > .photo-container > .photo {
    top: 0px!important;
    bottom: 0px!important;
  }
}

.has-margin{
  width: calc(100% - 4px)!important;
  max-height: 396px;
  margin: 2px;
}

.items-content {
  position: relative;
  float: left;
  width: 100%;
  max-height: 400px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  cursor: pointer;
  z-index: 1;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.items-content:before {
  pointer-events: none;
  content:'';
  position: absolute;
  z-index: 1;
  top: 0%;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(rgba(var(--card-hover-rgb, 0,0,0), 0), rgba(var(--card-hover-rgb, 0,0,0), .6))!important;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  bottom: -18px;
}

.editorial {
  position: absolute;
  width: 100%;
  opacity: 0;
  height: 100%;
  background: var(--card-hover)!important;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.inverted:hover  .editorial{
  opacity: 0.5;
}
.items-content > .photo-container {
  position: relative;
  float: left;
  width: 100%;
  height: 400px;
}

.items-content > .photo-container > .photo {
  position: absolute;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

@media (max-width: 1000px) {
  .items-content > .photo-container > .photo {
    background-size: cover;
    top: 0px;
    left: 0px;
    bottom: 0px;
    right: 0px;
  }
}

@media (min-width: 1000px) {
  .items-content > .photo-container > .photo {
    height: 100%; width: 100%; object-fit: contain;
  }
}

.items-content > .info {
  position: absolute;
  width: 100%;
  opacity: 0;
  z-index: 2;
}

.inverted .info{
  opacity: 1;
  visibility: visible;
  bottom: 15px;
  font-weight: bold;
  white-space: initial;
}

.inverted:hover .info{
  font-weight: bold;
  white-space: initial;
}

.items-content > .info > .title {
  letter-spacing: -.5px;
  width: 100%;
  font-size: 25px;
  font-weight: bold;
  color: var(--title-hover);
  text-align: left;
  line-height: initial;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  padding: 5px 40px;
}

.items-content > .info > .section {
  text-transform: uppercase;
  font-weight: 100;
  font-size: 12px;
  padding: 4px 10px 2px;
  color: black;
  background: white;
  text-align: left;
  line-height: initial;
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  margin-left: 40px;
  margin-bottom: 5px;
  position: relative;
  float: left;
  border-radius: 3px;
}

.items-content > .info > .author {
  width: 100%;
  font-size: 26px;
  font-weight: 100;
  color: var(--text-hover);
  text-align: left;
  line-height: initial;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  padding: 10px 40px;
}

.items-content > .info > .text{
  width: 100%;
  position: relative;
  float: left;
  font-size: 13px;
  color: var(--text-hover);
  text-align: left;
  font-weight: bold;
  line-height: 1.2em;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  padding: 5px 40px;
}

.items-content > .info > .text > p {
  line-height: 30px;
}

.items-content > .icons {
  position: absolute;
  right: 20px;
  width: 150px;
  text-align: right;
  top: -40px;
  transition: all .4s ease-in-out;
  z-index: 3;  
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.items-content:hover > .icons {
  opacity: 1;
  visibility: visible;
  top: 20px;
  transform: translateY(0);
}

.items-content:hover .event-dates {
  display: block;
  margin: 0 10px;
}

.event-dates {
  width: 80px;
  z-index: 4;
  display: none;

}

.items-content > .icons > .star {
  position: relative;
  float: right;
  z-index: 3;
  width: 30px;
  margin-left: 10px;
  cursor: pointer;
  transition: all .4s ease-in-out;
}

.items-content > .icons > .star > svg polygon {
  display: none;
}
.items-content > .icons > .star > svg path {
  fill: #FFFFFF;
}
.items-content > .icons > .star:hover > svg polygon,
.items-content > .icons > .star.active > svg polygon {
  display: block;
  fill: #FFFFFF;
}

.items-content > .icons > .list {
  position: relative;
  float: right;
  z-index: 3;
  width: 30px;
  margin-left: 10px;
  cursor: pointer;
  transition: all .4s ease-in-out;
}

.items-content > .icons > .list > svg path {
  fill: #FFFFFF;
}
.items-content > .icons > .list:hover > svg path {
  stroke: #FFFFFF;
}

.limit-reached {
  position: absolute;
  z-index: 10;
  right: 5px;
  fill: white;
  width: 40px;
  height: 40px;
}