.toggle-switch {
  position: relative;
  width: 100px;
  display: inline-block;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  text-align: left;
}
.toggle-switch-checkbox {
  display: none;
}
.toggle-switch-label {
  display: block;
  overflow: hidden;
  cursor: pointer;
  border: 0 solid #ccc;
  border-radius: 20px;
  margin: 0;
}
.toggle-switch-inner {
  display: block;
  width: 200%;
  margin-left: -100%;
  transition: margin 0.3s ease-in 0s;
}
.toggle-switch-inner::before, .toggle-switch-inner::after {
  display: block;
  float: left;
  width: 50%;
  /* height: 34px; */
  padding: 0;
  line-height: 24px;
  font-size: 12px;
  color: white;
  /* font-weight: bold; */
  box-sizing: border-box;
}
.toggle-switch-inner:before {
  content: attr(data-yes);
  padding-left: 10px;
  padding-right: 20px;
  background-color: var(--checked-color, black);
  color: #fff;
  text-align: center;
}
.toggle-switch-disabled {
  background-color: #ccc;
  cursor: not-allowed;
}
.toggle-switch-disabled::before {
  background-color: #ccc;
  cursor: not-allowed;
}
.toggle-switch-inner::after {
  content: attr(data-no);
  padding-right: 10px;
  padding-left: 20px;
  background-color: var(--unchecked-color, #ccc);
  color: #fff;
  text-align: center;
}
.toggle-switch-switch {
  display: block;
  width: 18px;
  margin: 3px;
  background: #fff;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 75px;
  border: 0 solid #ccc;
  border-radius: 20px;
  transition: all 0.3s ease-in 0s;
}
.toggle-switch-checkbox:checked + .toggle-switch-label .toggle-switch-inner {
  margin-left: 0;
}
.toggle-switch-checkbox:checked + .toggle-switch-label .toggle-switch-switch {
  right: 0px;
}