/* ------------------------------------------------------- */
/* -------------- PREMIERE ------------------------------- */
/* ------------------------------------------------------- */
.items-content {
	position: relative;
    float: left;
	width: 100%;
	display: flex;
	flex-direction: column;
	overflow: hidden;
	cursor: pointer;
	
	-webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
}

	.items-content > .photo-container {
			position: relative; 
			float: left; 
			padding: 45% 0px;
			width: 90%;
			left: 5%;
		}

			.items-content > .photo-container > .photo {
				position: absolute; 
				border-radius: 50%;
				top: 0px; 
				left: 0px; 
				bottom: 0px; 
				right: 0px; 
				background-position: center center; 
				background-repeat: no-repeat; 
				background-size: cover;
			}

	.items-content:hover  > .photo-container:after {
		pointer-events: none;
		content:'\A';
		position:absolute;
		width:100%; 
		height:100%;
		top:0; 
		left:0;
		background:rgba(0,0,0,0.6);
		opacity:0;
		transition: all 0.5s;
		-webkit-transition: all 0.5s;
		border-radius: 50%;
	}
	.items-content:hover  > .photo-container:after {
		pointer-events: none;
		opacity:1;
		border-radius: 50%;
		background-image: -webkit-linear-gradient(to top, rgba(var(--card-hover-rgb, 0,0,0), 0.9) 10%, rgba(var(--card-hover-rgb, 0,0,0), 0.0) 80%);
		background-image: -moz-linear-gradient(to top, rgba(var(--card-hover-rgb, 0,0,0), 0.9) 10%, rgba(var(--card-hover-rgb, 0,0,0), 0.0) 80%);
		background-image: -ms-linear-gradient(to top, rgba(var(--card-hover-rgb, 0,0,0), 0.9) 10%, rgba(var(--card-hover-rgb, 0,0,0), 0.0) 80%);
		background-image: -o-linear-gradient(to top, rgba(var(--card-hover-rgb, 0,0,0), 0.9) 10%, rgba(var(--card-hover-rgb, 0,0,0), 0.0) 80%);
		background-image: linear-gradient(to top, rgba(var(--card-hover-rgb, 0,0,0), 0.9) 10%, rgba(var(--card-hover-rgb, 0,0,0), 0.0) 80%);
	}


.items-content > .info{
	position: absolute;
    width: 100%;
    height: 100%;
	top: 0;
    margin-top: 45%;
	padding: 0 40px;
	
	visibility: visible;
	font-weight: bold;
	white-space: initial;
	
	-webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
	.items-content:hover .info{
		opacity: 1;
		visibility: visible;
		font-weight: bold;
		white-space: initial;
	}

		.items-content > .info > .title{
			position: relative;
			float: left;
			font-size: 17px;
			width: 100%;
			color: var(--title-hover);
			text-align: center;
			overflow: hidden;
			text-overflow: ellipsis;
			transition: all .4s ease-in-out;
			font-weight: bold;

			display: -webkit-box;
			/* autoprefixer: off */
			-webkit-line-clamp: 2;
			-moz-line-clamp: 2;
			-ms-line-clamp: 2;
			-o-line-clamp: 2;
			line-clamp: 2;
			-webkit-box-orient: vertical;
		}

		.items-content > .info > .text{
			position: relative;
			float: left;
			width: 100%;
			font-size: 17px;
			color: var(--text-hover);
			text-align: center;
			font-weight: 100;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			transition: all .4s ease-in-out;
		}
		.items-content > .info > .podcast-subtext{
			position: relative;
			float: left;
			width: 100%;
			font-size: 15px;
			color: #fff;
			text-align: center;
			font-weight: 100;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			transition: all .4s ease-in-out;
			font-weight: 100;
		}

		.items-content > .info > .podcast{
			position: relative;
			float: left;
			width: 30px;
			left: 23px;
			left: 50%;
			margin-left: -15px;
			line-height: 2px;
			border-bottom: 1px solid #fff;
			margin-top: 5px;
			margin-bottom: 20px;
		}

		.items-content > .info > .menu {
			position: relative;
			float: left;
			width: 100%;
    		margin-top: 20px;
			text-align: center;
			align-items: center;
			display: flex;
			justify-content: center;
		}
			.items-content > .info > .menu > .star {
				position: relative;
				float: left;
				width: 25px;
				margin: 0 5px;
				cursor: pointer;
				transition: all .4s ease-in-out;
			}

				.items-content > .info > .menu > .star > svg polygon {
					display: none;
				}
				.items-content > .info > .menu > .star > svg path {
					fill: var(--title-hover, #FFFFFF);
				}
				.items-content > .info > .menu > .star:hover > svg polygon,
				.items-content > .info > .menu > .star.active > svg polygon {
					display: block;
					fill: var(--title-hover, #FFFFFF);
				}

			.items-content > .info > .menu > .list {
				position: relative;
				float: left;
				width: 25px;
				margin: 0 5px;
				cursor: pointer;
				transition: all .4s ease-in-out;
			}	
				
				.items-content > .info > .menu > .list > svg path {
					fill: var(--title-hover, #FFFFFF);
				}
				.items-content > .info > .menu > .list:hover > svg path {
					stroke:  var(--title-hover, #FFFFFF);
				}

@media (max-width: 600px){
	.items-content > .photo-container {
		padding: 50% 0px !important;
		width: 100% !important;
		left: 0 !important;
	}
}

.limit-reached {
	position: absolute;
	z-index: 10;
	right: calc(50% - 20px);
	top: calc(35% + 45px);
	fill: var(--title-hover, #FFFFFF);
	width: 40px;
	height: 40px;
	opacity: 0;
	transition: all .4s ease-in-out;
}

.items-content:hover .limit-reached{
	opacity: 1;
}

.has-margin{
	padding: 2%;
  }