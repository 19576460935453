/* ------------------------------------------------------- */
/* -------------- COURSES ------------------------------- */
/* ------------------------------------------------------- */
.musicRecommended-content {
	position: relative;
	float: left;
	width: 100%;
	display: flex;
	flex-direction: column;
	overflow: hidden;
	cursor: pointer;
	padding: 2px !important;
	z-index: 1;

	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}

/* ----- ROUND CASE ------- */
	.musicRecommended.round > .musicRecommended-content {
		padding-right: 0.5rem !important;
    	padding-left: 0.5rem !important;
		padding-top: 0px !important;
		padding-bottom: 0px !important;
	}
	.musicRecommended.round > .musicRecommended-content > .photo-container > .photo {
		border-radius: 50%;
		border: 5px solid #f2f2f2;
	}

		.musicRecommended.round > .musicRecommended-content:hover  > .photo-container:after {
			border-radius: 50%;
			width: 96% !important;
			height: 96% !important;
			top: 4px !important;
			left: 4px !important;
		}

	.musicRecommended.round > .musicRecommended-content > .photo-container.background {
		background-color: #f2f2f2;
	}
	@media only screen and (min-width: 1200px) {
		.musicRecommended.round > .musicRecommended-content:nth-child(-n+6) > .photo-container.background {
			background: -webkit-linear-gradient(to top, #f2f2f2 50%, transparent 50%);
			background: -moz-linear-gradient(to top, #f2f2f2 50%, transparent 50%);
			background: -ms-linear-gradient(to top, #f2f2f2 50%, transparent 50%);
			background: -o-linear-gradient(to top, #f2f2f2 50%, transparent 50%);
			background: linear-gradient(to top, #f2f2f2 50%, transparent 50%);
		}
		.musicRecommended.round > .musicRecommended-content:nth-last-child(-n+6) > .photo-container.background {
			background: -webkit-linear-gradient(to bottom, #f2f2f2 50%, transparent 50%);
			background: -moz-linear-gradient(to bottom, #f2f2f2 50%, transparent 50%);
			background: -ms-linear-gradient(to bottom, #f2f2f2 50%, transparent 50%);
			background: -o-linear-gradient(to bottom, #f2f2f2 50%, transparent 50%);
			background: linear-gradient(to bottom, #f2f2f2 50%, transparent 50%);
		}
	}
	@media only screen and (min-width: 1025px) and (max-width: 1200px) {
		.musicRecommended.round > .musicRecommended-content:nth-child(-n+4) > .photo-container.background {
			background: -webkit-linear-gradient(to top, #f2f2f2 50%, transparent 50%);
			background: -moz-linear-gradient(to top, #f2f2f2 50%, transparent 50%);
			background: -ms-linear-gradient(to top, #f2f2f2 50%, transparent 50%);
			background: -o-linear-gradient(to top, #f2f2f2 50%, transparent 50%);
			background: linear-gradient(to top, #f2f2f2 50%, transparent 50%);
		}
		.musicRecommended.round > .musicRecommended-content:nth-last-child(-n+4) > .photo-container.background {
			background: -webkit-linear-gradient(to bottom, #f2f2f2 50%, transparent 50%);
			background: -moz-linear-gradient(to bottom, #f2f2f2 50%, transparent 50%);
			background: -ms-linear-gradient(to bottom, #f2f2f2 50%, transparent 50%);
			background: -o-linear-gradient(to bottom, #f2f2f2 50%, transparent 50%);
			background: linear-gradient(to bottom, #f2f2f2 50%, transparent 50%);
		}
	}
	@media only screen and (min-width: 769px) and (max-width: 1024px) {
		.musicRecommended.round > .musicRecommended-content:nth-child(-n+3) > .photo-container.background {
			background: -webkit-linear-gradient(to top, #f2f2f2 50%, transparent 50%);
			background: -moz-linear-gradient(to top, #f2f2f2 50%, transparent 50%);
			background: -ms-linear-gradient(to top, #f2f2f2 50%, transparent 50%);
			background: -o-linear-gradient(to top, #f2f2f2 50%, transparent 50%);
			background: linear-gradient(to top, #f2f2f2 50%, transparent 50%);
		}
		.musicRecommended.round > .musicRecommended-content:nth-last-child(-n+3) > .photo-container.background {
			background: -webkit-linear-gradient(to bottom, #f2f2f2 50%, transparent 50%);
			background: -moz-linear-gradient(to bottom, #f2f2f2 50%, transparent 50%);
			background: -ms-linear-gradient(to bottom, #f2f2f2 50%, transparent 50%);
			background: -o-linear-gradient(to bottom, #f2f2f2 50%, transparent 50%);
			background: linear-gradient(to bottom, #f2f2f2 50%, transparent 50%);
		}
	}
	@media only screen and (max-width: 768px) {
		.musicRecommended.round > .musicRecommended-content:nth-child(-n+2) > .photo-container.background {
			background: -webkit-linear-gradient(to top, #f2f2f2 50%, transparent 50%);
			background: -moz-linear-gradient(to top, #f2f2f2 50%, transparent 50%);
			background: -ms-linear-gradient(to top, #f2f2f2 50%, transparent 50%);
			background: -o-linear-gradient(to top, #f2f2f2 50%, transparent 50%);
			background: linear-gradient(to top, #f2f2f2 50%, transparent 50%);
		}
		.musicRecommended.round > .musicRecommended-content:nth-last-child(-n+2) > .photo-container.background {
			background: -webkit-linear-gradient(to bottom, #f2f2f2 50%, transparent 50%);
			background: -moz-linear-gradient(to bottom, #f2f2f2 50%, transparent 50%);
			background: -ms-linear-gradient(to bottom, #f2f2f2 50%, transparent 50%);
			background: -o-linear-gradient(to bottom, #f2f2f2 50%, transparent 50%);
			background: linear-gradient(to bottom, #f2f2f2 50%, transparent 50%);
		}
	}
/* ------------------------- */
	.musicRecommended-content > .photo-container {
		position: relative;
		float: left;
		width: 100%;
		padding: 50% 0px;
	}

		.musicRecommended-content > .photo-container > .photo {
			position: absolute;
			top: 0px;
			left: 0px;
			bottom: 0px;
			right: 0px;
			background-position: center center;
			background-repeat: no-repeat;
			background-size: cover;
		}

	.musicRecommended-content:hover  > .photo-container:after {
		content:'\A';
		position:absolute;
		width:100%;
		height:100%;
		top:0;
		left:0;
		background:rgba(var(--card-hover-rgb, 0,0,0),0.6);
		opacity:0;
		transition: all 0.5s;
		-webkit-transition: all 0.5s;
	}
	.musicRecommended-content:hover  > .photo-container:after {
		opacity:1;
		background-image: -webkit-linear-gradient(to top, rgba(var(--card-hover-rgb, 0,0,0), 0.7) 60%, rgba(var(--card-hover-rgb, 0,0,0), 0.1) 80%);
		background-image: -moz-linear-gradient(to top, rgba(var(--card-hover-rgb, 0,0,0), 0.7) 60%, rgba(var(--card-hover-rgb, 0,0,0), 0.1) 80%);
		background-image: -ms-linear-gradient(to top, rgba(var(--card-hover-rgb, 0,0,0), 0.7) 60%, rgba(var(--card-hover-rgb, 0,0,0), 0.1) 80%);
		background-image: -o-linear-gradient(to top, rgba(var(--card-hover-rgb, 0,0,0), 0.7) 60%, rgba(var(--card-hover-rgb, 0,0,0), 0.1) 80%);
		background-image: linear-gradient(to top, rgba(var(--card-hover-rgb, 0,0,0), 0.7) 60%, rgba(var(--card-hover-rgb, 0,0,0), 0.1) 80%);
	}

.musicRecommended-content > .info {
	position: absolute;
	width: 100%;
	top: 100px;
	opacity: 0;
	padding-right: 4px;
	-webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.musicRecommended.round > .musicRecommended-content > .icons,
.musicRecommended.round > .musicRecommended-content > .info {
	padding-right: 1rem !important;
}

	.musicRecommended-content:hover .info{
   		opacity: 1;
		visibility: visible;
		top: 30%;
	}
		.musicRecommended-content > .info > .title {
			position: relative;
			float: left;
			width: 100%;
			padding: 20px 20px 0px 20px;
			font-size: 16px;
			font-weight: bold;
			color: var(--title-hover);
			text-align: center;
			overflow: hidden;
			text-overflow: ellipsis;

			display: -webkit-box;
			/* autoprefixer: off */
			-webkit-line-clamp: 2;
			-moz-line-clamp: 2;
			-ms-line-clamp: 2;
			-o-line-clamp: 2;
			line-clamp: 2;
			-webkit-box-orient: vertical;
			line-height: 1;
		}

		.musicRecommended-content > .info > .text{
			position: relative;
			float: left;
			width: 100%;
			padding: 0 20px 20px 20px;
			font-size: 12px;
			color: var(--text-hover);
			text-align: center;
			font-weight: 100;
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
		}


.musicRecommended-content > .icons {
	position: absolute;
	width: 100%;
	display: table-cell;
	vertical-align: middle;
	text-align: center;
	bottom: -40px;
	transition: all .4s ease-in-out;
	align-items: center;
	display: flex;
	justify-content: center;
}

.musicRecommended-content > .iconsWithText {
	opacity: 0;
	bottom: calc(3.3em + 10px + 30px) !important;
}

.musicRecommended-content:hover > .icons {
	opacity: 1;
	visibility: visible;
	bottom: 40px;
	transform: translateY(0);
}

.musicRecommended-content:hover > .iconsWithText {
	bottom: calc(3.3em + 10px + 40px) !important;
}

.musicRecommended-content > .icons > .star {
	position: relative;
	float: left;
	width: 30px;
	padding: 0 2px;
	cursor: pointer;
	transition: all .4s ease-in-out;
}

.musicRecommended-content > .icons > .star > svg polygon {
	display: none;
}
.musicRecommended-content > .icons > .star > svg path {
	fill: var(--title-hover, #fff);
}
.musicRecommended-content > .icons > .star:hover > svg polygon,
.musicRecommended-content > .icons > .star.active > svg polygon {
	display: block;
	fill: var(--title-hover, #fff);
}

.musicRecommended-content > .icons > .list {
	position: relative;
	float: left;
	width: 20px;
	padding: 0 2px;
	cursor: pointer;
	transition: all .4s ease-in-out;
}

		.musicRecommended-content > .icons > .list > svg path {
			fill: var(--title-hover, #fff);
		}
		.musicRecommended-content > .icons > .list:hover > svg path {
			stroke: var(--title-hover, #fff);
		}

@media (max-width: 480px) {
	.musicRecommended-content:hover > .icons {
		bottom: 20px !important;
	}
}

.limit-reached {
	position: absolute;
	z-index: 10;
	right: 15px;
	fill: var(--title-hover, #fff);
	width: 40px;
	height: 40px;
}

.musicRecommended.round > .musicRecommended-content > .limit-reached{
	right: calc(50% - 20px);
	/*opacity: 0;*/
	transition: all .4s ease-in-out;
	bottom: -40px;
	display: none;
}

.musicRecommended.round > .musicRecommended-content:hover  > .limit-reached {
	opacity: 1;
	visibility: visible;
	display: block;
	bottom: 40px;
	transform: translateY(0);
}

.musicRecommended-content > .bottom-title {
	position: relative;
	float: left;
	width: 100%;
	padding: 8px 10px 10px 10px;
	font-size: 26px;
	font-weight: bold;
	color: var(--title-normal);
	text-align: left;
	-webkit-box-orient: vertical;
	line-height: 1.1em;
	height: calc(3.3em + 10px);
	overflow: hidden;
	-webkit-line-clamp: 3;
	-moz-line-clamp: 3;
	-ms-line-clamp: 3;
	-o-line-clamp: 3;
	line-clamp: 3;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
	z-index: 6;
	margin-bottom: 20px;
}

.musicRecommended-content > .photo-container > .photo > .section {
	text-transform: uppercase;
	font-weight: 100;
	font-size: 12px;
	padding: 4px 10px 2px;
	color: black;
	background: white;
	text-align: left;
	line-height: initial;
	display: -webkit-box;
	overflow: hidden;
	-webkit-box-orient: vertical;
	margin-left: 10px;
	margin-top: 10px;
	margin-bottom: 5px;
	position: relative;
	float: left;
	border-radius: 3px;
	z-index: 3;
}