/* ------------------------------------------------------- */
/* -------------- PREMIERE ------------------------------- */
/* ------------------------------------------------------- */
.items-content {
	position: relative;
	float: left;
	width: 90%;
	height: auto;
	display: flex;
	flex-direction: column;
	overflow: hidden;
	cursor: pointer;
	padding: 2px !important;
	z-index: 1;
	-webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

	.items-content > .photo-container {
		position: relative; 
		float: left; 
		width: 100%;
		padding: 50% 0px;
	}

		.items-content > .photo-container > .photo {
			position: absolute; 
			top: 0px; 
			left: 0px; 
			bottom: 0px; 
			right: 0px; 
			background-position: center center; 
			background-repeat: no-repeat; 
			background-size: cover;
		}

	.items-content:hover  > .photo-container:after {
		pointer-events: none;
		content:'\A';
		position:absolute;
		width:100%; 
		height:100%;
		top:0; 
		left:0;
		opacity:1;
		background-image: -webkit-linear-gradient(to top, rgba(var(--card-hover-rgb, 0,0,0), 0.9) 10%, rgba(var(--card-hover-rgb, 0,0,0), 0.6) 90%);
		background-image: -moz-linear-gradient(to top, rgba(var(--card-hover-rgb, 0,0,0), 0.9) 10%, rgba(var(--card-hover-rgb, 0,0,0), 0.6) 90%);
		background-image: -ms-linear-gradient(to top, rgba(var(--card-hover-rgb, 0,0,0), 0.9) 10%, rgba(var(--card-hover-rgb, 0,0,0), 0.6) 90%);
		background-image: -o-linear-gradient(to top, rgba(var(--card-hover-rgb, 0,0,0), 0.9) 10%, rgba(var(--card-hover-rgb, 0,0,0), 0.6) 90%);
		background-image: linear-gradient(to top, rgba(var(--card-hover-rgb, 0,0,0), 0.9) 10%, rgba(var(--card-hover-rgb, 0,0,0), 0.6) 90%);
	}

 .radius:hover  > .photo-container:after {
 	border-radius: 25px;
 }

.items-content > .info{
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	margin-top: 35%;
	padding: 0px 20px;
	opacity: 0;
	
	-webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
	.items-content:hover .info{
		opacity: 1;
		visibility: visible;
		font-weight: bold;
		white-space: initial;
	}

		.items-content > .info > .title{
			position: relative;
			float: left;
			font-size: 17px;
			width: 100%;
			color: var(--title-hover);
			text-align: center;
			overflow: hidden;
			text-overflow: ellipsis;
			transition: all .4s ease-in-out;
			font-weight: bold;

			display: -webkit-box;
			/* autoprefixer: off */
			-webkit-line-clamp: 2;
			-moz-line-clamp: 2;
			-ms-line-clamp: 2;
			-o-line-clamp: 2;
			line-clamp: 2;
			-webkit-box-orient: vertical;
		}

		.items-content > .info > .text{
			position: relative;
			float: left;
			width: 100%;
			font-size: 17px;
			color: var(--text-hover);
			text-align: center;
			font-weight: 100;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			transition: all .4s ease-in-out;
		}

		.items-content > .info > .menu {
			position: relative;
			float: left;
			width: 100%;
    		margin-top: 50px;
			text-align: center;
			align-items: center;
			display: flex;
			justify-content: center;
		}
			.items-content > .info > .menu > .star {
				position: relative;
				float: left;
				width: 30px;
				margin: 0 5px;
				cursor: pointer;
				transition: all .4s ease-in-out;
			}
				.items-content > .info > .menu > .star > svg polygon {
					display: none;
				}
				.items-content > .info > .menu > .star:hover > svg polygon,
				.items-content > .info > .menu > .star.active > svg polygon {
					display: block;
					fill: #FFFFFF;
				}
				.items-content > .info > .menu > .star > svg path {
					fill: #ffffff;
				}

			.items-content > .info > .menu > .list {
				position: relative;
				float: left;
				width: 30px;
				margin: 0 5px;
				cursor: pointer;
				transition: all .4s ease-in-out;
			}	

				.items-content > .info > .menu > .list > svg path {
					fill: #FFFFFF;
				}
				.items-content > .info > .menu > .list:hover > svg path {
					stroke: #FFFFFF;
				}

@media (max-width: 600px) {
	.items-content {
		width: 100%;
	}
	.items-content > .photo-container {
		width: 100% !important;
	}
	.items-content > .info{
		width: 100%;
	}
}

.limit-reached {
	position: absolute;
	z-index: 10;
	right: calc(10% + 10px);
	fill: white;
	width: 40px;
	height: 40px;
}


.has-margin{
	padding: 2%;
  }