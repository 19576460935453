/* ------------------------------------------------------- */
/* -------------- COURSES ------------------------------- */
/* ------------------------------------------------------- */
.directors-content {
	position: relative;
    float: left;
	width: 100%;
	display: flex;
	flex-direction: column;
	overflow: hidden;
	cursor: pointer;
	padding: 6px !important;
	left: -6px;
}

	.directors-content > .photo-container {
		position: relative; 
		float: left; 
		width: 100%; 
		padding: 50% 0px;
		background-color: #f2f2f2;
	}
		.directors-content:hover > .photo-container:after {
			content:'\A';
			position:absolute;
			width: calc( 100% - 8px);
			height: calc( 100% - 8px);
			top:4px;
			left:4px;
			opacity:1;
			background-image: -webkit-linear-gradient(to top, rgba(var(--card-hover-rgb, 0,0,0), 0.8) 5%, rgba(var(--card-hover-rgb, 0,0,0), 0.4) 60%);
			background-image: -moz-linear-gradient(to top, rgba(var(--card-hover-rgb, 0,0,0), 0.8) 5%, rgba(var(--card-hover-rgb, 0,0,0), 0.4) 60%);
			background-image: -ms-linear-gradient(to top, rgba(var(--card-hover-rgb, 0,0,0), 0.8) 5%, rgba(var(--card-hover-rgb, 0,0,0), 0.4) 60%);
			background-image: -o-linear-gradient(to top, rgba(var(--card-hover-rgb, 0,0,0), 0.8) 5%, rgba(var(--card-hover-rgb, 0,0,0), 0.4) 60%);
			background-image: linear-gradient(to top, rgba(var(--card-hover-rgb, 0,0,0), 0.8) 5%, rgba(var(--card-hover-rgb, 0,0,0), 0.4) 60%);
		}

		.directors-content:hover > .photo-container:after > .embed-container {
			display: none;
		}

		.directors-content > .photo-container > .photo {
			position: absolute; 
			top: 0px; 
			left: 0px; 
			bottom: 0px; 
			right: 0px; 
			background-position: center center; 
			background-repeat: no-repeat; 
			background-size: cover;
			margin: 5px;
		}

.directors-content > .info {
	position: absolute;
	width: 80%;
	left: 10%;
    bottom: 6px;
    padding: 3px 5px;
	background-color: #f2f2f2;
	border-radius: 5px;
	
	line-height: 20px;
    color: var(--title-normal);
	text-align: center;
	font-size: 12px;

	white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
	
	-webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
}
	.directors-content:hover > .info {
		width: 86%;
		left: 7%;
		font-size: 13px;
		line-height: 28px;	
		font-weight: bold;
	}

.directors-content > .icons {
	position: absolute;
	right: 25px;
	width: 150px;
	text-align: right;
	top: -60px;
	transition: all .4s ease-in-out;
	z-index: 3;
	opacity: 0;
}
.directors-content:hover > .icons {
	opacity: 1;
	visibility: visible;
	top: 25px;
	transform: translateY(0);
}

.directors-content > .icons > .star {
	position: relative;
	float: right;
	width: 20px;
	margin-left: 10px;
	cursor: pointer;
}

.directors-content > .icons > .star > svg polygon {
	display: none;
}
.directors-content > .icons > .star > svg path {
	fill: #ffffff;
}
.directors-content > .icons > .star:hover > svg polygon,
.directors-content > .icons > .star:hover > svg polygon,
.directors-content > .icons > .star.active > svg polygon,
.directors-content > .icons > .star.active > svg polygon {
	display: block;
	fill: #ffffff;
	stroke: #ffffff;
}

.directors-content > .icons > .list {
	position: relative;
	float: right;
	width: 20px;
	margin-left: 10px;
	cursor: pointer;
}

.directors-content > .icons > .list > svg path {
	fill: #ffffff;
}
.directors-content > .icons > .list:hover > svg path {
	stroke: #ffffff;
}

@media (max-width: 600px) {
	.directors-content {
		left: 0px !important;
		padding: 2px !important;
	}
}

.limit-reached {
	position: absolute;
	z-index: 10;
	right: 15px;
	fill: white;
	width: 40px;
	height: 40px;
}