
.table-hm-row {
	padding: 8px;
	border-bottom: 1px solid #ddd;
}

.table-hm-row:hover .table-hm-drag {
	visibility: visible;
}
  
.table-hm-drag {
	visibility: hidden;
	margin-top: 5px;
	transition: visibility 0.1s;
	color: rgba(0, 0, 0, 0.14);
	padding: 3px 0;
}