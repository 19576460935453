/* ------------------------------------------------------- */
/* -------------- COURSES ------------------------------- */
/* ------------------------------------------------------- */
.has-margin{
    padding: 2%;
}

.items-content {
    position: relative;
    float: left;
    width: 100%;
    max-height: 400px;
    display: flex;
    flex-direction: column-reverse;
    overflow: hidden;
    cursor: pointer;
    z-index: 1;

    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.items-content > .photo-container {
    position: relative;
    float: left;
    width: 100%;
    height: 250px;
}

@media (max-width: 1300px) {
    .items-content > .photo-container {
        height: 220px;
    }
}
@media (max-width: 1200px) {
    .items-content > .photo-container {
        height: 200px;
    }
}
@media (max-width: 1100px) {
    .items-content > .photo-container {
        height: 180px;
    }
}
@media (max-width: 920px) {
    .items-content > .photo-container {
        height: 200px;
    }
}
@media (max-width: 800px) {
    .items-content > .photo-container {
        height: 180px;
    }
}
@media (max-width: 750px) {
    .items-content > .photo-container {
        height: 160px;
    }
}
@media (max-width: 640px) {
    .items-content > .photo-container {
        height: 220px;
    }
}

.items-content > .photo-container > .photo {
    position: absolute;
    top: 0px;
    left: 0px;
    bottom: 0px;
    right: 0px;
    background-position: center center;
    background-repeat: no-repeat;
}

@media (max-width: 1000px) {
    .items-content > .photo-container > .photo {
        background-size: cover;
    }
}

@media (min-width: 1000px) {
    .items-content > .photo-container > .photo {
        background-size: cover;
    }
}

.items-content > .info {
    position: relative;
    width: 100%;
    opacity: 0;
    z-index: 2;
}

.featured-image-title {
    background-color: rgba(0, 0, 0, .7);
    color: var(--title-hover) !important;
    position: absolute;
    bottom: 10px;
    left: 10px;
    border-radius: 5px;
    padding: 5px 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
}

.premiere-title-start {
    position: relative;
    width: 100%;
    height: calc(3.3em + 10px);
    min-height: calc(3.3em + 10px);
    padding: 10px 0;
    font-size: 26px;
    font-weight: bold;
    color: var(--title-normal) !important;
    text-align: left;
    line-height: 1.1em;
    /* autoprefixer: off */
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 3;
    -moz-line-clamp: 3;
    -ms-line-clamp: 3;
    -o-line-clamp: 3;
    line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.author {
    position: absolute;
    width: 100%;
    font-size: 24px;
    color: var(--text-hover);
    text-align: left;
    line-height: initial;
    display: -webkit-box;
    overflow: hidden;
}

.items-content > .info > .title {
    display: none;
    position: relative;
    float: left;

    width: 100%;
    padding: 20px 20px 5px 20px;
    font-size: 26px;
    font-weight: bold;
    color: var(--title-normal) !important;
    text-align: left;
    line-height: initial;
    /* autoprefixer: off */
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 2;
    -moz-line-clamp: 2;
    -ms-line-clamp: 2;
    -o-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.items-content > .info > .text{
    width: 100%;
    padding: 0 20px 20px 20px;
    display: none;
    position: relative;
    float: left;
    width: 75%;
    height: 155px;
    font-size: 13px;
    color: var(--text-hover);
    text-align: left;
    font-weight: 100;
    overflow: hidden;
}

.items-content > .info > .text > p {
    line-height: 30px;
}



.items-content > .icons {
    position: absolute;
    right: 20px;
    width: 150px;
    text-align: right;
    bottom: -40px;
    transition: all .4s ease-in-out;
    z-index: 3;
}
.items-content:hover > .icons {
    opacity: 1;
    visibility: visible;
    bottom: 20px;
    transform: translateY(0);
}

.items-content > .icons > .star {
    position: relative;
    float: right;
    z-index: 3;
    width: 30px;
    margin-left: 10px;
    cursor: pointer;
    transition: all .4s ease-in-out;
}

.items-content > .icons > .star > svg polygon {
    display: none;
}
.items-content > .icons > .star > svg path {
    fill: #FFFFFF;
}
.items-content > .icons > .star:hover > svg polygon,
.items-content > .icons > .star.active > svg polygon {
    display: block;
    fill: #FFFFFF;
}

.items-content > .icons > .list {
    position: relative;
    float: right;
    z-index: 3;
    width: 30px;
    margin-left: 10px;
    cursor: pointer;
    transition: all .4s ease-in-out;
}

.items-content > .icons > .list > svg path {
    fill: #FFFFFF;
}
.items-content > .icons > .list:hover > svg path {
    stroke: #FFFFFF;
}

.limit-reached {
    position: absolute;
    z-index: 10;
    right: 5px;
    fill: white;
    width: 40px;
    height: 40px;
}

.items-content > .info > .author {
    font-weight: 100;
    width: 100%;
    font-size: 24px;
    color: var(--text-hover);
    text-align: left;
    line-height: initial;
    display: -webkit-box;
    overflow: hidden;
    -webkit-box-orient: vertical;
    padding: 5px 20px 5px 20px;
}

@media (min-width: 650px) {
    .premiere-title-start {
        line-height: 1.5em;
        height: 5em;
        min-height: 5em;
    }
}