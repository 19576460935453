:root {
    --main-font: "roboto-font";
    --title-hover: #fff;
    --title-normal: #000;
    --text-hover: #fff;
    --text-normal: #000;
    --card-hover: #000;
}

* {
  font-family: var(--main-font), sans-serif !important;
}

.material-icons {
  font-family: 'Material Icons'!important;
}

::placeholder {
  color: lightgrey !important;
}

.coverflow__text__TAisD {
  display: none;
}

body {
	margin: 0px;
}
* {
    box-sizing: border-box;
}
*:focus {
    outline: none;
}
@media (max-width: 991px) {
	body, html {
		position: initial !important;
		overflow-x: hidden !important;
	}
}
@media (min-width: 1280px) {
	.category {
		left: 50%;
		transform: translateX(-640px);
	}
}
/* ------------------------------------------------------- */
/* -------------- FOOTER --------------------------------- */
/* ------------------------------------------------------- */

footer {
	position: relative;
	float: left;
	left: 0;
	bottom: 0;
	width: 100%;
}

footer .container-footer {
	position: relative;
	float: left;
	padding: 5px 25px;
	background-color: #1E1E1E;
	width: 100%;    
	height: 40px;
    line-height: 30px;
}


.footer-left {
	position: relative;
	float: left;
	width: 300px;
}
.footer-right {
	position: relative;
	float: right;
	width: 300px;
	text-align: right;
}
.link-footer {
    color: #a9a9a9;
    font-size: 10px;
	text-decoration: none;
	text-transform: uppercase;
}

.footer-social-link {
  position: relative;
  float: right;
  padding: 0 !important;
}

@media (max-width: 600px) {
  .footer-social-link {
    margin-right: 5px;
  }
}

.footer-social-link img {
	width: 20px;
	height: 20px;
	filter: brightness(0) invert(1);
	margin-bottom: 0 !important;
	margin-top: 5px;
}

@media (max-width: 750px) {
  .footer-left {
	position: relative;
	float: left;
	width: 65%;
	}
	.footer-right {
		position: relative;
		float: right;
		width: 35%;
		text-align: right;
	}
}
/* ------------------------------------------------------- */
/* -------------- SLIDER CSS ----------------------------- */
/* ------------------------------------------------------- */
@media (min-width: 1440px) {
  section {
    padding: var(--home-padding, 0 70px)!important;
  }
}

section {
	position: relative;
	float: left;
	width: 100%;
	max-width: 1920px;
	padding: 0 70px
}


.category {
	text-align: center;
	margin-bottom: 30px;
	padding-top: 0;
	position: relative;
	float: left;
	width: 100%;
	max-width: 1280px;
}

.category-title {
	position: relative; 
	float: left; 
	width: 100%; 
	padding: 20px 0px 10px 0px; 
	text-align: left;
}

.category-title > .title {
	margin: 0;
	font-size: 1rem;
	font-weight: 400;
}


.documentary-position {
	left: -15px;
	top: -15px;
}

@media (max-width: 600px) {
	section {
		padding: 0px;
	}

	.documentary-position {
		left: 0px;
		top: 0px;
	}

  .category-title > .title {
    margin: 0 15px;
  }
}

.tns-nav > [aria-controls] {
    width: 9px;
    height: 9px;
    padding: 0;
    margin: 20px 5px;
    border-radius: 50%;
    background: #ddd;
    border: 0;
}

.tns-nav > .tns-nav-active {
    background: #999;
}
.tns-outer > .tns-controls {
	position: absolute;
    height: 100%;
    width: 100%;
}
.tns-outer > .tns-controls > button {
	border: 0;
    background: transparent;
}
.tns-outer > .tns-controls > button[data-controls="prev"] { 
	position: relative;
    float: left;
    z-index: 1;
    top: 40%;
    left: -50px;
}
.tns-outer > .tns-controls > button[data-controls="next"] { 
	position: relative;
    float: right;
    z-index: 1;
    top: 40%;
    right: -50px;
}

/* ------------------------------------------------------- */
/* ------------------------------------------------------- */
/* ------------------------------------------------------- */

.button {
	position: absolute;
	width: auto;
	margin: 0;
	padding: 5px 20px;
	border: 2px solid var(--main-font-color, #000);
	font-size: 12px;
    color: var(--main-font-color, var(--text-normal));
	top: 50%;
	left: 50%;
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	
	cursor: pointer;
	-webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

@media (hover: hover) and (pointer:fine)  {
  .button:hover {
    background-color: var(--main-font-color, #313131)!important;
    color: var(--main-color, var(--text-hover))!important;
  }
}


.ico_star {
	display: none;
}
.ico_star:hover {
	fill: #FFFFFF;
}
.ico_list {
	fill: #FFFFFF;
}
.ico_list:hover {
	fill: #CCCCCC;
}


.navbar {
  background-color: #ffffff;
  padding: 1.5625rem 0 1.375rem 0;
  position: fixed;
  top: 0;
  width: 25%;
  min-width: 300px;
  z-index: 200;
  height: 80px;
  transition: .25s ease-in-out;
}

.navbar-sm {
  background: rgba(255, 255, 255, 0.9);
  padding: 0.4375rem 0 0 0;
  height: 60px;
  transition: .25s ease-in-out;
}

@media (min-width: 1200px) {
  .container {
    width: 1170px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .container {
    width: 970px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {

  .container {
    width: 750px;
  }
}

.navbar-header {
  margin-right: 0;
  margin-left: 0;
  float: left;
}

.navbar-brand {
  margin-left: 15px;
}

.container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.navbar-header {
  margin-right: -15px;
  margin-left: -15px;
}

.navbar-brand {
  width: 190px;
  padding: 10px 10px 10px 10px;
  margin: 0;
  float: left;
}

.navbar-brand img {
  max-width: 100%;
  display: block;
  border: 0;
}

.navbar .navbar-collapse {
  width: 100%;
  text-align: center;
}

.navbar .navbar-collapse ul li a {

}

@media (max-width: 768px) {
  .navbar-collapse {
    display: none
  }
}

@media (min-width: 768px) {
  .container > .navbar-header, .jp-audio .jp-interface > .navbar-header, .jp-playlist li > div:not(.modal) > .navbar-header, .container-fluid > .navbar-header, .container > .navbar-collapse, .jp-audio .jp-interface > .navbar-collapse, .jp-playlist li > div:not(.modal) > .navbar-collapse, .container-fluid > .navbar-collapse {
    margin-right: 0;
    margin-left: 0;
  }

  .container > .navbar-header, .jp-audio .jp-interface > .navbar-header, .jp-playlist li > div:not(.modal) > .navbar-header, .container-fluid > .navbar-header, .container > .navbar-collapse, .jp-audio .jp-interface > .navbar-collapse, .jp-playlist li > div:not(.modal) > .navbar-collapse, .container-fluid > .navbar-collapse {
    margin-right: -15px;
    margin-left: -15px;
  }

  .navbar-fixed-top .navbar-collapse, .navbar-fixed-bottom .navbar-collapse {
    max-height: 340px;
  }
}

@media (min-width: 768px) {
  .navbar-fixed-top .navbar-collapse, .navbar-static-top .navbar-collapse, .navbar-fixed-bottom .navbar-collapse {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 768px) {
  .navbar-collapse.collapse {
    display: block !important;
    height: auto !important;
    padding-bottom: 0;
    overflow: visible !important;
  }

  .collapse {
    visibility: visible;
  }
}

@media (min-width: 768px) {
  .navbar-collapse {
    width: auto;
    border-top: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  .navbar-collapse {
    padding-right: 15px;
    padding-left: 15px;
    overflow-x: visible;
    -webkit-overflow-scrolling: touch;
    border-top: 1px solid transparent;
    -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1);
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1);
  }

  .collapse {
    display: none;
    visibility: hidden;
  }
}

.nav.navbar-nav {
  text-align: center;
  display: inline-block;
  float: none;
}

@media (min-width: 768px) {
  .navbar-nav {
    float: left;
    margin: 0;
  }
}

.navbar-nav {
  margin: 7.5px -15px;
}

.nav {
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

@media (min-width: 768px) {
  .navbar-nav > li {
    float: left;
  }
}

.nav > li {
  position: relative;
  display: block;
}

.navbar .nav.navbar-nav a:hover, .navbar .nav.navbar-nav .nav-item:hover, .navbar .navbar-header a:hover {

  font-weight: bold;
}

.nav > li > a:hover, .nav > li > a:focus {
  text-decoration: none;

}

.nav a:hover, .nav a.active {
  color: #000000;
}

.nav.navbar-nav a, .navbar-header a {
  text-decoration: none;
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
  margin: 0;
}

@media screen and (max-width: 1199px) {
  .nav.navbar-nav a, .nav.navbar-nav .nav-item, .navbar-header a {
    font-size: 0.75rem;
  }
}

.nav.navbar-nav a, .nav.navbar-nav .nav-item, .navbar-header a {
  font-size: 0.75rem;
  font-weight: 400;
  color: #262626;
}

@media (min-width: 768px) {
  .navbar-nav > li > a {
    padding-top: 15px;
    padding-bottom: 15px;
  }
}

.navbar-nav > li > a {
  padding-top: 10px;
  padding-bottom: 10px;
  line-height: 20px;

}

.nav > li > a {
  position: relative;
  display: block;
  padding: 10px 15px;

}

@media (min-width: 768px) {
  .navbar-right {
    float: right !important;
    margin-right: -15px;
  }
}

.nav-icon {
  margin-top: 0;
  transform: rotate(0deg);
  transition: .5s ease-in-out;
  cursor: pointer;
  position: absolute;
  right: 0;
  padding: 9px 10px;
  margin-right: 15px;
  margin-bottom: 8px;
  background-color: transparent;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 4px;
  cursor: pointer;
}

@media (min-width: 768px) {
  .nav-icon {
    display: none;
  }
}

@media (max-width: 768px) {
  .search-icon {
    display: none;
  }
}

.overlay {
  height: 0;
  width: 100%;
  position: fixed;
  z-index: 199;
  left: 0;
  top: 0;
  background-color: #ffffff;
  overflow: hidden;
  transition: 0.5s;
}

.overlay-content {
  position: relative;
  top: 25%;
  width: 100%;
  text-align: center;
}

.overlay a {
  padding: 8px;
  text-decoration: none;
  font-size: 20px;
  display: block;
  transition: 0.3s;
  color: #262626
}


.row {
  width: 100%;
  text-align: center;
  background-color: #FFFFFF;
}

.arrow-down {
  margin-bottom: 30px;
  margin-top: 30px;
  font-size: 40px !important;
}

.footer-top {
  background-color: #262626;
  padding: 3.125rem 0;
  float: left;
  width: 100%;
  position: relative;
}

.row-footer {
  margin-right: -15px;
  margin-left: -15px;
  float: left;
  position: relative;
}

.footer-top img {
  max-height: 3.75rem;
  width: 140px;
  margin-bottom: 20px;
  vertical-align: middle;
  border: 0;
}

.footer-top h6 {
  font-size: 0.8125rem;
  margin: 0;
}

footer .footer-top ul {
  list-style: none;
}

.nowrap {
  white-space: nowrap;
  float: right;
}

@media (max-width: 768px) {
  .text-left {
    width: 100%;
    float: left;
    position: relative;
  }

  .text-right {
    width: 100%;
    float: right;
    position: relative;
  }
}

@media (min-width: 768px) {
  .text-left {
    width: 60%;
    float: left;
    position: relative;
  }

  .text-right {
    width: 40%;
    float: left;
    position: relative;
  }
}

.category[data-max-per-row="4"] {
  padding-left: 45.5px;
  padding-right: 45.5px;
  padding-bottom: 22.5px;
}

@media (max-width: 600px) {
  .category[data-max-per-row="6"] {
    padding-left: 25px !important;
    padding-right: 25px !important;
    padding-bottom: 22.5px;
  }

  .category[data-max-per-row="4"] {
    padding-left: 20px !important;
    padding-right: 20px !important;
    padding-bottom: 20px;
  }
}

.bold {
  font-weight: bold;
}

@media screen and (max-width: 830px) and (min-width: 561px) {
  .category[data-max-per-row="4"] .album-grid-cell:not([data-format="carousel"]) {
    width: 33.33333333%;
  }
}

@media screen and (min-width: 831px) {
  .category[data-max-per-row="4"] .album-grid-cell:not([data-format="carousel"]) {
    width: 25%;
  }
}

@media screen and (max-width: 560px) and (min-width: 291px) {
  .category[data-max-per-row="4"] .album-grid-cell:not([data-format="carousel"]) {
    width: 50%;
  }
}

.album-grid-cell {
  float: left;
  position: relative;
}

.opacity1 {
  opacity: 1;
}

article {
  display: block;
}

.category[data-max-per-row="4"] .album-grid-img {
  padding: 30px 22.5px;
  height: 100%;
}

@media (max-width: 470px){
  .category[data-max-per-row="4"] .album-grid-img {
    padding: 12px;
    height: 100%;
  }
}

.category-item {
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, .1), 0 -1px 2px 0 rgba(0, 0, 0, .1)
}

.category-item:hover {
  box-shadow: 0 8px 20px 0 rgba(0, 0, 0, .16);
}

.category-item:hover .album-fav {
  display: block !important;
}

@media (max-width: 470px) {
  .category-item:hover .album-fav {
    display: none!important;
  }
}

.album-fav:hover button {
  color: var(--main-font-color, rgb(0, 0, 0))!important;
}

.album-grid-cell.is-collapsed .album-grid-img {
  cursor: pointer;
}

.category .category-item {
  height: 100%;
  background-color: #e8e8e8;
  position: relative;
  float: left;
}

.basic-img {
  display: block;
  width: 100%;
  height: auto;
  margin: 0 auto;
}

.category .category-item .category-info {
  padding: 20px;
  opacity: 1;
  float: left;
  position: relative;
  width: 100%;
}

@media (max-width: 470px){
  .category .category-item .category-info {
    padding: 10px;
  }
}

.album-info {
  transition: 0.3s;
  opacity: 0;
}

.text-left {
  text-align: left;
}

.mt0 {
  margin-top: 0;
}

@media screen and (max-width: 543px) {
  h6 {
    font-size: 0.8125rem;
  }
}

@media screen and (max-width: 767px) {
  h6 {
    font-size: 0.8125rem;
  }
}

@media screen and (max-width: 991px) {
  h6 {
    font-size: 0.8125rem;
  }
}

@media screen and (max-width: 1199px) {
  h6 {
    font-size: 0.8125rem;
  }
}

h6 {
  font-size: 0.8125rem;
}

.category .category-item .caption-most-voted {
  background-repeat: no-repeat;
  background-size: contain;
  width: 60px;
  height: 130px;
  position: absolute;
  top: -5px;
  right: 5px;
}

.category-item .album-description {

  height: 72px;
  margin: 10px auto;
  font-size: 16px;
  line-height: 1;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  position: relative;
  float: left;
  width: 100%;
  padding: 20px;
  white-space: nowrap;
  overflow: hidden;
  display: inline-block;
  text-align: left;
}

@media (max-width: 470px){
  .category-item .album-description {
    display: none;
  }
}

@media screen and (min-width: 1371px) {
  .category[data-max-per-row="6"] .album-grid-cell {
    width: 16.66666667%;
  }
}

@media screen and (max-width: 1370px) and (min-width: 1101px) {
  .category[data-max-per-row="6"] .album-grid-cell {
    width: 20%;
  }
}

@media screen and (max-width: 1100px) and (min-width: 831px) {
  .category[data-max-per-row="6"] .album-grid-cell {
    width: 25%;
  }
}

@media screen and (max-width: 830px) and (min-width: 561px) {
  .category[data-max-per-row="6"] .album-grid-cell {
    width: 33.33333333%;
  }
}

@media screen and (max-width: 560px) {
  .category[data-max-per-row="6"] .album-grid-cell {
    width: 50%;
  }
}

.album-grid-cell {
  float: left;
  position: relative;
}

.category[data-max-per-row="6"] {
  padding-left: 56px;
  padding-right: 56px;
  padding-bottom: 22.5px;
}

.album-grid-cell.is-collapsed .album-grid-img {
  cursor: pointer;
}

.category .album-grid-img {
  height: 100%;
}

.album-grid-img {
  padding: 10px;
  height: 100%;
  position: relative;
  float: left;
}

.category .category-item {
  height: 100%;
  background-color: #f4f4f4;
  position: relative;
}

#wrapper {
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100.0%;
  height: 100.0%;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 70px 0 0;
}

#inner {
  position: relative;
  float: left;
  width: 100.0%;
  /*min-height: 100vh;*/
}

#innerinner {
  position: relative;
  float: left;
  width: 100.0%;
  flex: 1;
  background-color: white;
  min-height: 100vh;
}

.album-buttons {
  float: left;
  position: relative;
  margin: 15px 0 0 30px;
}

.album-header {
  flex: 1;
}

@media (min-width: 768px) {
  .image {
    width: 200px;
    min-width: 200px;
    float: left;
    position: relative;
    padding: 10px;
  }

  .album-header {
    /* width: calc(100% - 248px); */
    position: relative;
    float: left;
    padding-left: 30px;
  }

  .album-social {
    width: calc(100% - 144px);
    position: relative;
    float: left;
    padding-left: 30px;
  }
}

@media (max-width: 768px) {
  .image {
    max-width: 140px;
    width: 30%;
    float: left;
    position: relative;
  }

  .album-header {
    /* width: calc(100% - 188px); */
    position: relative;
    float: left;
    padding-left: 20px;
  }

  .album-social {
    width: calc(100% - 144px);
    position: relative;
    float: left;
    padding-left: 30px;
  }
}

@media (max-width: 600px) {
  .album-header {
    /* width: calc(70% - 48px); */
    position: relative;
    float: left;
    padding-left: 20px;
  }

  .album-buttons {
    margin: 0
  }
}

.image img {
  float: left;
  position: relative;
  width: 100%;
}

.album-title {
  width: 80%;
  float: left;
  position: relative;
  color: black;
  font-weight: 500;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
@media (max-width: 470px) {
  .album-title {
    width: 100%;
    font-size: 1.15em;
  }
}

.home-album-title {
  float: left;
  position: relative;
  color: black;
  font-weight: 500;
  text-overflow: ellipsis;
  /* white-space: nowrap; */
  overflow: hidden;
  width: auto;
  max-width: 100%;
  margin-top: 10px!important;
  margin-bottom: 10px!important;
  line-height: 1.4em!important;
  font-size: 1.3em!important;
}

@media (max-width: 600px) {
  .home-album-title {
    font-size: 24px!important;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}

.album-description {
  position: relative;
  float: left;
  width: 100.0%;
  height: 106px;
  line-height: 22px;
  font-weight: 400;
  box-orient: vertical;
  line-clamp: 5;
  color: rgba(114, 114, 114, 1);
  overflow: hidden;
  padding-top: 15px;
  font-size: 16px;
}

.album-description-link {
  z-index: 100;
  color: darkgray;
}

.album-description-link:hover {
  color: black;
}

.album-subtitle {
  color: black;
  width: 100%;
  font-weight: 500;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

@media (max-width: 480px){
	.album-subtitle {
		display: none;
	}

	.container-footer {
		padding: 5px 10px !important;
	}
}

.link-footer {
  color: #777;
}

.link-footer:hover {
  color: var(--footer-font-color, white);
}


.coverflow__actions__MMUtW {
  bottom: 0 !important;
}

.coverflow__actions__MMUtW button {
  cursor: pointer;
}

.coverflow__stage__15AAc {
  perspective: 800px !important;
}

@media (min-width: 1280px) {
  .coverflow__stage__15AAc {
    margin-left: 60px !important;
  }
}

#simple-popper > div[role="document"] {
  box-shadow: 0 0 0 transparent !important;
  top: 26px !important;
}

.search-result:empty {
  display: none;
}

.search-input input::placeholder {
  color: black !important;
}

.card-grid-pagination-container {
  padding: 0 !important;
}

.home-element-carousel-item-description {
  padding-right: 44.5px;
  padding-left: 44.5px;
}

@media ( max-width: 600px) {
  .home-element-carousel-item-description {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
}

.slick-dots {
  z-index: 100 !important;
  margin: 0!important;
  top: 0;
  position: relative!important;
}

@media ( min-width: 600px) {
    .arrow-prev {
        left: -30px!important;
    }
    .arrow-next {
        right: -30px!important;
    }
    .arrow-next-square-carousel {
        right: -5px!important;
    }
}

@media (max-width: 900px) {
  .home-item-description-tag-container {
    display: none;
  }
}

.home-item-description-tag-container:hover span {
  color: black;
}

.home-playlists .rt-tbody {
  padding-bottom: 150px;
}

.home-playlists .row {
  visibility: hidden;
}

.-pageJump {
  width: 50px;
}

.rt-tbody:empty {
  min-height: 150px!important;
}

.ReactTable .rt-noData {
  top: 70%!important;
}

@media (min-width: 960px) {
  #header-link [role="tooltip"]  {
    left: auto!important;
    top: 55px!important;
    right: 100px!important;
    transform: translate3d(0, 0, 0)!important;
  }
}

#header-link li:hover  {
  box-shadow: 0 12px 20px -10px rgba(0,188,212,.28), 0 4px 20px 0 rgba(0,0,0,.12), 0 7px 8px -5px rgba(0,188,212,.2)!important;
  background-color: #00acc1!important;
}

div[role="alertdialog"] {
  background-color: black;
  box-shadow: 0 12px 20px -10px rgba(0, 0, 0, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(0, 0, 0, 0.2)
}

#public-playlist-tab > div:first-child > div:first-child {
  background: black!important;
  box-shadow: 0 12px 20px -10px rgba(0, 4, 4, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(0, 0, 0, 0.27);
}

p {
  margin: 0!important;
}

.home-show-more {
  display: inline-block;
  width: 100px;
  height: 30px;
  border: lightgray solid 1px;
  border-radius: 15px;
  padding-top: 4px;
  font-size: 15px;
  cursor: pointer;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, .1), 0 -1px 2px 0 rgba(0, 0, 0, .1)
}

.home-show-more:hover {
  box-shadow: 0 3px 5px 0 rgba(0, 0, 0, .16);
  font-weight: bold;
}

#music-table {
  font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

#music-table td, #music-table th {
  border: 1px solid #ddd;
  padding: 8px;
}

#music-table tr:nth-child(even){background-color: #f2f2f2;}

#music-table tr:hover {background-color: #ddd;}

#music-table th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #676767;
  color: white;
}

@media (max-width: 991px) {
  body, html {
    overflow-x: inherit!important;
  }
}

.home-artist-link:hover {
  color: black!important;
}

.album-container {
  float: left;
  width: 105%;
  left: -2.5%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: fixed;
  filter: blur(10px);
  transform: scale(1.04);
}

.album-container:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.4);
}

.album-card-container {
  padding: 90px 50px 50px 50px;
}

.album-card-body {
  padding: 40px!important;
}

@media (max-width: 600px) {
  .album-card-container {
    padding: 90px 10px 10px 10px;
  }

  .album-card-body {
    padding: 10px!important;
  }
}

.home-item-description-container {
  padding: 85px 25px;
  float: left;
  position: relative;
  width: 100%;
}

@media (max-width: 600px) {
  .home-item-description-container {
    padding: 85px 5px;
  }
}

.dashboard-graph {
  width: 33.3%;
  float: left;
  position: relative;
  text-align: center;
  margin-bottom: 30px;
  height: 420px;
}

.dashboard-graph .recharts-wrapper {
  margin-left: calc(50% - 250px);
}

@media (max-width: 600px) {
  .dashboard-graph .recharts-wrapper {
    margin: 0;
    max-width: 100%;
  }

  .dashboard-graph .recharts-wrapper svg{
    max-width: 100%;
  }

  .dashboard-graph .recharts-legend-wrapper {
    left: 0!important;
    margin-left: 0!important;
    max-width: 100%!important;
  }

  .dashboard-graph .recharts-legend {
    margin-left: calc(50% - 150px)!important;
  }
}

@media (max-width: 1700px) {
  .dashboard-graph {
    width: 50%;
  }
}

@media (max-width: 1200px) {
  .dashboard-graph {
    width: 100%;
  }
}


.featured-image-title {
  /* font-family: "Thorne-Normal", serif!important; */
  font-weight: 800;

  font-size: 200%;
  letter-spacing: 0px;
  line-height: 30px;
  padding: 0 10%;
  text-align: center;
}

@media (max-width: 600px){
  .featured-image-title {
    font-size: 16px;
    line-height: 13px;
  }
}

.go-to-link {
  color: black!important;
  margin-left: 20px;
}

.go-to-link:hover {
  text-decoration: underline;
}

@media (max-width: 600px){
  .go-to-link {
    float: left;
    position: relative;
    width: 100%;
    margin-left: 8px;
    margin-bottom: 14px;
  }

  .links-filter {
    display: none!important;
  }
}

.row-category {
  float: left;
  position: relative;
  text-align: center;
  width: 100%;
}

.contact-modal {
  margin: 0!important;
  padding: 10px 5px 5px;
}

.catalog-client-info {
  margin-top: 4%!important;
  max-width: 800px;
  display: inline-block!important;
  text-align: left;
  padding: 10px 5px 5px;
}

@media (max-width: 800px) {
  .catalog-client-info {
    max-width: 96%;
    padding: 0;
  }
}

.home-item-description-album-name {
  float: left;
  position: relative;
  width: 100%;
  color: #3C4858;
}

.home-item-description-album-name:hover {
  color: #657a8a;
}

.home-elements-add-to-playlist-button {
  position: absolute;
  z-index: 1;
  border-radius: 50%;
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.7);
  top: 60px;
  right: 5px;
}

.home-elements-with-description-add-to-playlist-button {
  position: absolute;
  z-index: 1;
  border-radius: 50%;
  cursor: pointer;
  top: 85px;
  right: 15px;
}

.banner-arrow {
  display: none!important;
  transition: .3s;
}

.slick-slider:hover .banner-arrow {
  transition: .3s;
  display: block!important;
}

.underline-on-hover:hover {
  text-decoration: underline;
}

.forced-bold-text {
  font-weight: bold!important;
}
.forced-normal-text {
  font-weight: normal!important;
  text-shadow: none!important;
}