/* ------------------------------------------------------- */
/* -------------- COURSES ------------------------------- */
/* ------------------------------------------------------- */
.books-content {
	position: relative;
    float: left;
	width: 100%;
	display: flex;
	flex-direction: column;
	overflow: hidden;
	cursor: pointer;
	padding: 2px !important;
	z-index: 1;
	
	-webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
	.books-content:hover > .photo-container:after {
		content:'';
		z-index: 1;
		position: absolute;	
		padding: 2px !important;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		background-color: rgba(var(--card-hover-rgb, 0,0,0), 0.7);
	}

	.books-content:hover > .photo-container:after > .embed-container {
		display: none;
	}


	.books-content > .photo-container {
		position: relative; 
		float: left; 
		width: 100%; 
		padding: 50% 0px;
	}

		.books-content > .photo-container > .photo {
			position: absolute; 
			top: 0px; 
			left: 0px; 
			bottom: 0px; 
			right: 0px; 
			background-position: center center; 
			background-repeat: no-repeat; 
			background-size: cover;
		}

.books-content > .info {
	position: absolute;
	width: 100%;
	top: 100px;
	opacity: 0;
	z-index: 2;
	
	-webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
	.books-content:hover .info{
		opacity: 1;
		visibility: visible;
		/* top: 50%; */
	}
		.books-content > .info > .title {
			position: relative;
			float: left;
			width: 100%;
			padding: 20px 20px 10px 20px;
			font-size: 26px;
			font-weight: bold;
			color: var(--title-hover);
			text-align: center;
			-webkit-box-orient: vertical;
			line-height: 1em;
			max-height: calc(2em + 20px);
			overflow: hidden;
			-webkit-line-clamp: 2;
			-moz-line-clamp: 2;
			-ms-line-clamp: 2;
			-o-line-clamp: 2;
			line-clamp: 2;
			-webkit-box-orient: vertical;
			overflow: hidden;
			text-overflow: ellipsis;
		}

		.books-content > .info > .text{
			position: relative;
			float: left;
			width: 100%;
			padding: 0 20px 20px 20px;
			font-size: 14px;
			color: var(--text-hover);
			text-align: center;
			font-weight: 100;
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
		}


.books-content > .icons {
	position: absolute;
	width: 100%;
	display: table-cell;
	vertical-align: middle;
	text-align: center;
	top: calc(24vw - 30px);
	transition: all .4s ease-in-out;
	align-items: center;
	display: flex;
	justify-content: center;
	z-index: 2;
	opacity: 0;
}
.books-content:hover > .icons {
	opacity: 1;
	visibility: visible;
	top: calc(24vw - 40px);
	transform: translateY(0);
	z-index: 5;
}

.books-content > .icons > .star {
	position: relative;
	float: left;
	width: 30px;
	margin: 0 10px;
	cursor: pointer;
	transition: all .4s ease-in-out;
	z-index: 5;
}

.books-content > .icons > .star > svg polygon {
	display: none;
}
.books-content > .icons > .star > svg path {
	fill: #ffffff;
}
.books-content > .icons > .star:hover > svg polygon,
.books-content > .icons > .star.active > svg polygon {
	display: block;
	fill: #FFFFFF;
}

.books-content > .icons > .list {
	position: relative;
	float: left;
	width: 30px;
	margin: 0 10px;
	cursor: pointer;
	transition: all .4s ease-in-out;
	z-index: 3;
}
.books-content > .icons > .list > svg path {
	fill: #FFFFFF;
}
.books-content > .icons > .list:hover > svg path {
	stroke: #FFFFFF;
}

.limit-reached {
	position: absolute;
	z-index: 10;
	right: 5px;
	fill: white;
	width: 40px;
	height: 40px;
}

.books-content > .photo-container {
	height: 24vw;
}

@media (max-width: 1200px) {
    .books-content > .photo-container {
        height: 35vw;
    }
	.books-content > .icons {
		top: calc(35vw - 30px);
	}
	.books-content:hover > .icons {
		top: calc(35vw - 40px);
	}
}
@media (max-width: 1024px) {
    .books-content > .photo-container {
        height: 40vw;
    }
	.books-content > .icons {
		top: calc(40vw - 30px);
	}
	.books-content:hover > .icons {
		top: calc(40vw - 40px);
	}
}
@media (max-width: 770px) {
    .books-content > .photo-container {
        height: 60vw;
    }
	.books-content > .icons {
		top: calc(60vw - 30px);
	}
	.books-content:hover > .icons {
		top: calc(60vw - 40px);
	}
}
@media (max-width: 600px) {
    .books-content > .photo-container {
        height: 70vw;
    }
	.books-content > .icons {
		top: calc(70vw - 30px);
	}
	.books-content:hover > .icons {
		top: calc(70vw - 40px);
	}
}

.books-content > .bottom-title {
	position: relative;
	float: left;
	width: 100%;
	padding: 10px;
	font-size: 26px;
	font-weight: bold;
	color: var(--title-normal);
	text-align: left;
	-webkit-box-orient: vertical;
	line-height: 1.1em;
	height: calc(3.3em + 10px);
	overflow: hidden;
	-webkit-line-clamp: 3;
	-moz-line-clamp: 3;
	-ms-line-clamp: 3;
	-o-line-clamp: 3;
	line-clamp: 3;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
	z-index: 6;
	margin-bottom: 20px;
}

.books-content > .photo-container > .photo > .section {
	text-transform: uppercase;
	font-weight: 100;
	font-size: 12px;
	padding: 4px 10px 2px;
	color: black;
	background: white;
	text-align: left;
	line-height: initial;
	display: -webkit-box;
	overflow: hidden;
	-webkit-box-orient: vertical;
	margin-left: 10px;
	margin-top: 10px;
	margin-bottom: 5px;
	position: relative;
	float: left;
	border-radius: 3px;
	z-index: 3;
  }