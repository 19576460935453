/* ------------------------------------------------------- */
/* -------------- PREMIERE ------------------------------- */
/* ------------------------------------------------------- */
.has-margin{
  width: calc(100% - 4px)!important;
  margin: 2px;
}

.items-content {
  position: relative;
  float: left;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  cursor: pointer;
}

.inverted:before {
  pointer-events: none;
  content:'';
  position: absolute;
  z-index: 1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(rgba(var(--card-hover-rgb, 0,0,0), 0), rgba(var(--card-hover-rgb, 0,0,0), .6))!important;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.editorial {
  position: absolute;
  width: 100%;
  opacity: 0;
  height: 100%;
  background: var(--card-hover)!important;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.inverted:hover  .editorial{
  opacity: 0.5;
}

.items-content > .photo-container {
  position: relative;
  float: left;
  width: 100%;
  padding: 70% 0px;
}

.items-content > .photo-container > .photo {
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.items-content > .premiere-title-start {
  display: none;
}


.info {
  position: absolute;
  z-index: 2;
  width: 100%;
  opacity: 1;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.inverted .info{
  bottom: 15px !important;
  opacity: 1;
  visibility: visible;
  z-index: 2;
  font-weight: bold;
  white-space: initial;
}

.items-content > .info > .title {
  letter-spacing: -.5px;
  position: relative;
  float: left;
  width: 100%;
  padding: 0px 20px 0px 20px;
  font-size: 25px;
  font-weight: bold;
  color: var(--title-hover);
  text-align: left;
  display: -webkit-box;
  line-height: 1.4em;
  -webkit-box-orient: vertical;
}

.items-content > .info > .section {
  text-transform: uppercase;
  font-weight: 100;
  font-size: 12px;
  padding: 4px 10px 2px;
  color: black;
  background: white;
  text-align: left;
  line-height: initial;
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  margin-left: 20px;
  margin-bottom: 5px;
  position: relative;
  float: left;
  border-radius: 3px;
}

.items-content > .info > .author{
  width: 100%;
  padding: 10px 20px 20px 20px;
  position: relative;
  float: left;
  z-index: 2;
  font-size: 16px;
  color: var(--text-hover);
  text-align: left;
  font-weight: 100;
}

.items-content > .info > .text{
  width: 100%;
  position: relative;
  float: left;
  font-size: 12px;
  color: var(--text-hover);
  text-align: left;
  font-weight: bold;
  line-height: 1.2em;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  padding: 5px 20px 0px 20px;
  height: 45px;
}

.items-content > .line {
  position: absolute;
  z-index: 2;
  width: 30px;
  left: 23px;
  bottom: 100px;
  line-height: 2px;
  border-bottom: 1px solid var(--title-hover);
}
.items-content:hover .line {
  display: none;
}

.items-content > .number{
  position: absolute;
  z-index: 2;
  bottom: -3px;
  width: 150px;
  font-size: 72px;
  text-transform: uppercase;
  font-weight: bold;
  color: var(--title-hover);
  padding: 20px;
  text-align: left;
  -webkit-text-stroke: 1px var(--title-hover);
  -webkit-text-fill-color: transparent;
  -webkit-transition: 0.2s ease-out;
  -moz-transition: 0.2s ease-out;
  -o-transition: 0.2s ease-out;
  transition: 0.2s ease-out;
  line-height: initial;
}
.items-content:hover .number {
  -webkit-text-fill-color: var(--title-hover);
}

.items-content > .icons {
  position: absolute;
  right: 20px;
  width: 150px;
  height: 60px;
  text-align: right;
  top: -60px;
  transition: all .4s ease-in-out;
  z-index: 3;
  display: flex;
  justify-content: flex-end;
}
.items-content:hover > .icons {
  opacity: 1;
  visibility: visible;
  top: 20px;
  transform: translateY(0);
}

.items-content > .icons > .star {
  position: relative;
  float: right;
  width: 30px;
  padding: 0 2px;
  margin-left: 10px;
  cursor: pointer;
  transition: all .4s ease-in-out;
}
.items-content:hover > .icons > .star {
  opacity: 1;
  visibility: visible;
}

.items-content > .icons > .star > svg polygon {
  display: none;
}
.items-content > .icons > .star > svg path {
  fill: var(--title-hover, #FFFFFF);
}
.items-content > .icons > .star:hover > svg polygon,
.items-content > .icons > .star.active > svg polygon {
  display: block;
  fill: var(--title-hover, #FFFFFF);
}

.items-content > .icons > .list {
  position: relative;
  float: right;
  width: 30px;
  padding: 0 2px;
  margin-left: 10px;
  cursor: pointer;
  transition: all .4s ease-in-out;
}
.items-content:hover > .icons > .list {
  opacity: 1;
  visibility: visible;
}

.items-content > .icons > .list > svg path {
  fill: var(--title-hover, #FFFFFF);
}
.items-content > .icons > .list:hover > svg path {
  stroke:  var(--title-hover, #FFFFFF);
}

.limit-reached {
  position: absolute;
  z-index: 10;
  right: 5px;
  fill: var(--title-hover, #FFFFFF);
  width: 40px;
  height: 40px;
}