/* ------------------------------------------------------- */
/* -------------- PODCAST ART ---------------------------- */
/* ------------------------------------------------------- */
.items-content {
	position: relative;
    float: left;
	width: 100%;
	display: flex;
	flex-direction: column;
	overflow: hidden;
	cursor: pointer;
	
	-webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
}

	.items-content > .photo-container {
			position: relative; 
			float: left; 
			padding: 45% 0px;
			width: 90%;
			left: 5%;
		
			transition: all 0.5s;
			-webkit-transition: all 0.5s;
		}

			.items-content > .photo-container > .photo {
				position: absolute; 
				border-radius: 50%;
				border: 5px solid #f2f2f2;
				top: 0px; 
				left: 0px; 
				bottom: 0px; 
				right: 0px; 
				background-position: center center; 
				background-repeat: no-repeat; 
				background-size: cover;
			}

	.items-content:hover  > .photo-container:after {
		pointer-events: none;
		content:'\A';
		position:absolute;
		width: 95%;
		height: 95%;
		top: 2.5%;
		left: 2.5%;

		opacity:0.5;
		border-radius: 50%;
		background-image: -webkit-linear-gradient(to top, var(--card-hover, #000) 100%, transparent 50%);
		background-image: -moz-linear-gradient(to top, var(--card-hover, #000) 100%, transparent 50%);
		background-image: -ms-linear-gradient(to top, var(--card-hover, #000) 100%, transparent 50%);
		background-image: -o-linear-gradient(to top, var(--card-hover, #000) 100%, transparent 50%);
		background-image: linear-gradient(to top, var(--card-hover, #000) 100%, transparent 50%);
	}

.items-back {
	position: absolute;
    width: 100%;
    left: 0%;
    top: -10px;
    height: 105%;
    background-color: #f4f4f4;
    border-radius: 150px;
}
.items-content.tns-slide-active:first-child {
	background: -webkit-linear-gradient(right, #e4e4e4 50%, #ffffff 50%) !important;
	background: -o-linear-gradient(right, #e4e4e4 50%, #ffffff 50%) !important;
	background: linear-gradient(to left, #e4e4e4 50%, #ffffff 50%) !important;
}
.items-content.tns-slide-active:last-child {
	background: -webkit-linear-gradient(right, #ffffff 50%, #e4e4e4 50%) !important;
	background: -o-linear-gradient(right, #ffffff 50%, #e4e4e4 50%) !important;
	background: linear-gradient(to left, #ffffff 50%, #e4e4e4 50%) !important;
}


.items-content > .info{
	position: absolute;
    width: 100%;
    height: 100%;
	top: 0;
    margin-top: 40%;
	padding: 0px 40px;
	opacity: 0;
	
	-webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
	.items-content:hover .info{
		opacity: 1;
		visibility: visible;
		font-weight: bold;
		white-space: initial;
	}

		.items-content > .info > .title{
			position: relative;
			float: left;
			font-size: 12px;
			width: 100%;
			color: var(--title-hover);
			text-align: center;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			transition: all .4s ease-in-out;
			font-weight: bold;
		}

		.items-content > .info > .text{
			position: relative;
			float: left;
			width: 100%;
			font-size: 11px;
			color: var(--text-hover);
			text-align: center;
			font-weight: 100;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			transition: all .4s ease-in-out;
			font-weight: 100;
		}
		
		.items-content > .info > .menu {
			position: relative;
			float: left;
			width: 100%;
    		margin-top: 20px;
			text-align: center;
			align-items: center;
			display: flex;
			justify-content: center;
		}
			.items-content > .info > .menu > .star {
				position: relative;
			    float: right;
				width: 20px;
				margin: 0 5px;
				cursor: pointer;
				transition: all .4s ease-in-out;
				fill: var(--title-hover, #FFFFFF);
			}

				.items-content > .info > .menu > .star > svg polygon {
					display: none;
					fill: var(--title-hover, #FFFFFF);
				}
				.items-content > .info > .menu > .star > svg path {
					fill: var(--title-hover, #FFFFFF);
				}
				.items-content > .info > .menu > .star:hover > svg polygon,
				.items-content > .info > .menu > .star.active > svg polygon {
					display: block;
					fill: var(--title-hover, #FFFFFF);
				}

			.items-content > .info > .menu > .list {
				position: relative;
			    float: right;
				width: 20px;
				margin: 0 5px;
				cursor: pointer;
				transition: all .4s ease-in-out;
				fill: var(--title-hover, #FFFFFF);
			}	
				
				.items-content > .info > .menu > .list > svg path {
					fill: var(--title-hover, #FFFFFF);
				}
				.items-content > .info > .menu > .list:hover > svg path {
					stroke: var(--title-hover, #FFFFFF);
				}


/* ----- GREY BACKGROUND ------- */

	.items-content.tns-slide-active{
		background: #f2f2f2;
	}

	@media only screen and (min-width: 1280px) {
		.items-content.tns-slide-active {
			background: -webkit-linear-gradient(left, transparent 50%, #f2f2f2 50%);
			background: -moz-linear-gradient(left, transparent 50%, #f2f2f2 50%);
			background: -ms-linear-gradient(left, transparent 50%, #f2f2f2 50%);
			background: -o-linear-gradient(left, transparent 50%, #f2f2f2 50%);
			background: linear-gradient(left, transparent 50%, #f2f2f2 50%);
		}
		.items-content.tns-slide-active + .tns-slide-active {
			background: #f2f2f2;
			border: 1px solid #f2f2f2;
		}
		.items-content.tns-slide-active + .tns-slide-active + .tns-slide-active + .tns-slide-active + .tns-slide-active + .tns-slide-active {
			background: -webkit-linear-gradient(left, #f2f2f2 50%, transparent 50%);
			background: -moz-linear-gradient(left, #f2f2f2 50%, transparent 50%);
			background: -ms-linear-gradient(left, #f2f2f2 50%, transparent 50%);
			background: -o-linear-gradient(left, #f2f2f2 50%, transparent 50%);
			background: linear-gradient(left, #f2f2f2 50%, transparent 50%);
			border:0px;
		}
	}
	@media only screen and (min-width: 921px) and (max-width: 1279px) {
		.items-content.tns-slide-active {
			background: -webkit-linear-gradient(left, transparent 50%, #f2f2f2 50%);
			background: -moz-linear-gradient(left, transparent 50%, #f2f2f2 50%);
			background: -ms-linear-gradient(left, transparent 50%, #f2f2f2 50%);
			background: -o-linear-gradient(left, transparent 50%, #f2f2f2 50%);
			background: linear-gradient(left, transparent 50%, #f2f2f2 50%);
		}
		.items-content.tns-slide-active + .tns-slide-active {
			background: #f2f2f2;
			border: 1px solid #f2f2f2;
		}
		.items-content.tns-slide-active + .tns-slide-active + .tns-slide-active + .tns-slide-active {
			background: -webkit-linear-gradient(left, #f2f2f2 50%, transparent 50%);
			background: -moz-linear-gradient(left, #f2f2f2 50%, transparent 50%);
			background: -ms-linear-gradient(left, #f2f2f2 50%, transparent 50%);
			background: -o-linear-gradient(left, #f2f2f2 50%, transparent 50%);
			background: linear-gradient(left, #f2f2f2 50%, transparent 50%);
			border:0px;
		}
	}
	@media only screen and (min-width: 640px) and (max-width: 920px) {
		.items-content.tns-slide-active {
			background: -webkit-linear-gradient(left, transparent 50%, #f2f2f2 50%);
			background: -moz-linear-gradient(left, transparent 50%, #f2f2f2 50%);
			background: -ms-linear-gradient(left, transparent 50%, #f2f2f2 50%);
			background: -o-linear-gradient(left, transparent 50%, #f2f2f2 50%);
			background: linear-gradient(left, transparent 50%, #f2f2f2 50%);
		}
		.items-content.tns-slide-active + .tns-slide-active {
			background: #f2f2f2;
			border: 1px solid #f2f2f2;
		}
		.items-content.tns-slide-active + .tns-slide-active + .tns-slide-active {
			background: -webkit-linear-gradient(left, #f2f2f2 50%, transparent 50%);
			background: -moz-linear-gradient(left, #f2f2f2 50%, transparent 50%);
			background: -ms-linear-gradient(left, #f2f2f2 50%, transparent 50%);
			background: -o-linear-gradient(left, #f2f2f2 50%, transparent 50%);
			background: linear-gradient(left, #f2f2f2 50%, transparent 50%);
			border:0px;
		}
	}
	@media only screen and (max-width: 640px) {
		.items-content.tns-slide-active {
			background: -webkit-linear-gradient(left, transparent 50%, #f2f2f2 50%);
			background: -moz-linear-gradient(left, transparent 50%, #f2f2f2 50%);
			background: -ms-linear-gradient(left, transparent 50%, #f2f2f2 50%);
			background: -o-linear-gradient(left, transparent 50%, #f2f2f2 50%);
			background: linear-gradient(left, transparent 50%, #f2f2f2 50%);
		}
		.items-content.tns-slide-active + .tns-slide-active {
			background: -webkit-linear-gradient(left, #f2f2f2 50%, transparent 50%);
			background: -moz-linear-gradient(left, #f2f2f2 50%, transparent 50%);
			background: -ms-linear-gradient(left, #f2f2f2 50%, transparent 50%);
			background: -o-linear-gradient(left, #f2f2f2 50%, transparent 50%);
			background: linear-gradient(left, #f2f2f2 50%, transparent 50%);
			border:0px;
		}
	}
	@media only screen and (max-width: 480px) {
		.items-content.tns-slide-active {
			background: transparent;
		}
		.items-content.tns-slide-active + .tns-slide-active {
			background: transparent;
		}
	}
/* ------------------------- */


@media (max-width: 600px){
	.items-content > .photo-container {
		padding: 50% 0px !important;
		width: 100% !important;
		left: 0 !important;
	}
}

.limit-reached {
	position: absolute;
	z-index: 10;
	right: calc(50% - 20px);
	top: calc(60% + 15px);
	fill: var(--title-hover, #FFFFFF);
	width: 40px;
	height: 40px;
	opacity: 0;
	transition: all .4s ease-in-out;
}

.items-content:hover .limit-reached{
	opacity: 1;
}

.has-margin{
	padding: 2%;
  }