.content-creator-container {
  float: left;
  position: relative;
  width: 100%;
  background-color: #d3d3d34d;
  border-radius: 50px 5px 5px 50px;
  margin-bottom: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.content-creator-title {
  text-align: center;
  float: left;
  position: relative;
  width: 100%;
  color: var(--main-font-color, black);
}

.content-creator-image-container {
  float: left;
  position: relative;
  height: 75px;
  width: 75px;
  min-width: 75px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.content-creator-info-container {
  float: left;
  position: relative;
  flex: 1;
}

.content-creator-name {
  float: left;
  position: relative;
  width: auto;
  font-size: 20px;
  font-weight: bold;
  padding-left: 20px;
  color: black;
  max-height: 75px;
}

.content-creator-name:hover {
  color: darkgray;
}

.content-creator-tag {
  width: auto;
  max-width: 200px;
  font-weight: 400;
  cursor:  pointer!important;
  height: 30px!important;
  font-size: 12px!important;
  margin: 3px 4px 3px 4px!important;
}

.content-creator-tag-container {
  width: 100%;
  float: left;
  position: relative;
  padding: 0 15px 5px;
}

.content-creator-content-container {
  float: left;
  position: relative;
  width: 100%;
}

.content-creator-remove-button {
  font-size: 30px;
  color: #727272;
  cursor: pointer;
  width: 30px;
  margin: 0;
  top: 0;
  display: grid !important;
  position: absolute;
}

.content-creator-remove-button:hover {
  color: black;
}

@media (max-width: 700px) {
  .content-creator-name {
    float: left;
    position: relative;
    width: auto;
    font-size: 20px;
    font-weight: bold;
    padding-left: 10px;
    color: black;
  } 
}

.content-creator-country {
  width: auto;
  padding-left: 10px;
  vertical-align: middle;
  float: right;
  color: #676767;
  align-self: flex-end;
  margin: 5px;
}
