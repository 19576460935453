.artist-container {
  float: left;
  width: 100%;
  height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  position: fixed;
  background-position-x: 50%;
  background-position-y: 0;
}

@media(max-width: 800px) {
  .artist-container {
    background-position-y: 50%;
  }
}

.artist-container:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.4);
}

.artist-specs {
  float: left;
  position: relative;
  width: 100%;
  padding: 120px 70px 70px 70px;
}

.artist-name-container {
  float: left;
  position: relative;
  width: 60%;
}

.artist-name {
  float: left;
  position: relative;
  width: calc(100% - 50px);
  font-size: calc(30px + 2.5vw);
  line-height: 100px;
  color: white;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  left: -5px;
}

.artist-description {
  float: left;
  position: relative;
  width: 100%;
  color: #cdcdcd;
  margin-top: 30px;
  max-width: 600px;
}

.artist-social-container {
  float: left;
  position: relative;
  width: 40%;
}

.artist-social-circle {
  float: right;
  position: relative;
  text-align: center;
  width: 200px;
  height: 200px;
  background-color: rgba(255, 255, 255, 0.4);
  border-radius: 50%;
}

.artist-social-icons {
  display: inline-block;
  margin-top: 45px;
  min-width: 41px;
}

.artist-social-icon {
  width: 30px;
  float: left;
  position: relative;
  margin-right: 10px;
}

.artist-social-icon img{
  width: 100%;
  filter: brightness(0%);
}

.artist-social-line {
  display: inline-block;
  width: 160px;
  height: 3px;
  background-color: black;
}

.artist-social-name {
  color: black;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: 20px;
  font-weight: bold;
}

.artist-social-star-container{
  margin-top: 10px;
}

.artist-social-star-container button{
  background-color: black!important;
  border-radius: 10px!important;
  width: 45px!important;
  height: 45px!important;
}

.artist-social-star-container button:hover{
  background-color: #2e2e2e!important;
}

.artist-albums-container{
  float: left;
  position: relative;
  width: 80%;
  padding: 0 70px;
  max-width: 800px;
  margin-top: 50px;
}

.artist-albums-title{
  color: white;
  font-size: 21px;
  font-weight: bold;
  margin: 0 0 10px 10px;
}

.artist-album-container{
  float: left;
  position: relative;
  cursor: pointer;
}

.artist-album-image-container{
  float: left;
  position: relative;
  padding: 50% 0;
  width: 100%;
}

.artist-album-image{
  position: absolute;
  top: 10px;
  left: 10px;
  bottom: 10px;
  right: 10px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  box-shadow: 0 0 5px 0 #c1c1c1;
}

.artist-album-name{
  float: left;
  position: relative;
  width: 100%;
  background-color: white;
  padding: 5px 0;
  color: black;
  text-align: center;
  margin-top: 10px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  opacity: 0.8;
  font-weight: bold;
}

.artist-albums-container .slick-prev, .artist-albums-container .slick-next {
  top: 40%!important;
}

.artist-albums-container .slick-prev:before, .artist-albums-container .slick-next:before {
  color: white!important;
  font-size: 35px!important;
}

.artist-albums-container .slick-prev:before {
  margin-left: -18px!important;
}

.artist-albums-container .slick-dots li button:before {
  color: lightgrey!important;
}

.artist-albums-container .slick-dots li.slick-active button:before {
  color: white!important;
}

.artist-musics-container {
  position: relative;
  width: 100%;
}

.artist-musics-title {
  color: black;
  font-size: 20px;
  padding: 20px;
  font-weight: bold;
}

@media (max-width: 800px) {
  .artist-specs {
    padding: 70px 30px 30px 30px;
  }

  .artist-name-container {
    width: 100%;
  }

  .artist-social-container {
    width: 100%;
    text-align: center;
  }

  .artist-social-circle {
    display: inline-block;
    float: none;
    margin-top: 40px;
  }

  .artist-albums-container {
    width: 100%;
    left: 0;
  }

  .artist-musics-container {
    width: 100%;
    margin: 70px 0 0 0;
  }
}

.artist-close-button {
  position: absolute!important;
  right: 145px!important;
  top: 40px!important;
  width: 50px!important;
  height: 50px!important;
  z-index: 10!important;
}

@media (max-width: 800px) {
  .artist-close-button {
    top: 90px!important;
    right: 30px!important;
  }
}

.artist-albums-container .slick-track {
  margin-left: 0!important;
}

.artist-musics-container .home-item-description-container {
  background-color: #ffffff66;
  margin-top: 35px;
}