/* ------------------------------------------------------- */
/* -------------- PREMIERE ------------------------------- */
/* ------------------------------------------------------- */
.has-margin{
  width: calc(100% - 4px)!important;
  margin: 2px;
}

.items-content {
  position: relative;
  float: left;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  cursor: pointer;
}

.inverted:before {
  background-color: var(--card-hover, #000)!important;
  pointer-events: none;
  content:'';
  position: absolute;
  z-index: 1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0.7;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.inverted:hover:before {
  background-color: rgba(0, 0, 0, 0)!important;
}

.items-content:hover:before {
  pointer-events: none;
  content:'';
  position: absolute;
  z-index: 1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.items-content > .photo-container {
  position: relative;
  float: left;
  width: 100%;
  padding: 70% 0px;
}

.items-content > .photo-container > .photo {
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.items-content > .premiere-title-start {
  display: none!important;
  position: absolute;
  z-index: 3;
  bottom: 100px;
  width: 100%;
  padding: 5px 20px 5px 20px;
  font-size: 22px;
  font-weight: 100;
  color: var(--title-hover);
  text-align: left;
  line-height: initial;
  overflow: hidden;
  text-overflow: ellipsis;

  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;

  display: -webkit-box;
  /* autoprefixer: off */
  -webkit-line-clamp: 2;
  -moz-line-clamp: 2;
  -ms-line-clamp: 2;
  -o-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.items-content:hover .premiere-title-start {
  opacity: 0;
  z-index: 2;
  margin-bottom: 70px;
  transform: translateY(0);
}

.info {
  position: absolute;
  z-index: 2;
  width: 100%;
  top: 240px;
  opacity: 0;

  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.inverted .info{
  top: 40% !important;
  opacity: 1;
  visibility: visible;
  z-index: 2;
  font-weight: bold;
  white-space: initial;
}

.inverted:hover .info{
  top: 50% !important;
  opacity: 0;
  visibility: hidden;
  z-index: 2;
  margin-bottom: 70px;
  transform: translateY(0);
}

.items-content > .info > .title {
  display: none;
  position: relative;
  float: left;

  width: 100%;
  padding: 0px 20px 0px 20px;
  font-size: 26px;
  font-weight: bold;
  color: var(--title-hover);
  text-align: left;

  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.4em;
  /* autoprefixer: off */
  -webkit-line-clamp: 2;
  -moz-line-clamp: 2;
  -ms-line-clamp: 2;
  -o-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
.items-content > .info > .title {
  display: none;
  position: relative;
  float: left;

  width: 100%;
  padding: 0px 20px 0px 20px;
  font-size: 26px;
  font-weight: bold;
  color: var(--title-hover);
  text-align: left;

  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.4em;
  /* autoprefixer: off */
  -webkit-line-clamp: 2;
  -moz-line-clamp: 2;
  -ms-line-clamp: 2;
  -o-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
.items-content > .info > .section {
  display: none;
  position: relative;
  float: left;
  font-weight: 100;
  width: 100%;
  padding: 0px 20px 0px 20px;
  font-size: 26px;
  color: var(--title-hover);
  text-align: left;

  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.4em;
  /* autoprefixer: off */
  -webkit-line-clamp: 2;
  -moz-line-clamp: 2;
  -ms-line-clamp: 2;
  -o-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.items-content > .info > .author{
  width: 100%;
  padding: 0 20px 20px 20px;
  display: none;
  position: relative;
  float: left;
  height: 155px;
  z-index: 2;
  font-size: 16px;
  color: var(--text-hover);
  text-align: left;
  font-weight: 100;
  overflow: hidden;
}
.items-content:hover .info > .text{
  opacity: 1;
  display: -webkit-box;
  overflow: hidden;
  /* autoprefixer: off */
  -webkit-line-clamp: 7;
  -moz-line-clamp: 7;
  -ms-line-clamp: 7;
  -o-line-clamp: 7;
  line-clamp: 7;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.items-content > .info > .text > p {
  line-height: 30px;
}


.items-content > .line {
  position: absolute;
  z-index: 2;
  width: 30px;
  left: 23px;
  bottom: 100px;
  line-height: 2px;
  border-bottom: 1px solid var(--title-hover);
}
.items-content:hover .line {
  display: none;
}

.items-content > .number{
  position: absolute;
  z-index: 2;
  bottom: -3px;
  width: 150px;
  font-size: 72px;
  text-transform: uppercase;
  font-weight: bold;
  color: var(--title-hover);
  padding: 20px;
  text-align: left;
  -webkit-text-stroke: 1px var(--title-hover);
  -webkit-text-fill-color: transparent;
  -webkit-transition: 0.2s ease-out;
  -moz-transition: 0.2s ease-out;
  -o-transition: 0.2s ease-out;
  transition: 0.2s ease-out;
  line-height: initial;
}
.items-content:hover .number {
  -webkit-text-fill-color: var(--title-hover);
}



.items-content > .icons {
  position: absolute;
  right: 20px;
  width: 150px;
  height: 60px;
  text-align: right;
  bottom: -60px;
  transition: all .4s ease-in-out;
  z-index: 3;
  display: flex;
  justify-content: flex-end;
}
.items-content:hover > .icons {
  opacity: 1;
  visibility: visible;
  bottom: 0px;
  transform: translateY(0);
}

.items-content > .icons > .star {
  position: relative;
  float: right;
  width: 30px;
  padding: 0 2px;
  margin-left: 10px;
  cursor: pointer;
  transition: all .4s ease-in-out;
}
.items-content:hover > .icons > .star {
  opacity: 1;
  visibility: visible;
}

.items-content > .icons > .star > svg polygon {
  display: none;
}
.items-content > .icons > .star > svg path {
  fill: var(--title-hover, #FFFFFF);
}
.items-content > .icons > .star:hover > svg polygon,
.items-content > .icons > .star.active > svg polygon {
  display: block;
  fill: var(--title-hover, #FFFFFF);
}

.items-content > .icons > .list {
  position: relative;
  float: right;
  width: 30px;
  padding: 0 2px;
  margin-left: 10px;
  cursor: pointer;
  transition: all .4s ease-in-out;
}
.items-content:hover > .icons > .list {
  opacity: 1;
  visibility: visible;
}

.items-content > .icons > .list > svg path {
  fill: var(--title-hover, #FFFFFF);
}
.items-content > .icons > .list:hover > svg path {
  fill: var(--title-hover, #FFFFFF);
}

.limit-reached {
  position: absolute;
  z-index: 10;
  right: 5px;
  fill: var(--title-hover, #FFFFFF);
  width: 40px;
  height: 40px;
}