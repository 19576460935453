.license-wizard {
  width: 100%;
  height: 100%;
  overflow: hidden;
  padding: 5px;
  display: flex;
  position: fixed;
  justify-content: center;
  left: 0;
  top: 0;
  z-index: 10060;
  text-align: left; }

.license-wizard-background {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  animation: license-wizard-animation-fade-id 300ms ease-out;
  overflow: hidden;
  position: absolute;
  left: 0;
  top: 0; }

@-webkit-keyframes license-wizard-animation-fade-id {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-moz-keyframes license-wizard-animation-fade-id {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-ms-keyframes license-wizard-animation-fade-id {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes license-wizard-animation-fade-id {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.license-wizard-container {
  border-radius: 6px;
  box-shadow: 0 0 5px 0 dimgray;
  width: 100%;
  height: 100%;
  max-width: 850px;
  max-height: 680px;
  margin: auto;
  align-self: center;
  background-color: white;
  overflow: hidden;
  opacity: 0;
  display: flex;
  margin: auto;
  animation: license-wizard-container-appear 500ms forwards;
  animation-delay: 300ms; }

@-webkit-keyframes license-wizard-container-appear {
  from {
    opacity: 0;
    transform: scale(1.1); }
  to {
    opacity: 1;
    transform: scale(1); } }

@-moz-keyframes license-wizard-container-appear {
  from {
    opacity: 0;
    transform: scale(1.1); }
  to {
    opacity: 1;
    transform: scale(1); } }

@-ms-keyframes license-wizard-container-appear {
  from {
    opacity: 0;
    transform: scale(1.1); }
  to {
    opacity: 1;
    transform: scale(1); } }

@keyframes license-wizard-container-appear {
  from {
    opacity: 0;
    transform: scale(1.1); }
  to {
    opacity: 1;
    transform: scale(1); } }

.license-wizard-main {
  position: relative;
  width: 550px;
  max-width: 100%; }

.license-wizard-main-title {
  margin-top: 30px;
  margin-bottom: 10px;
  font-size: 26px;
  text-align: center; }

.license-wizard-main-content {
  animation: license-wizard-animation-fade-id 300ms ease-out;
  height: 560px;
  overflow-y: auto; }

.license-wizard-media-duration {
  height: 30px;
  transition: 500ms;
  display: flex;
  justify-content: flex-end;
  padding: 0 10px;
  position: relative; }
  .license-wizard-media-duration.hidden {
    opacity: 0;
    height: 0px;
    visibility: hidden;
    overflow: hidden;
    padding: 0px; }

.license-wizard-main-navigation-buttons {
  bottom: 5px;
  position: absolute;
  padding: 0 5px;
  display: flex;
  justify-content: space-between;
  width: 100%; }

.license-wizard-next-button {
  transform: scale(1);
  opacity: 1;
  transition: 1s; }
  .license-wizard-next-button.hidden {
    transform: scale(2);
    opacity: 0; }

.license-wizard-main-button, .license-wizard-main-checkbox {
  margin: 10px 20px;
  padding: 10px;
  cursor: pointer;
  transition: 500ms;
  font-size: 16px;
  background-color: #f4f4f4;
  position: relative; }
  .license-wizard-main-button:hover, .license-wizard-main-checkbox:hover {
    background-color: #eaeaea; }
  .license-wizard-main-button.disabled, .disabled.license-wizard-main-checkbox {
    opacity: 0.7;
    cursor: auto;
    background-color: transparent; }

.license-wizard-close-button, .license-wizard-close-button-main {
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
  transition-duration: 500ms; }
  .license-wizard-close-button:hover, .license-wizard-close-button-main:hover {
    transform: scale(1.1); }

.license-wizard-main-checkbox {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between; }

.license-wizard-main-checkbox-label-with-description {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 80%; }
  .license-wizard-main-checkbox-label-with-description div:nth-child(1) {
    color: dimgray;
    font-size: 10px;
    flex: 1; }
  .license-wizard-main-checkbox-label-with-description div:nth-child(2) {
    text-align: end; }

.license-wizard-main-description {
  text-align: justify;
  color: dimgray;
  margin: 10px 20px; }

.license-wizard-sidebar {
  position: relative;
  background-color: black;
  height: 100%;
  width: 300px;
  float: right;
  padding: 20px;
  color: white; }
  .license-wizard-sidebar .track-description {
    display: flex; }
    .license-wizard-sidebar .track-description .track-description-image {
      width: 50px;
      height: 50px;
      margin-right: 10px;
      border-style: solid;
      border-color: white;
      border-width: 1px; }
    .license-wizard-sidebar .track-description .track-description-right {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      position: relative; }
      .license-wizard-sidebar .track-description .track-description-right .track-description-waveform {
        width: 150px;
        filter: brightness(0.6); }

.license-wizard-sidebar-bottom {
  position: absolute;
  bottom: 20px;
  width: calc(100% - 40px); }

.license-wizard-sidebar-license-button {
  background-color: white;
  border-radius: 4px;
  color: black;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: 200ms; }
  .license-wizard-sidebar-license-button.disabled {
    opacity: 0.5;
    cursor: auto;
    pointer-events: none; }
  .license-wizard-sidebar-license-button:not(.disabled):hover {
    background-color: #cacaca; }

.license-wizard-sidebar-preview-license-button {
  color: white; }
  .license-wizard-sidebar-preview-license-button.disabled {
    display: none; }
  .license-wizard-sidebar-preview-license-button:visited {
    color: dimgray; }
  .license-wizard-sidebar-preview-license-button:focus {
    color: dimgray; }
  .license-wizard-sidebar-preview-license-button:hover {
    color: dimgray; }

.license-wizard-sidebar-price-value {
  text-align: right;
  font-size: 28px; }
  .license-wizard-sidebar-price-value span {
    font-size: 12px;
    color: dimgray; }

.license-wizard-countries-per-region-checkbox-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: -10px 0; }

.license-wizard-countries-per-region-checkbox {
  display: flex;
  align-items: center; }

.license-wizard-sadaic-plan-item-container {
  display: flex;
  flex-wrap: wrap;
  font-size: 12px; }

.license-wizard-sadaic-plan-item {
  display: flex;
  width: 50%;
  justify-content: space-between;
  padding: 0 10px; }

.license-wizard-sadaic-plans-form-page-container {
  margin: 20px; }
