.track-table-row {
  width: 100%;
  float: left;
  position: relative;
  height: 80px;
  text-align: left;
  padding-left: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.track-suggestion-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}

.track-table-row:hover {
  background-color: rgba(var(--card-hover-rgb, 150,150,150), 0.1);
}

.track-table-row:hover .track-table-play-icon,
.track-suggestion-container:hover .track-table-play-icon {
  display: block!important;
}

.track-table-row:hover .track-table-play-button,
.track-suggestion-container:hover .track-table-play-button {
  display: block!important;
}

.track-table-row .track-table-play-icon {
  cursor: pointer;
}

.track-table-row:hover .track-table-add-to-favorites-button {
  visibility: visible!important;
}

.track-table-sound-active {
  top: 27px;
  color: gray;
  position: absolute;
  float: left;
  left: -28px;
}

.track-table-favorite {
  float: left!important;
  position: relative!important;
  width: 5%!important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.track-table-add-to-favorites-button {
  width: 40px!important;
  height: 40px!important;
}

.track-table-remove-from-favorites-button {
  width: 40px!important;
  height: 40px!important;
}

.track-table-image-container {
  width: 7%;
  float: left;
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
}

.track-table-index {
  float: left;
  position: relative;
  width: 100%;
  font-size: 18px;
  color: darkgray;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.track-table-image {
  float: left;
  position: relative;
  width: 60px;
  height: 60px;
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.track-table-play-button {
  background-color: black!important;
  display: none!important;
  position: absolute !important;
}

.track-table-pause-button {
  background-color: black!important;
  position: absolute !important;
}

.track-table-play-icon {
  position: relative!important;
  color: white!important;
  font-size: 30px!important;
  display: none!important;
  cursor: pointer!important;
}

.track-table-pause-icon {
  position: relative!important;
  color: white!important;
  font-size: 30px!important;
  display: none;
  cursor: pointer!important;
}

.track-table-name-container {
  width: 25%;
  float: left;
  position: relative;
}

.track-table-name-container.voice{
  width: 25%;
  height: 100%;
  align-items: center;
  display: grid;
  color: black;
}

.track-table-name {
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  margin-right: 30px;
  text-overflow: ellipsis;
}

.track-table-name-auxiliary {
  font-size: 12px;
  font-weight: 300;
  color: darkgray;
  white-space: nowrap;
  overflow: hidden;
}

.track-table-name-auxiliary:hover {
  font-size: 12px;
  font-weight: 300;
  color: var(--main-font-color, black);
}

.track-table-sound-wave-container {
  width: 300px;
  float: left;
  position: relative
}

.track-table-sound-wave {
  float: left;
  position: relative;
  width: 100%;
  height: 80px;
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center;
  filter: brightness(75%) sepia(100) saturate(00) hue-rotate(25deg);
}

.track-table-tags {
  width: 20%;
  float: left;
  position: relative;
  height: 100%;
  cursor: default;
  color: darkgray;
  font-size: 13px;
  display: inline-flex;
  align-items: center;
  padding-left: 15px;
}

.track-table-tags-link-container {
  width: 100%;
  float: left;
  position: relative;
}

.track-table-tags-link {
  color: darkgray;
  cursor: pointer;
  margin-right: 20px;
  font-size: 12px;
  float: left;
  position: relative;
}

.track-table-tags-link:hover{
  color: var(--main-font-color, black);
}

.track-table-duration {
  width: 7%;
  text-align: center;
  float: left;
  position: relative;
  color: var(--main-font-color, rgba(0,0,0,0.87));
}

.track-table-more-container {
  width: 4%;
  float: left;
  position: relative;
}

.track-table-more {
  width: 40px!important;
  height: 40px!important;
}

.track-table-license-container {
  width: 10%;
  float: left;
  position: relative;
}

.track-table-license {
  background-color: black!important;
  font-weight: 700!important;
  border-radius: 7px!important;
  font-size: 13px!important;
  padding-top: 4px!important;
  padding-bottom: 4px!important;
  height: 30px!important;
  float: right!important;
  width: 90px;
  text-transform: capitalize!important;
}

.track-table-download {
  background-color: black!important;
  font-weight: 700!important;
  border-radius: 7px!important;
  font-size: 13px!important;
  padding-top: 3px!important;
  padding-bottom: 4px!important;
  height: 30px!important;
  float: right!important;
  width: 120px;
  text-transform: capitalize!important;
}

@media (min-width: 960px) and (max-width: 1180px) {
  .track-table-favorite {
    width: 5%!important;
  }

  .track-table-image-container {
    width: 10%!important;
  }

  .track-table-name-container {
    width: 25%!important;
  }

  .track-table-name-container.voice {
    width: 27%!important;
  }

  .track-table-sound-wave-container {
    width: 300px!important;
  }

  .track-table-tags {
    display: none!important;
  }

  .track-table-duration {
    width: 10%!important;
  }

  .track-table-more-container{
    width: 5%!important;
  }

  .track-table-license-container{
    width: 15%!important;
  }
}

@media (max-width: 960px) {
  .track-table-favorite {
    width: 10%!important;
  }

  .track-table-image-container {
    width: 15%!important;
  }

  .track-table-name-container {
    width: 35%!important;
  }

  .track-table-sound-wave-container {
    display: none!important;
  }

  .track-table-tags {
    display: none!important;
  }

  .track-table-duration {
    width: 10%!important;
  }

  .track-table-more-container{
    width: 10%!important;
  }

  .track-table-license-container{
    width: 20%!important;
  }
}

@media (max-width: 600px) {
  .track-table-favorite {
    width: 10%!important;
  }

  .track-table-add-to-favorites-button {
    visibility: visible!important;
    width: 30px!important;
    height: 30px!important;
  }

  .track-table-remove-from-favorites-button {
    width: 30px!important;
    height: 30px!important;
  }

  .track-table-image-container {
    width: 15%!important;
  }

  .track-table-image {
    width: 50px!important;
    height: 50px!important;
  }

  .track-table-play-button {
    width: 37px!important;
    height: 37px!important;
  }

  .track-table-pause-button {
    width: 37px!important;
    height: 37px!important;
  }

  .track-table-play-icon {
    font-size: 20px!important;
  }

  .track-table-pause-icon {
    font-size: 20px!important;
  }

  .track-table-name-container {
    width: 40%!important;
    padding-left: 10px;
  }

  .track-table-sound-wave-container {
    display: none!important;
  }

  .track-table-tags {
    display: none!important;
  }

  .track-table-duration {
    display: none!important;
  }

  .track-table-more-container{
    width: 10%!important;
  }

  .track-table-license-container{
    width: 10%!important;
  }

  .track-table-license {
    background-color: black!important;
    font-weight: 600!important;
    border-radius: 7px!important;
    font-size: 12px!important;
    padding-top: 3px!important;
    padding-bottom: 3px!important;
    height: 30px!important;
    width: 80px!important;
    float: left!important;
  }

  .track-table-download {
    background-color: black!important;
    font-weight: 600!important;
    border-radius: 7px!important;
    font-size: 12px!important;
    padding-top: 3px!important;
    padding-bottom: 3px!important;
    height: 30px!important;
    width: 100px!important;
    float: left!important;
  }

  .smart-search-result-container {
    padding: 0px!important;
  }

  .track-table-sound-active {
    display: none!important;
  }
}

.track-table-row div[role="tooltip"] {
  transform: translate3d(0, 0, 0)!important;
}

.track-table-tags-link-selected {
  margin-right: 20px;
  font-size: 12px;
  float: left;
  position: relative;
  font-weight: bold;
  color: black;
  cursor: pointer;
}

.track-table-tags-link-selected:hover {
  color: darkgray;
}

.track-table-logo {
  width: 18px;
  height: 18px;
  margin-left: 10px;
  margin-top: -2px;
  opacity: 0.5;
}

.track-table-action-buttons {
  float: right;
  position: relative;
  width: 13%;
  max-width: 144px;
  justify-content: end;
}

.track-table-action-icon {
  font-size: 21px!important;
  color: #727272;
  cursor: pointer;
  width: 18%!important;
  margin-left: 7%;
}

.track-table-action-icon:hover {
  color: black;
}

.suggestions-container{
  position: relative;
  float: left;
  width: 100%;
  padding: 0 20px;
  text-align: center;
}

.suggestion-elements-container{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 20px;
}

.suggestion-element{
  margin: 0 10px 10px 0;
  max-width: calc(25% - 60px)!important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

@media (max-width: 450px) {
  .suggestion-element{
    max-width: 100%!important;
  }
}

@media (min-width: 670px) and (max-width: 950px)  {
  .suggestions-container{
    padding: 0;
  }

  .suggestion-elements-container{
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-evenly;
  }
}

@media (max-width: 910px) {
  .suggestion-elements-container .suggestion-element:last-child {
    display: none !important;
  }
}

@media (max-width: 670px) {
  .suggestion-elements-container{
    display: grid!important;
    grid-template-columns: auto auto;
    justify-content: space-evenly;
  }

  .suggestion-element{
    max-width: 100%;
  }
}

@media (max-width: 1180px){
  .track-table-action-buttons {
    width: 18%;
  }
}

@media (min-width: 600px) and (max-width: 960px){
  .track-table-action-buttons {
    width: 30%;
  }
}

@media (max-width: 600px) {
  .track-table-action-buttons {
    width: 35%;
    text-align: right;
  }
}

.track-table-versions {
  float: left;
  position: relative;
  width: 100%;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, .16);
}

.track-table-show-versions-button {
  position: absolute!important;
  top: 6px;
  right: 10px;
  width: 30px!important;
  height: 30px!important;
  color: var(--main-font-color, rgba(0,0,0,0.54))!important;
}
