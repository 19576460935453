.shaka-video-container .material-icons-round {
    font-family: 'Material Icons'!important;
    font-size: 24px;
}

.shaka-play-button {
    padding: 5%;
    width: 100px;
    height: 100px;
}

@media (max-width: 800px) {
    .shaka-play-button {
        width: 60px;
        height: 60px;
    }
}

.shaka-spinner {
    padding: 5.2%;
}

.shaka-video-container:not(:fullscreen) {
    width: 100%; 
    margin: 0 auto; 
    position: relative;
    background-color: transparent; 
}

.shaka-video-container video {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
    max-width: 100%; 
    max-height: 100%;
}

.shaka-text-container span {
    font-size: calc(15px + 0.8vw);
}

.shaka-player-wrapper-play-button {
  background-color: black!important;
  position: absolute!important;
  height: 100px!important;
  width: 100px!important;
  top: calc(50% - 50px);
  left: calc(50% - 50px); 
}

.shaka-player-wrapper-play-icon {
  position: relative!important;
  color: white!important;
  font-size: 70px!important;
  cursor: pointer!important;
}

@media (max-width: 750px) {
    .shaka-player-wrapper-play-button {
        height: 60px!important;
        width: 60px!important;
        top: calc(50% - 30px);
        left: calc(50% - 30px);
    }

    .shaka-player-wrapper-play-icon {
        font-size: 40px!important;
    }
}

.shaka-player-blurred-container {
  -webkit-filter: blur(10px);
  -moz-filter: blur(10px);
  -o-filter: blur(10px);
  -ms-filter: blur(10px);
  filter: blur(10px);
}