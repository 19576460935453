.purchase-table .rt-noData {
    color: var(--navbar-text-color, rgba(0,0,0,0.5))!important;
    background-color: var(--secondary-color, white)!important;
}

.share-gift-dialog {
  display: flex;
  flex-direction: column;
  background-color: var(--secondary-color, white);
  color: var(--navbar-text-color, black);
  justify-content: space-between;
  width: 500px;
  max-width: 90vw;
  padding: 20px 50px;
}