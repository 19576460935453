.right-management-container {
  float: left;
  position: relative;
  text-align: center;
  width: 100%;
  padding-left: 100px;
  padding-right: 100px;
}

@media (max-width: 600px) {
  .right-management-container {
    padding-left: 50px;
    padding-right: 50px;
  }
}

.right-management-title {
  font-weight: 600;
  font-family: Warnock, serif!important;
}

.right-management-container h3{
  margin-top: 40px;
  font-family: Warnock, serif!important;
  font-weight: bold;
}

.right-management-container p{
  color: darkgray;
  font-size: 14px;
  max-width: 1200px;
  display: inline-block;
}

.right-management-form-container {
  float: left;
  position: relative;
  text-align: center;
  width: 100%;
  padding-left: 10%;
  padding-right: 10%;
  margin-top: 50px;
  background-color: rgb(247, 247, 247);
}

.right-management-form-container h3 {
  font-family: Warnock, serif!important;
  font-size: 22px;
}

.right-management-form-button-container {
  float: left;
  position: relative;
  width: 100%;
  text-align: center;
  margin-top: 30px;
  margin-bottom: 30px;
}

.right-management-input{
  width: 100%;
  margin-bottom: 10px!important;
}

.right-management-form-button {
  margin-top: 20px!important;
  background-color: black!important;
  font-weight: 600!important;
  border-radius: 9px!important;
  font-size: 15px!important;
  letter-spacing: 1.3px!important;
  text-transform: capitalize!important;
  padding-top: 3px!important;
  padding-bottom: 3px!important;
  height: 35px!important;
  width: 100px!important;
}

.right-management-form-title{
  font-weight: bold;
  font-family: Warnock, serif!important;
  margin-top: 80px;
  margin-bottom: 40px;
}


.right-management-privacy-checkbox span{
  color: darkgray!important;
}