.item {
    width: 100%;
    height: 250px;
    display: inline-block;
    position: relative;
    float: left;
    background: white;
}

.file-item {
    position: relative;
    float: left;
    width: 100%;
    height: 100%;
}

.file-item-carousel {
    width: 100%;
    position: relative;
    float: left;
    height: 500px;
}

.file-background-image {
    opacity: 0.3;
    width: 170px;
    height: 150px;
    position: absolute;
    bottom: 0;
    right: 0;
    top: calc(50% - 75px);
    left: calc(50% - 85px);
    background-Position: center;
    background-repeat: no-repeat;
    background-size: cover;
    cursor: pointer;
}

.over-item {
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 2;
    top: 0;
    left: 0;
    bottom: 0;
}


.over-item:hover .over-buttons {
    display: block!important;
}


/*.over-item:hover .item-text {*/
/*    color: white;*/
/*}*/

.over-background {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: hidden;
    width: 100%;
    height: 0;
    transition: .2s ease;
}

.over-item:hover .over-background {
    height: 100%;
}


.over-item:hover .over-action-reel {
    height: calc(50% + 40px);
}

.over-action-reel {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: hidden;
    width: 100%;
    height: 0;
    transition: .5s ease;
}

.over-action-reel > svg {
    width: 70px;
    height: 70px;
    margin-left: calc(50% - 35px);
}


.over-buttons {
    transition: 0.3s;
    position: absolute;
    top: 10px;
    border-radius: 50px;
}

.play-arrow {
    fill: currentColor;
    width: 1em;
    height: 1em;
    display: inline-block;
    transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    user-select: none;
    flex-shrink: 0;
    color: white!important;
    margin-left: calc(50% - 20px);
    font-size: 40px!important;
    margin-top: 28px!important
}

.play-button {
    height: 100px;
    width: 100px;
    position: absolute;
    top: calc(50% - 50px);
    left: calc(50% - 50px);
    border: solid 2px white;
    border-radius: 50%;
    cursor: pointer;
}

.item-text {
    padding: 0 10px;
    position: absolute;
    bottom: 0;
    width: 100%;
    margin: 0;
    line-height: 22px;
}

.item-title {
    font-size: 18px;
    font-weight: normal;
    margin: 0;
}

.item-subtitle {
    font-size: 12px;
}

.file-image {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-Position: center;
    background-repeat: no-repeat;
    background-size: cover;
    cursor: pointer;
}


.file-background-logo {
    opacity: 0.3;
    width: 170px;
    height: 170px;
    position: absolute;
    bottom: 0;
    right: 0;
    top: 30px;
    left: calc(50% - 85px);
    background-Position: center;
    background-repeat: no-repeat;
    background-size: cover;
    cursor: pointer;
}

@media (min-width: 1441px) {

    .file-item-carousel {
        width: 100%;
        position: relative;
        float: left;
        height: 600px;
    }
}