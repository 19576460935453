.star {
    position: relative;
    float: left;
    width: 25px;
    margin: 0 10px;
    top: 2px;
    cursor: pointer;
    transition: all .4s ease-in-out;
    stroke: var(--main-font-color, #000);
    fill: var(--main-font-color, #000);
}
.star > svg polygon {
    display: none;
}

@media (max-width: 768px) {
    .star.active > svg polygon {
        display: block;
        fill: var(--main-font-color, #000);
    }
}

@media (min-width: 768px) {
    .star:hover > svg polygon,
    .star.active > svg polygon {
        display: block;
        fill: var(--main-font-color, #000);
    }
}

.audiovisual-info-container {
    width: 80% !important;
}

@media (max-width: 600px) {
    .audiovisual-info-container {
        width: 100% !important;
    }

    .audiovisual-description-h3 {
        font-size: 1.3em;
        margin-bottom: 0;
    }
    
    .audiovisual-description-h3-no-margin {
        margin-top: 0;
        font-size: 1.3em;
        margin-bottom: 0;
    }
}

.editorial-description-h3 {
    font-size: 1em;
    margin: 0;
    margin-left: 30px;
}