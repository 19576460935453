/* ------------------------------------------------------- */
/* -------------- COURSES ------------------------------- */
/* ------------------------------------------------------- */
.has-margin{
	width: calc(100% - 4px)!important;
	max-height: 396px;
	margin: 2px;
}

.items-content {
	position: relative;
	float: left;
	width: 100%;
	max-height: 400px;
	display: flex;
	flex-direction: column;
	overflow: hidden;
	cursor: pointer;
	z-index: 1;

	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}

.items-content:hover:before {
	pointer-events: none;
	content:'';
	position: absolute;
	z-index: 1;
	top: 35%;
	bottom: 0;
	left: 0;
	right: 0;
	opacity: 0.7;
	background-color: var(--card-hover, #000);
}

.items-content > .photo-container {
	position: relative;
	float: left;
	width: 100%;
	height: 400px;
}

.items-content > .photo-container > .photo {
	position: absolute;
	top: 0px;
	left: 0px;
	bottom: 0px;
	right: 0px;
	background-position: center center;
	background-repeat: no-repeat;
}

@media (max-width: 1000px) {
	.items-content > .photo-container > .photo {
		background-size: cover;
	}
}

.items-content > .info {
	position: absolute;
	width: 100%;
	top: 240px;
	opacity: 0;
	z-index: 2;

	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}
.items-content:hover .info{
	opacity: 1;
	visibility: visible;
	top: 40%;
	font-weight: bold;
	white-space: initial;
}
.items-content > .info > .title {
	display: none;
	position: relative;
	float: left;

	width: 100%;
	padding: 20px 20px 5px 20px;
	font-size: 26px;
	font-weight: bold;
	color: var(--title-hover);
	text-align: left;
	line-height: initial;
	/* autoprefixer: off */
	display: -webkit-box;
	overflow: hidden;
	-webkit-line-clamp: 2;
	-moz-line-clamp: 2;
	-ms-line-clamp: 2;
	-o-line-clamp: 2;
	line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
}

.items-content > .info > .text{
	width: 100%;
	padding: 0 20px 20px 20px;
	display: none;
	position: relative;
	float: left;
	width: 75%;
	height: 155px;
	font-size: 13px;
	color: var(--text-hover);
	text-align: left;
	font-weight: 100;
	overflow: hidden;
}
.items-content:hover .info > .text{
	height: 136px;
	opacity: 1;
	display: -webkit-box;
	overflow: hidden;
	line-height: 1.2em;
	/* autoprefixer: off */
	-webkit-line-clamp: 8;
	-moz-line-clamp: 8;
	-ms-line-clamp: 8;
	-o-line-clamp: 8;
	line-clamp: 8;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
}

.items-content > .info > .text > p {
	line-height: 30px;
}



.items-content > .icons {
	position: absolute;
	right: 20px;
	width: 150px;
	text-align: right;
	bottom: -40px;
	transition: all .4s ease-in-out;
	z-index: 3;
    display: flex;
}

.event-dates {
	width: 80px;
    z-index: 4;
}

.items-content:hover > .icons {
	opacity: 1;
	visibility: visible;
	bottom: 20px;
	transform: translateY(0);
}

.items-content > .icons > .star {
	position: relative;
	float: right;
	z-index: 3;
	width: 30px;
	margin-left: 10px;
	cursor: pointer;
	transition: all .4s ease-in-out;
}

.items-content > .icons > .star > svg polygon {
	display: none;
}
.items-content > .icons > .star > svg path {
	fill: #FFFFFF;
}
.items-content > .icons > .star:hover > svg polygon,
.items-content > .icons > .star.active > svg polygon {
	display: block;
	fill: #FFFFFF;
}

.items-content > .icons > .list {
	position: relative;
	float: right;
	z-index: 3;
	width: 30px;
	margin-left: 10px;
	cursor: pointer;
	transition: all .4s ease-in-out;
}

.items-content > .icons > .list > svg path {
	fill: #FFFFFF;
}
.items-content > .icons > .list:hover > svg path {
	stroke: #FFFFFF;
}

.limit-reached {
	position: absolute;
	z-index: 10;
	right: 5px;
	fill: white;
	width: 40px;
	height: 40px;
}

.items-content > .info > .author {
  font-weight: 100;
  width: 100%;
  font-size: 24px;
  color: var(--text-hover);
  text-align: left;
  line-height: initial;
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  padding: 5px 20px 5px 20px;
}