/* ------------------------------------------------------- */
/* -------------- MOST VIEWED ---------------------------- */
/* ------------------------------------------------------- */
.items-content {
	position: relative;
    float: left;
	width: 100%;
	display: flex;
	flex-direction: column;
	overflow: hidden;
	cursor: pointer;
}
	.items-content:hover:before {
		pointer-events: none;
		content:'';
		position: absolute;
		z-index: 1;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		background-image: -webkit-linear-gradient(to top, rgba(var(--card-hover-rgb, 0,0,0), 0.9) 0.0%, rgba(var(--card-hover-rgb, 0,0,0), 0.2) 80.0%);
		background-image: -moz-linear-gradient(to top, rgba(var(--card-hover-rgb, 0,0,0), 0.9) 0.0%, rgba(var(--card-hover-rgb, 0,0,0), 0.2) 80.0%);
		background-image: -ms-linear-gradient(to top, rgba(var(--card-hover-rgb, 0,0,0), 0.9) 0.0%, rgba(var(--card-hover-rgb, 0,0,0), 0.2) 80.0%);
		background-image: -o-linear-gradient(to top, rgba(var(--card-hover-rgb, 0,0,0), 0.9) 0.0%, rgba(var(--card-hover-rgb, 0,0,0), 0.2) 80.0%);
		background-image: linear-gradient(to top, rgba(var(--card-hover-rgb, 0,0,0), 0.9) 0.0%, rgba(var(--card-hover-rgb, 0,0,0), 0.2) 80.0%);
	}

		.items-content > .photo-container {
			position: relative; 
			float: left; 
			width: 100%; 
			padding: 30% 0px;
		}

			.items-content > .photo-container > .photo {
				position: absolute; 
				top: 0px; 
				left: 0px; 
				bottom: 0px; 
				right: 0px; 
				background-position: center top;
				background-repeat: no-repeat; 
				background-size: cover;
			}

	.items-content > .info {
		position: absolute;
		width: 100%;
		height: 100%;
		z-index: 2;
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		padding-bottom: 20px;
	}

	.items-content > .info > .title,
	.items-content > .info > .author {
		width: 100%;
		padding-left: 10px;
		padding-right: 10px;
		padding-top: 5px;
		font-size: 12px;
		color: var(--title-hover);
		font-weight: 100;
		text-align: left;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		transition: all .4s ease-in-out;
		max-height: calc(10px + 3.3em);
		z-index: 2;
	}
		.items-content:hover .title,
		.items-content:hover .author {
			opacity: 1;
			z-index: 2;
			visibility: visible;
			font-size: 14px!important;
			transform: translateY(0);
			font-weight: bold;
			white-space: initial;
			line-height: 1.1em;
			/* autoprefixer: off */
			display: -webkit-box;
			overflow: hidden;
			-webkit-line-clamp: 3;
			-moz-line-clamp: 3;
			-ms-line-clamp: 3;
			-o-line-clamp: 3;
			line-clamp: 3;
			-webkit-box-orient: vertical;
			text-overflow: ellipsis;
		}
		.items-content:hover .author {
			font-weight: normal!important;
		}

	.items-content > .info > .text{
		visibility: hidden;
		position: absolute;
		bottom: -40px;
		width: 100%;
		height: 40px;
		font-size: 10px;
		color: #fff;
		padding: 10px;
		text-align: left;
		font-weight: 100;
		z-index: 2;

		transition: all .4s ease-in-out;
	}
		.items-content:hover .text{
			opacity: 1;
			z-index: 2;
			visibility: visible;
			margin-bottom: 45px;
			transform: translateY(0);
			line-height: 1em!important;
			display: -webkit-box;
			overflow: hidden;
			/* autoprefixer: off */
			-webkit-line-clamp: 2;
			-moz-line-clamp: 2;
			-ms-line-clamp: 2;
			-o-line-clamp: 2;
			line-clamp: 2;
			-webkit-box-orient: vertical;
			overflow: hidden;
			text-overflow: ellipsis;
		}

	.items-content > .info > .text > p {
		line-height: 14px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	.items-content > .info > .number{
		position: absolute;
		top: 0;
		z-index: 2;
		padding: 10px;
		width: 100%;
		font-size: 11px;
		text-transform: uppercase;
		font-weight: bold;
		color: var(--title-hover);
		text-align: left;
		-webkit-transition: 0.2s ease-out;
		-moz-transition: 0.2s ease-out;
		-o-transition: 0.2s ease-out;
		transition: 0.2s ease-out;
		line-height: initial;
	}
		.items-content:hover .number{
			opacity: 1;
			visibility: visible;
			font-size: 20px;
		}

	.items-content > .info > .line{
		position: absolute;
		z-index: 2;
		width: 15px;
		left: 10px;
		top: 25px;
		line-height: 1px;
		border-bottom: 1px solid var(--title-hover);
	}
		.items-content:hover .line{
			z-index: 2;
			width: 25px;
			top: 35px;
			line-height: 2px;
		}

	.items-content > .info > .menu {
		position: absolute;
		right: 10px;
		width: 80px;
		text-align: right;
		bottom: -30px;
		transition: all .4s ease-in-out;
		z-index: 3;
		opacity: 0;
	}
		.items-content:hover .menu {
			opacity: 1;
			visibility: visible;
			bottom: 10px;
			transform: translateY(0);
		}

		.items-content > .info > .menu > .star {
			position: relative;
			float: right;
			width: 18px;
			margin-left: 7px;
			cursor: pointer;
			transition: all .4s ease-in-out;
		}

			.items-content > .info > .menu > .star > svg polygon {
				display: none;
			}
			.items-content > .info > .menu > .star > svg path {
				fill: #FFFFFF;
			}
			.items-content > .info > .menu > .star:hover > svg polygon,
			.items-content > .info > .menu > .star.active > svg polygon {
				display: block;
				fill: #FFFFFF;
			}

		.items-content > .info > .menu > .list {
			position: relative;
			float: right;
			width: 18px;
			margin-left: 7px;
			cursor: pointer;
			transition: all .4s ease-in-out;
		}
			
			.items-content > .info > .menu > .list > svg path {
				fill: #FFFFFF;
			}
			.items-content > .info > .menu > .list:hover > svg path {
				stroke: #FFFFFF;
			}

.limit-reached {
	position: absolute;
	z-index: 10;
	right: 5px;
	fill: white;
	width: 40px;
	height: 40px;
}

.items-content > .info > .section {
	text-transform: uppercase;
	font-size: 12px;
	padding: 4px 10px 2px;
	font-weight: 100;
	color: black;
	background: white;
	text-align: left;
	line-height: initial;
	display: -webkit-box;
	overflow: hidden;
	-webkit-box-orient: vertical;
	margin-left: 10px;
	position: relative;
	float: left;
	border-radius: 3px;
	width: fit-content;
  }


.has-margin{
	padding: 2%;
  }