@media (min-width:770px) {
    .file-container {
        display: grid;
        justify-content: center;
        width: 100%;
        grid-gap: 15px;
        grid-template-columns: repeat(3, calc(33% - 7.5px));
    }

    .banner-text .item-title {
        font-size: 2.9rem!important;
    }

    .banner-text .item-subtitle {
        font-size: 1.9rem!important;
    }

    .contact-section {
        width: 40%;
        position: relative;
        float: right;
    }
}

@media (min-width:450px) AND (max-width: 769px) {
    .file-container {
        display: grid;
        width: 100%;
        grid-gap: 20px;
        grid-template-columns: repeat(2, calc(50% - 10px));
    }

    .contact-section {
        width: 100%;
        position: relative;
        float: right;
    }

    .subheader {
        margin-top: 25px;
        height: 200px!important;
        padding: 100px 50px!important;
    }

    .download {
        margin-top: -50px!important;
        right: 60px!important;
    }
}

@media (max-width:450px) {
    .subheader {
        margin-top: 25px;
        height: 200px!important;
        padding: 100px 25px!important;
    }
    .file-container {
        display: grid;
        width: 100%;
        grid-gap: 10px;
        grid-template-columns: 100%;
    }

    .contact-section {
        width: 35%;
        position: relative;
        float: right;
    }

    .download {
        margin-top: -50px!important;
        right: 60px!important;
    }
}


@media (min-width:769px) AND (max-width: 1279px) {
    .item-text {
        top: 300px!important;
    }
}

.subfooter {
    height: 100px;
    position: relative;
    float: left;
    width: 100%;
    padding: 10px 70px;
}


@media (min-width:769px) AND (max-width: 1279px) {
    .subheader {
        position: relative;
        float: left;
        width: 100%;
        height: 150px;
        padding: 30px 70px;
        line-height: 43px
    }

    .subheader-title {
        position: relative;
        width: 100%;
        max-width: 100%;
        overflow: hidden;
        float: left;
        font-size: 25px;
    }

    .banner-container {
        max-height: 500px;
    }

    .banner-video {
        overflow: hidden;
        max-width: 100%;
        width: 100%;
        object-fit: cover;
        transform: translate(0, -20%);
        background-repeat: no-repeat;
        background-size: cover;
    }
}

@media (min-width: 1279px) {
    .subheader {
        position: relative;
        float: left;
        width: 100%;
        height: 100px;
        padding: 30px 70px;
        line-height: 43px
    }

    .subheader-title {
        position: relative;
        width: 80%;
        max-width: 100%;
        overflow: hidden;
        float: left;
        font-size: 28px;
    }

    .banner-video {
        overflow: hidden;
        max-width: 100%;
        width: 100%;
        object-fit: cover;
        transform: translate(0, -10%);
        background-repeat: no-repeat;
        background-size: cover;
    }
}

.contact {
    position: relative;
    float: left;
    margin: 10px 0px 0px 10px;
}

.logo-navbar-reel {
    position: relative;
    float: left;
    max-width: 200px;
    max-height: 75px;
    color: black;
    cursor: pointer;
}

.social {
    float: right;
    position: relative;
    width: 20px;
    height: 20px;
    cursor: pointer;
    color: white;
    fill: white;
    margin: 0 5px;
    opacity: .8;
}

@media (max-width: 450px) {
    .social {
        margin: 5px;
    }
}

.social:hover {
    opacity: 1;
}

.social img {
    filter: brightness(0) invert(1);
    margin-bottom: 0 !important;
}

.socials {
    width: 100%;
    position: relative;
    float: right;
    margin-top: 10px;
}



.download {
    position: absolute;
    float: right;
    margin-top: 10px;
    right: 70px;
    text-align: center;
    display: inline-block;
    vertical-align: middle;
    cursor: pointer;
}

.reel-navbar {
    position: absolute;
    top: 0;
    width: 100%;
    padding: 30px 70px;
    height: 200px;
    background: linear-gradient(to top, rgba(255, 255, 0, 0), rgba(255, 255, 255, 0.62))
}

@media (max-width: 450px) {
    .reel-navbar {
        padding: 23px 25px;
    }
}

.reel-navbar-no-banner {
    height: 100px!important;
    position: relative!important;
    top: -15px;
}

.banner-dots li button:before {
    color:blue!important;
    font-family: 'slick', sans-serif;
    font-size: 6px;
    line-height: 20px;
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    content: '•!important';
    text-align: center;
    opacity: .25;
    -webkit-font-smoothing: antialiased;
}

.banner-dots li.slick-active button:before {
    color:greenyellow!important;
}

.skeleton {
    margin: 0 2%;
}
