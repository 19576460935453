/* ------------------------------------------------------- */
/* -------------- PREMIERE ------------------------------- */
/* ------------------------------------------------------- */
.has-margin{
	width: calc(100% - 4px)!important;
	margin: 2px;
}

.items-content {
	position: relative;
	float: left;
	width: 100%;
	display: flex;
	flex-direction: column;
	overflow: hidden;
	cursor: pointer;
}
.items-content:hover:before {
	pointer-events: none;
	content:'';
	position: absolute;
	z-index: 1;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background-image: -webkit-linear-gradient(to top, rgba(var(--card-hover-rgb, 0,0,0), 0.9) 10.0%, rgba(var(--card-hover-rgb, 0,0,0), 0.2) 80.0%);
	background-image: -moz-linear-gradient(to top, rgba(var(--card-hover-rgb, 0,0,0), 0.9) 10.0%, rgba(var(--card-hover-rgb, 0,0,0), 0.2) 80.0%);
	background-image: -ms-linear-gradient(to top, rgba(var(--card-hover-rgb, 0,0,0), 0.9) 10.0%, rgba(var(--card-hover-rgb, 0,0,0), 0.2) 80.0%);
	background-image: -o-linear-gradient(to top, rgba(var(--card-hover-rgb, 0,0,0), 0.9) 10.0%, rgba(var(--card-hover-rgb, 0,0,0), 0.2) 80.0%);
	background-image: linear-gradient(to top, rgba(var(--card-hover-rgb, 0,0,0), 0.9) 10.0%, rgba(var(--card-hover-rgb, 0,0,0), 0.2) 80.0%);
}

.items-content > .photo-container {
	position: relative;
	float: left;
	width: 100%;
	padding: 70% 0px;
}

.items-content > .photo-container > .photo {
	position: absolute;
	top: 0px;
	left: 0px;
	bottom: 0px;
	right: 0px;
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
}

.items-content > .premiere-title-start {
	position: absolute;
	z-index: 3;
	bottom: 100px;
	width: 100%;
	padding: 20px 20px 5px 20px;
	font-size: 22px;
	font-weight: 100;
	color: var(--title-hover);
	text-align: left;
	line-height: initial;
	overflow: hidden;
	text-overflow: ellipsis;

	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;

	display: -webkit-box;
	/* autoprefixer: off */
	-webkit-line-clamp: 3;
	-moz-line-clamp: 3;
	-ms-line-clamp: 3;
	-o-line-clamp: 3;
	line-clamp: 3;
	-webkit-box-orient: vertical;
}
.items-content:hover .premiere-title-start {
	opacity: 0;
	z-index: 2;
	margin-bottom: 70px;
	transform: translateY(0);
}

.info {
	position: absolute;
	z-index: 2;
	width: 100%;
	top: 240px;
	opacity: 0;

	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}
.items-content:hover .info{
	opacity: 1;
	visibility: visible;
	z-index: 2;
	top: 15px;
	font-weight: bold;
	white-space: initial;
}

	.items-content > .info > .section {
		text-transform: uppercase;
		font-weight: 100;
		font-size: 12px;
		padding: 4px 10px 2px;
		color: black;
		background: white;
		text-align: left;
		line-height: initial;
		display: -webkit-box;
		overflow: hidden;
		-webkit-box-orient: vertical;
		margin-left: 20px;
		margin-bottom: 10px;
		position: relative;
		float: left;
		border-radius: 3px;
	}

	.items-content > .info > .author{
		width: 100%;
		padding: 0px 20px;
		position: relative;
		float: left;
		z-index: 2;
		font-size: 16px;
		color: var(--text-hover);
		text-align: left;
		font-weight: 100;
	}

.items-content > .info > .title {
	display: none;
	position: relative;
	float: left;

	width: 100%;
	padding: 20px 20px 5px 20px;
	font-size: 26px;
	font-weight: bold;
	
	color: var(--title-hover);
	text-align: left;

	display: -webkit-box;
	overflow: hidden;
	text-overflow: ellipsis;
	line-height: 1.4em;
	/* autoprefixer: off */
	-webkit-line-clamp: 2;
	-moz-line-clamp: 2;
	-ms-line-clamp: 2;
	-o-line-clamp: 2;
	line-clamp: 2;
	-webkit-box-orient: vertical;
}

.items-content > .info > .text{
	width: 100%;
	padding: 0 20px 20px 20px;
	display: none;
	position: relative;
	float: left;
	height: 155px;
	z-index: 2;
	font-size: 16px;
	color: var(--title-hover);
	text-align: left;
	font-weight: 100;
	overflow: hidden;
}
.items-content:hover > .info > .title {
	padding: 5px 20px 5px 20px;
	color: var(--title-hover);
}
.items-content:hover .info > .text{
	opacity: 1;
	display: -webkit-box;
	overflow: hidden;
	/* autoprefixer: off */
	-webkit-line-clamp: 7;
	-moz-line-clamp: 7;
	-ms-line-clamp: 7;
	-o-line-clamp: 7;
	line-clamp: 7;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
	color: var(--text-hover);
}

.items-content > .info > .text > p {
	line-height: 30px;
}


.items-content > .line {
	position: absolute;
	z-index: 2;
	width: 30px;
	left: 23px;
	bottom: 100px;
	line-height: 2px;
	border-bottom: 1px solid var(--title-hover);
}
.items-content:hover .line {
	display: none;
	border-bottom: 1px solid var(--title-hover);
}

.items-content > .number{
	position: absolute;
	z-index: 2;
	bottom: -3px;
	width: 150px;
	font-size: 72px;
	text-transform: uppercase;
	font-weight: bold;
	
	color: var(--title-hover);
	padding: 20px;
	text-align: left;
	-webkit-text-stroke: 1px var(--title-hover);
	-webkit-text-fill-color: transparent;
	-webkit-transition: 0.2s ease-out;
	-moz-transition: 0.2s ease-out;
	-o-transition: 0.2s ease-out;
	transition: 0.2s ease-out;
	line-height: initial;
}
.items-content:hover .number {
	-webkit-text-fill-color: var(--title-hover);
	-webkit-text-stroke-color: var(--title-hover);
	color: var(--title-hover);
}

.event-dates {
	width: 80px;
    z-index: 4;
    display: none;
}

.items-content:hover .event-dates {
    display: block;
}

.items-content > .icons {
	position: absolute;
	right: 20px;
	width: 150px;
	height: 60px;
	text-align: right;
	bottom: -60px;
	transition: all .4s ease-in-out;
	z-index: 3;
	display: flex;
}
.items-content:hover > .icons {
	opacity: 1;
	visibility: visible;
	bottom: 0px;
	transform: translateY(0);
    display: flex;
    justify-content: flex-end;
}

.items-content > .icons > .star {
	position: relative;
	float: right;
	width: 30px;
	padding: 0 2px;
	margin-left: 10px;
	cursor: pointer;
	transition: all .4s ease-in-out;
}
.items-content:hover > .icons > .star {
	opacity: 1;
	visibility: visible;
}

.items-content > .icons > .star > svg polygon {
	display: none;
}
.items-content > .icons > .star > svg path {
	fill: var(--title-hover, #FFFFFF);
}
.items-content > .icons > .star:hover > svg polygon,
.items-content > .icons > .star.active > svg polygon {
	display: block;
	fill: var(--title-hover, #FFFFFF);
}

.items-content > .icons > .list {
	position: relative;
	float: right;
	width: 30px;
	padding: 0 2px;
	margin-left: 10px;
	cursor: pointer;
	transition: all .4s ease-in-out;
}
.items-content:hover > .icons > .list {
	opacity: 1;
	visibility: visible;
}

.items-content > .icons > .list > svg path {
	fill: var(--title-hover, #FFFFFF);
}
.items-content > .icons > .list:hover > svg path {
	stroke: var(--title-hover, #FFFFFF);
}

.limit-reached {
	position: absolute;
	z-index: 10;
	right: 5px;
	fill: var(--title-hover, #FFFFFF);
	width: 40px;
	height: 40px;
}