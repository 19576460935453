.home-licenses-container {
  animation: home-licenses-container-fadein 200ms forwards ease-out;
  display: flex;
  flex-direction: column;
}

@keyframes home-licenses-container-fadein {
  from {
    transform: scaleX(1.02);
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.home-license-element {
  background-color: #f5f5f5;
  transition: 200ms; 
}

.home-license-element:hover {
  background-color: #e5e5e5;
}

.home-license-element-button {
  transform: scale(1);
  transition: 200ms;
}

.home-license-element-button:hover {
  transform: scale(1.1);
}

.licence-listing-element-subcomponent-text {
  float: left;
  position: relative;
  width: 100%;
  margin: 5px 0 5px 0;
  text-overflow: ellipsis;
  overflow: hidden;
}