.storage-chart {
  width: 100%;
  position: relative;
  float: left;
  padding-top: 8px;
}

.chart-icon {
  position: relative;
  float: left;
  padding-right: 24px;
}

.chart {
  position: relative;
  float: left;
  display: block;
  width: 75%;
}


.complete-bar {
  width: 100%;
  height: 4px;
  background: #bbbbbb;
}

.used-bar {
  height: 4px;
  background: rgb(89, 207, 255);
  z-index: 1;
}

.percentage{ font-size: 10px; color: #a6a6a6; margin-top: 5px}