.bloomclouds-input,
.bloomclouds-select,
.bloomclouds-textarea {
  width: 100%;
  padding: 8px 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  margin-top: 6px;
  margin-bottom: 0;
  height: 36px
}

.bloomclouds-input:focus,
.bloomclouds-select:focus,
.bloomclouds-textarea:focus {
  border-color: #39a3f5;
  box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102,175,233,.6);
}

.bloomclouds-h4 {
  font-size: 14px;
  font-weight: normal;
  margin-left: -30px;
  padding: 4px;
  padding-left: 30px;
  background: #999999;
  color: white;
  padding-right: 10px;
  width: fit-content;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  white-space: break-spaces;
  display: flex;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 20px;
}

.bloomclouds-error {
  color: red;
  border-color: red;
}

.image-error {
  margin: 0 0 8px 20px;
}

.bloomclouds-textarea {
  resize: vertical;
}

.bloomclouds-submit-container {
  margin-top: 30px !important;
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 400px;
  max-width: 100%;
  align-self: center;
}

.bloomclouds-submit {
  background-color: #000;
  color: #fff;
  padding: 5px 30px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  text-align: center;
  display: inline-block;
}
.bloomclouds-cancel {
  background-color: #ccc;
  color: #000;
  padding: 5px 30px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  text-align: center;
  display: inline-block;
}

/* .w3-animate-zoom {animation:animatezoom 0.6s}@keyframes animatezoom{from{transform:scale(0)} to{transform:scale(1)}} */
.save-loader {
  animation: zoom-in 500ms;
}

.save-button {
  animation: zoom-out 500ms;
}

@keyframes zoom-in {
  from{transform:scale(0)}
  to{transform:scale(1)}
}

@keyframes zoom-out {
  from{transform:scale(1)}
  to{transform:scale(0)}
}

.demo-form {
  background-color: #f7f7f7;
  padding: 1px 0;
  max-width: 520px;
  margin: 35px auto;
  border-radius: 10px;
}

.font12 {
  font-size: 12px;
}