.grid {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    place-items: center;
    row-gap: 10px;
}

.public-grid, .profile-grid {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    place-items: center;
    row-gap: 10px;
}

@media (max-width: 960px) {
    .profile-grid {
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }
  }

.grid-item-image {
    overflow: hidden;
    max-height: 120px;
    cursor: pointer;
}

.unsplash-active {
    border: 5px solid #58b9cc;
}

.search {
    margin: 25px;
}