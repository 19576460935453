.login-footer-text {
  display: inline-block;
}

.login-footer-text a{
  font-weight: 400;
  color: black;
}


@media (min-width: 920px) {
  .login-footer {
    width: 100%;
    float: left;
    position: fixed;
    bottom: 0;
    height: 60px;
    font-size: 12px;
    text-align: center;
  }

  .login-footer-img {
    max-width: 200px;
    right: 60px;
    position: absolute;
    top: -5px;
  }
}

@media (max-width: 920px) {
  .login-footer {
    width: 100%;
    float: left;
    position: fixed;

    height: 60px;
    font-size: 12px;
    text-align: center;
  }

  .login-footer-text {
    float: left;
    position: relative;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 30px;
  }

  .login-footer-img {
    max-width: 200px;

  }

  .login-footer-img-container {
    float: left;
    position: relative;
    width: 100%;
  }

}