.account-drawer-container{
  float: left;
  position: relative;
  width: 100%;
  padding-top: 20px;
}

@media(max-width: 959px) {
  .account-drawer-container {
    padding-top: 60px;
    width: 280px;
  }
}

@media(max-width: 350px) {
  .account-drawer-container {
    padding-top: 60px;
    width: 75vw;
    min-width: 210px;
  }
}

.account-user-info{
  float: left;
  position: relative;
  width: 100%;
  padding: 23px 20px;
  height: 95px;
  border-bottom: rgba(200, 199, 204, 0.29) 1px solid;
  margin-bottom: 20px;
}

.account-user-profile{
  float: left;
  position: relative;
  width: 50px;
  height: 50px;
  background-repeat: no-repeat;
  background-size: 100%;
  border: 2px solid white;
  background-position: center;
  border-radius: 50%;
}

.account-user-name{
  float: left;
  position: relative;
  width: calc(100% - 60px);
  height: 100%;
  align-items: center;
  display: flex;
  padding-left: 20px;
  font-size: 20px;
}

.account-link-container{
  float: left;
  position: relative;
  width: 100%;
  height: 60px;
  align-items: center;
  display: flex;
  padding: 23px 20px;
  cursor: pointer;
  color: #676767;
}

.account-link-container a{
  color: #676767;
}

.account-link-container.selected {
  font-weight: bold;
  color: black!important;
}

.account-link-container.selected a{
  font-weight: bold;
  color: black!important;
}

.account-link-container:hover{
  color: black!important;
}

.account-link-container:hover a{
  color: black!important;
}

.account-link-logo{
  margin-left: 15px;
  margin-right: 33px;
}

.account-link {
  font-size: 18px;
}

.account-content {
  float: left;
  position: relative;
  width: calc(100% - 250px);
}

@media (max-width: 960px) {
  .account-content {
    width: 100%;
  }
}

.account-drawer-container a:focus {
  color: black;
}