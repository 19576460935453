
.footer-top {
  background-color: var(--footer-color, black);
  padding: 30px 0;
  float: left;
  width: 100%;
  position: relative;
  color: var(--footer-font-color, inherit);
}

.row-footer {
  margin-right: -15px;
  margin-left: -15px;
  float: none;
}

.footer-top img {
  max-height: 3.75rem;
  vertical-align: middle;
  border: 0;
}

.footer-top h6 {
  font-size: 0.8125rem;
  margin: 0;
}

footer .footer-top ul {
  list-style: none;
  margin: 0;
}

footer ul li a {
  color: var(--footer-font-color, inherit);
  padding: 15px;
  font-weight: 500;
  font-size: 12px;
  border-radius: 3px;
  text-decoration: none;
  position: relative;
  display: block;
  text-transform: capitalize!important;
}

.nowrap {
  white-space: nowrap;
  float: right;
}

@media (max-width: 768px) {
  .text-right {
    width: 100%;
    text-align: center;
  }

  .nowrap{
    justify-content: flex-end;
    flex-wrap: wrap;
    text-align: initial !important;
    float: inherit;
  }

  footer .footer-top ul {
    margin-top: 30px;
  }
}

@media (min-width: 768px) {
  .text-left {
    width: 50%;
    float: left;
    position: relative;
  }

  .text-right {
    width: 50%;
    float: left;
    position: relative;
  }

  .footer-social-links {
    float: right;
  }

  .text-left p {
    padding-right: 140px;
  }
}

.link-footer {
  color: var(--footer-font-color, darkgrey);
  font-size: 16px;
}

.link-footer:hover {
  color: var(--footer-font-color, white);
  font-weight: bold;
}

@media (max-width: 600px) {
  footer ul li a {
    padding: 5px!important;
  }
}

.link-support {
  float: left;
  position: relative;
  color: #7c7c7c;
  top: 3px;
}

.link-support:hover {
  color: white;
  font-weight: bold;
}

.public-footer-link {
  text-align: left;
}

@media (max-width: 500px) {
  .public-footer-link {
    text-align: center;
  }
}