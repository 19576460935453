.soundbar-volume-controller {
  position: absolute;
  top: -15px;
  right: 51px;
  width: 197px;
  display: flex;
  justify-content: center;
  box-shadow: lightgrey 0px 0px 5px 0px;
  background-color: white;
  border-radius: 20px; }

#soundbar-volume-controller-slider div div:nth-child(1) {
  background-color: var(--main-font-color, black); }

#soundbar-volume-controller-slider div button {
  background-color: var(--main-font-color, black); }

#soundbar-volume-controller-slider div div:nth-child(3) {
  background-color: var(--main-font-color, black); }
