.add-to-playlist-dialog-playlist {
  border-radius: 4px;
  box-shadow: 0 1px 1px 0 rgba(0,0,0,.1), 0 -1px 2px 0 rgba(0,0,0,.1);
}

.add-to-playlist-dialog-playlist:hover {
  box-shadow: 0 8px 20px 0 rgba(0,0,0,.16);
}

.add-to-playlist-dialog-playlist img{
  padding: 10px;
}

.add-to-playlist-dialog-playlist.active{
  background-color: #00acc1!important;
}

.add-to-playlist-dialog-playlist.active p{
 color: white!important;
}