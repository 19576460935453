.music-background {
  float: left;
  width: 105%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: fixed;
  filter: blur(10px);
  transform: scale(1.04);
}

.music-background:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.4);
}

.music-container {
  float: left;
  position: relative;
  width: 100%;
  padding: 100px 80px;
}

@media(max-width: 1000px) {
  .music-container {
    padding: 100px 50px;
  }
}

@media(max-width: 800px) {
  .music-container {
    padding: 90px 10px;
  }
}

.music{
  float: left;
  position: relative;
  width: 100%;
  padding: 50px;
  background-color: var(--main-color, white);
}

@media(max-width: 800px) {
  .music  {
    padding: 20px;
    margin-top: 30px;
  }
}

.music-image-container {
  float: left;
  position: relative;
  width: 200px;
  height: 200px;
}

@media(max-width: 800px) {
  .music-image-container {
    width: 100%;
    text-align: center;
  }

  .music-image-container img {
    display: inline-block;
  }
}

.music-details {
  float: left;
  position: relative;
  width: calc(100% - 200px);
  padding: 25px 0 25px 50px;
}

@media(max-width: 800px) {
  .music-details {
    text-align: center;
    width: 100%;
    padding: 20px;
  }
}

.music-title {
  float: left;
  position: relative;
  width: auto;
  font-size: 30px;
  font-weight: bold;
  line-height: 40px;
  color: var(--main-font-color, black);
}

.music-buttons {
  float: right;
  position: relative;
  width: 200px;
}

.music-button {
  float: left;
  position: relative;
  width: 40px!important;
  height: 40px!important;
  margin: 0 2.5px 0 2.5px;
}

@media(max-width: 800px) {
  .music-title {
    width: 100%;
  }

  .music-buttons {
    float: left;
    width: 100%;
    text-align: center;
    margin: 15px 0;
  }

  .music-button {
    float: none;
    display: inline-block!important;
  }
}

.music-button:hover {
  color: var(--main-font-color, black)!important;
}

.music-artist {
  float: left;
  position: relative;
  width: auto;
  color: var(--main-font-color, black);
  opacity: 0.5;
  line-height: 40px;
  font-size: 16px;
}

@media(max-width: 800px) {
  .music-artist  {
    display: inline-block;
    float: none;
  }
}

.music-artist:hover {
  opacity: 1;
  color: var(--main-font-color, black);
}

.music-album {
  float: left;
  position: relative;
  width: auto;
  color: var(--main-font-color, black);
  opacity: 0.5;
  line-height: 25px;
  font-size: 14px;
}

@media(max-width: 800px) {
  .music-album  {
    display: inline-block;
    float: none;
  }
}

.music-album:hover {
  opacity: 1;
  color: var(--main-font-color, black);
}

.music-internal-artist {
  width: 18px;
  height: 18px;
  margin-left: 10px;
  margin-top: 10px;
  opacity: 0.5;
}

@media(max-width: 800px) {
  .music-internal-artist {
    margin-top: -3px;
  }
}

.music-waveform {
  float: left;
  position: relative;
  width: 100%;
  filter: brightness(85%);
  height: 50px;
  margin-top: 15px;
}

.music-waveform img {
  height: 100%;
  width: 100%;
  object-fit: fill;
  opacity: 0.5;
  filter: drop-shadow(2px 4px 6px black);
}

.music-action-button {
  position: absolute!important;
  background-color: #d6d6d6 !important;
  width: 70px!important;
  height: 70px!important;
  opacity: 0.9!important;
  top: calc(50% - 35px)!important;
  left: calc(50% - 35px)!important;
}

.music-action-button:hover {
  background-color: #808080!important;
}

.music-action-icon {
  color: black!important;
  font-size: 30px!important;
}

.music-action-button:hover .music-action-icon {
  color: white!important;
}

.music-tags-container {
  float: left;
  position: relative;
  width: 100%;
  padding: 10px 50px 50px 50px;
  background-color: var(--main-color, white);
}

.music-tag-row {
  float: left;
  position: relative;
  width: 25%;
  padding: 5px 20px;
}

@media(max-width: 800px) {
  .music-tag-row {
    width: 50%;
    margin-bottom: 30px;
  }
}

.music-tag-group {
  float: left;
  position: relative;
  width: 100%;
  font-weight: bold;
  color: var(--main-font-color, black);
  margin-bottom: 10px;
}

.music-tag {
  float: left;
  position: relative;
  width: 100%;
  font-weight: 300;
  color: var(--main-font-color, darkgray);
}

.music-tag:hover {
  font-weight: 800;
  color: var(--main-font-color, black);
  cursor: pointer;
}

.music-similar-container {
  float: left;
  position: relative;
  width: 100%;
  padding: 10px 50px 50px 50px;
  background-color: var(--main-color, white);
  margin-top: 20px;
}

@media(max-width: 800px) {
  .music-similar-container {
    padding: 10px 20px 50px 20px;
  }
}

.music-section-title {
  float: left;
  position: relative;
  width: 100%;
  color: var(--main-font-color, black);
  font-size: 20px;
  padding: 20px;
  font-weight: bold;
}