.file-table-container {
  position: relative;
  float: left;
  background: white;
  width: 100%;
}

.file-table-row {
  width: 100%;
  float: left;
  position: relative;
  height: 80px;
  text-align: left;
}

.files-title {
  margin: 30px;
}
.file-table-row {
  border-bottom: solid 1px rgba(0, 0, 0, 0.05);
  border-top: solid 1px rgba(0, 0, 0, 0.05);
}

.file-table-shortfile  .ReactTable{
  position: relative;
  float: left;
  width: 80%;
  margin: 0 13%;
}

.file-table-row:hover {
  background-color: #f4f4f4;
}

.file-table-row:hover .file-table-play-icon {
  display: block!important;
}

.file-table-row:hover .file-table-play-button {
  display: block!important;
}

.file-table-row .file-table-play-icon {
  cursor: pointer;
}

.file-table-row:hover .file-table-add-to-favorites-button {
  visibility: visible!important;
}

.file-table-sound-active {
  top: 27px;
  color: gray;
  position: absolute;
  float: left;
  left: -28px;
}



.file-table-add-to-favorites-button {
  top: 20px!important;
  margin-left: 10px!important;
  visibility: hidden!important;
  width: 40px!important;
  height: 40px!important;
}

.file-table-remove-from-favorites-button {
  top: 20px!important;
  margin-left: 10px!important;
  width: 40px!important;
  height: 40px!important;
}

@media (min-width: 770px) {
  .file-table-favorite {
    float: left!important;
    position: relative!important;
    width: 8%!important;
    height: 100%;
    padding: 16px 9px;
  }

  .file-table-image-container {
    width: 105px;
    float: left;
    position: relative;
    height: 60px;
    margin: 10px 5px;
    border: 1px solid #ccc;
    border-radius: 5px;;
  }

  .file-table-title {
    margin-left: 200px;
    top: 15px;
    font-size: 18px;
    position: relative;
    float: left;
  }

  .check-all-checkbox {
    position: relative;
    margin-left: 9px!important;
    float: left;
  }
}

@media (max-width: 770px) {
  .file-table-favorite {
    float: left!important;
    position: relative!important;
    width: 8%!important;
    height: 100%;
    padding: 17px 0px;
  }

  .file-table-image-container {
    width: 75px;
    float: left;
    position: relative;
    height: 60px;
    margin: 10px 5px;
    border: 1px solid #ccc;
    border-radius: 5px;;
  }

  .file-table-title {
    margin-left: 30px;
    top: 15px;
    font-size: 18px;
    position: relative;
    float: left;
  }

  .file-table-name-container {
    margin-left: 0px;
    margin-right: 5px;
  }

  .file-table-name {
    margin: 0;
  }

  .check-all-checkbox {
    position: relative;
    float: left;
  }

  .file-table-actions {
    width: 10px!important;
  }
}

.file-image {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 2;
  top: 0;
  left: 0;
  bottom: 0;
}

.file-table-index {
  float: left;
  position: relative;
  width: 100%;
  font-size: 18px;
  color: darkgray;
  height: 100%;
  padding: 30px 0 0 30px;
}

@media (max-width: 600px) {
  .file-table-index {
    padding: 29px 0 0 15px;
  }
}

.file-table-image {
  float: left;
  position: relative;
  top: 14px;
  width: 60px;
  height: 60px;
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center;
  border-radius: 10px;
}

.file-table-play-button {
  background-color: black!important;
  display: none!important;
  top: 6px;
  left: 6px;
}

.file-table-index .file-table-play-button {
  top: -35px!important;
  left: -20px!important;
}

.file-table-pause-button {
  background-color: black!important;
  top: 6px;
  left: 6px;
}

.file-table-index .file-table-pause-button {
  top: -14px!important;
  left: -30px!important;
}

@media (max-width: 1435px) {
  .file-table-index .file-table-pause-button {
    top: -35px!important;
    left: -20px!important;

  }
}

@media (max-width: 600px) {
  .file-table-index .file-table-play-button {
    top: -28px!important;
    left: -14px!important;
  }
  .file-table-index .file-table-pause-button {
    top: -28px!important;
    left: -14px!important;
  }
}

.file-table-play-icon {
  position: relative!important;
  color: white!important;
  font-size: 30px!important;
  display: none!important;
  cursor: pointer!important;
}

.file-table-pause-icon {
  position: relative!important;
  color: white!important;
  font-size: 30px!important;
  display: none;
  cursor: pointer!important;
}

.file-table-name-container {
  width: 20%;
  float: left;
  position: relative;
  top: 20px;
  margin-right: 7px;
  text-overflow: ellipsis;
}

.file-table-name {
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
}

.file-table-name-auxiliary {
  font-size: 12px;
  font-weight: 300;
  color: darkgray;
  white-space: nowrap;
  overflow: hidden;
}

.file-table-name-auxiliary:hover {
  font-size: 12px;
  font-weight: 300;
  color: black;
}

.file-table-sound-wave-container {
  width: 22%;
  float: left;
  position: relative
}

.file-table-sound-wave {
  float: left;
  position: relative;
  width: 80%;
  height: 80px;
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center;
  filter: brightness(75%) sepia(100) saturate(00) hue-rotate(25deg);
}

.file-table-tags {
  width: 43%;
  float: left;
  position: relative;
  height: 100%;
  cursor: default;
  color: darkgray;
  font-size: 13px;
  display: inline-flex;
  align-items: center;
  overflow: hidden;
}


@media (max-width: 600px) and (max-width: 1250px) {
  .file-table-tags {
    width: 40%;
  }

}

.file-table-tags-file-container {
  width: 100%;
  float: left;
  position: relative;
}

.file-table-tags-file {
  color: darkgray;
  cursor: pointer;
  margin-right: 20px;
  font-size: 12px;
  float: left;
  position: relative;
}

.file-table-tags-file:hover{
  color: black;
}

.file-table-duration {
  width: 10%;
  text-align: center;
  float: left;
  position: relative;
  top: 30px;
  margin: 0 10px;
}

.file-table-actions {
  width: 10%;
  float: right;
  position: relative;
  top: 18px;
  right: 20px;
}

.file-table-action-button {
  width: 50%;
  position: relative;
  float: left;
}

.file-table-more {
  width: 40px!important;
  height: 40px!important;
}

.file-table-license-container {
  width: 10%;
  float: left;
  position: relative;
}

.file-table-row div[role="tooltip"] {
  transform: translate3d(0, 0, 0)!important;
}

.file-table-tags-file-selected {
  margin-right: 20px;
  font-size: 12px;
  float: left;
  position: relative;
  font-weight: bold;
  color: black;
  cursor: pointer;
}

.file-table-tags-file-selected:hover {
  color: darkgray;
}

.file-table-logo {
  width: 18px;
  height: 18px;
  margin-left: 10px;
  margin-top: -2px;
  opacity: 0.5;
}

.file-table-action-buttons {
  float: right;
  position: relative;
  width: 13%;
  top: 28px;
  max-width: 144px;
}

.file-table-action-icon {
  font-size: 21px!important;
  color: #727272;
  cursor: pointer;
  width: 18%!important;
  margin-left: 7%;
}

.file-table-action-icon:hover {
  color: black;
}

.suggestions-container{
  position: relative;
  float: left;
  width: 100%;
  padding: 0 20px;
  text-align: center;
}

.suggestion-elements-container{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 20px;
}

.suggestion-element{
  margin: 0 10px 10px 0;
  max-width: calc(50% - 10px);
}

@media (min-width: 670px) and (max-width: 950px)  {
  .suggestions-container{
    padding: 0;
  }

  .suggestion-elements-container{
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-evenly;
  }
}

@media (max-width: 910px) {
  .suggestion-elements-container .suggestion-element:last-child {
    display: none !important;
  }
}

@media (max-width: 670px) {
  .suggestion-elements-container{
    display: grid!important;
    grid-template-columns: auto auto;
    justify-content: space-evenly;
  }

  .suggestion-element{
    max-width: 100%;
  }
}

@media (max-width: 1180px){
  .file-table-action-buttons {
    width: 18%;
  }
}

@media (min-width: 600px) and (max-width: 960px){
  .file-table-action-buttons {
    width: 30%;
  }
}

@media (max-width: 600px) {
  .file-table-action-buttons {
    width: 35%;
    text-align: right;
  }
}

.video {
  float: left;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.add-selected-button {
  position: relative;
  float: right;
  margin: 8px 30px;
  border-radius: 3px;
  color: white;
  padding: 5px;
  background: black;
  cursor: pointer;
}

.file-table-head{
  position: relative;
  float: left;
  height: 50px;
  width: 100%;
}