.ticket-button {
    background: rgba(255,255,255,0.7)!important;
    height: 24px!important;
    width: 24px!important;
}

.ticket-button:hover, .ticket-button:focus {
    background: rgba(255,255,255,0.5)!important;
}


.calendar-toggle-switch {
    position: relative;
    width: 50px;
    display: inline-block;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    text-align: left;
  }
  .calendar-toggle-switch-checkbox {
    display: none;
  }
  .calendar-toggle-switch-label {
    display: block;
    overflow: hidden;
    cursor: pointer;
    border: 0 solid #ccc;
    border-radius: 20px;
    margin: 0;
    height: 24px;
  }
  .calendar-toggle-switch-inner {
    display: block;
    width: 200%;
    margin-left: -100%;
    transition: margin 0.3s ease-in 0s;
  }
  .calendar-toggle-switch-inner::before, .calendar-toggle-switch-inner::after {
    display: block;
    float: left;
    width: 50%;
    /* height: 34px; */
    padding: 0;
    line-height: 24px;
    font-size: 12px;
    color: white;
    /* font-weight: bold; */
    box-sizing: border-box;
  }
  .calendar-toggle-switch-inner:before {
    content: attr(data-yes);
    padding-left: 10px;
    padding-right: 20px;
    background-color: #76ee57;
    color: transparent;
    text-align: center;
    box-shadow: 0 0px 10px #34ba12 inset;
  }
  .calendar-toggle-switch-disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  .calendar-toggle-switch-disabled::before {
    background-color: #ccc;
    cursor: not-allowed;
  }
  .calendar-toggle-switch-inner::after {
    content: attr(data-no);
    padding-right: 10px;
    padding-left: 20px;
    background-color: #ccc;
    color: transparent;
    text-align: center;
    box-shadow: 0 0px 10px grey inset;
  }
  .calendar-toggle-switch-switch {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    margin: 3px;
    background: #fff;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 18px;
    border: 0 solid #ccc;
    border-radius: 20px;
    transition: all 0.3s ease-in 0s;
  }
  .calendar-toggle-switch-checkbox:checked + .calendar-toggle-switch-label .calendar-toggle-switch-inner {
    margin-left: 0;
  }
  .calendar-toggle-switch-checkbox:checked + .calendar-toggle-switch-label .calendar-toggle-switch-switch {
    right: 6px;
  }