.public-content-creator-container {
  float: left;
  position: relative;
  width: 100%;
  background-color: #d3d3d34d;
  border-radius: 50px;
  margin-bottom: 30px;
}

.public-content-creator-title {
  text-align: center;
  float: left;
  position: relative;
  width: 100%;
  color: var(--main-font-color, rgb(0, 0, 0));
}

.public-content-creator-image-container {
  float: left;
  position: relative;
  height: 80px;
  width: 80px;
}

.public-content-creator-info-container {
  display: table;
  width: calc(100% - 120px);
  table-layout: fixed;
  height: 80px;
}

.public-content-creator-name {
  width: auto;
  font-size: 20px;
  font-weight: bold;
  padding-left: 20px;
  display: table-cell;
  vertical-align: middle;
}

.public-content-creator-name:hover {
  text-decoration: underline;
  cursor: pointer;
}

.public-content-creator-tag {
  width: auto;
  max-width: 200px;
  font-weight: 400;
  cursor:  pointer!important;
  height: 30px!important;
  font-size: 12px!important;
  margin: 3px 4px 3px 4px!important;
}

.public-content-creator-tag-container {
  width: 100%;
  float: left;
  position: relative;
  padding: 10px 15px;
}

.public-content-creator-content-container {
  float: left;
  position: relative;
  width: 100%;
}

.public-content-creator-remove-button {
  font-size: 21px;
  color: #727272;
  cursor: pointer;
  width: 30px;
  margin: -3px 0 0 30px;
}

.public-content-creator-remove-button:hover {
  color: black;
}

.public-content-creator-country {
  width: auto;
  padding-left: 20px;
  display: table-cell;
  vertical-align: middle;
  text-align: right;
  color: #676767;
}
