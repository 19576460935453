.original-music-subcomponent{
  float: left;
  position: relative;
  width: 100%;
  padding: 10px 30px 10px 30px;
}

.original-music-subcomponent h4 {
  margin: 15px 0 15px 0;
  float: left;
  position: relative;
  width: 100%;
}

.original-music-subcomponent-row{
  float: left;
  position: relative;
  width: 100%;
  margin: 3px 0 3px 0;
}

.original-music-subcomponent-row-key{
  float: left;
  position: relative;
  width: 200px;
  font-size: 14px;
}

.original-music-subcomponent-row-value{
  float: left;
  position: relative;
  width: calc(100% - 200px);
}