.reels-content {
  width: 100%;
  height: 100%;
  position: relative;
  float: left;
}

@media(max-width: 959px) {
  .reels-drawer-container {
    padding-top: 60px;
    width: 280px;
  }
}

@media(max-width: 350px) {
  .reels-drawer-container {
    padding-top: 60px;
    width: 75vw;
    min-width: 210px;
  }
}

.reels-user-info{
  float: left;
  position: relative;
  width: 100%;
  padding: 23px 20px;
  height: 95px;
  border-bottom: rgba(200, 199, 204, 0.29) 1px solid;
  margin-bottom: 20px;
}

.reels-user-profile{
  float: left;
  position: relative;
  width: 50px;
  height: 50px;
  background-repeat: no-repeat;
  background-size: 100%;
  border: 2px solid white;
  background-position: center;
  border-radius: 50%;
}

.reels-user-name{
  float: left;
  position: relative;
  width: calc(100% - 60px);
  height: 100%;
  align-items: center;
  display: flex;
  padding-left: 20px;
  font-size: 20px;
}

.reels-link-container{
  float: left;
  position: relative;
  width: 100%;
  height: 60px;
  align-items: center;
  display: flex;
  padding: 23px 20px;
  cursor: pointer;
  color: #676767;
}

.reels-link-container a{
  color: #676767;
}

.reels-link-container.selected {
  font-weight: bold;
  color: black!important;
}

.reels-link-container.selected a{
  font-weight: bold;
  color: black!important;
}

.reels-link-container:hover{
  color: black!important;
}

.reels-link-container:hover a{
  color: black!important;
}

.reels-link-logo{
  margin-left: 15px;
  margin-right: 33px;
}

.reels-link {
  font-size: 18px;
}

.reels-drawer-container a:focus {
  color: black;
}

.reels-topbar-container {
  float: left;
  /*position: relative;*/
  width: 100%;
  background-color: #ffffff;
  height: 70px;
  z-index: 199;
  padding: 0;
  position: fixed;
  top: 0;
  box-shadow: 0 1px 10px 0 rgba(0,0,0,.1);
}

.topbar-container {
  position: relative;
  float: left;
  width: 100%;
}

.topbar-menu-button {
  position: relative;
  float: left;
  margin: 10px 0 0 15px!important;
}

.account-drawer-container {
  height: 100vh;
}

.tags-section-container {
  height: 50vh;
  max-height: 50vh;
  overflow: hidden;
  margin: 0 10px;
}
.tags-container {
  position: relative;
  float: left;
  height: 100%;
  width: 100%;
  height: 35vh;
  overflow-y: scroll;
}

@media (min-width:450px) AND (max-width: 768px) {
  .file-container {
    width: 100%;
  }
}
.file-uploader-tags-container{
  position: relative;
  float: left;
  max-height: 50vh;
}

.clear-share {
  position: absolute;
  top: 27px;
  left: 107px;
  color: red;
  cursor: pointer;
}

.clear-share:hover {
  background: rgba(145, 5, 0, 0.47);
  height: 18px;
  width: 18px;
  border-radius: 50%;
}

.clear-share > svg {
  font-size: 19px!important;
}

@media (min-width: 770px) {
  .reels-maker {
    width: 32%;
    background: white;
    height: 100%;
    position: relative;
    float: left;
    max-width: 450px;
    min-height: 100vh;
    box-shadow: 0 1px 10px 0 rgba(0, 0, 0, .1);
  }

  .storage-content {
    width: 100%;
    position: relative;
    float: left;
    margin: 15px;
    max-width: 450px;
  }

  .reel-files-table {
    position: relative;
    float: left;
    margin: 0 15px;
    padding: 20px;
    width: 65%;
  }

  .add-file-reel { display: none }

  .reel-files-clear{
    display: none
  }
}

@media (max-width: 770px) {
  .reels-maker {
    width: 100%;
    background: white;
    height: 100%;
    position: relative;
    float: left;
    min-height: 100vh;
  }

  .storage-content {
    width: 100%;
    position: relative;
    float: left;
    margin: 15px;
  }

  .reel-files-table {
    padding: 20px;
    width: 90%;
    position: absolute;
    z-index: 10;
    height: 100%;
    right: 0;
    background: white;
    box-shadow: 0 1px 10px 0 rgba(0, 0, 0, .4);
    transition: 0.3s;
  }

  .hide-file-selector {
    display: none;
  }

  .reel-files-clear{
    position: absolute; top: 15px; right: 15px;
  }
}

.reels-maker-mode-select {
  width: 100%;
  position: relative;
  float: left;
  padding: 15px 10px;
  display: flex;
  justify-content: center;
}

.reel-create-button {
  height: 50px;
  text-align: center;
  background: #000000;
  color: white;
  border-radius: 5px;
  min-width: 150px;
  line-height: 50px;
  margin: 0 10px;
  cursor: pointer;
}

.flap-container {
  width: 100%;
  height: 100%;
  position: relative;
  float: left;
}

.flap {
  width: 50%;
  position: relative;
  float: left;
  border: 1px solid #CCCCCC;
  text-align: center;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  height: 50px;
  line-height: 50px;
  cursor: pointer;
  background: #adadad;
}

.selected {
  background: white;
  border-bottom: 0px;
  cursor: default;
}

.reel-body {
  position: relative;
  float: left;
  width: 100%;
  height: 100%;
}

.form-body {
  position: relative;
  float: left;
  height: 70vh;
  width: 100%;
}

.reel {
  position: relative;
  float: left;
  padding: 10px;
  overflow-y: auto;
  width: 100%;
  height: 54vh;
}

@media (min-width:1000px) AND (max-width: 1400px) {
  .reel {
    height: 50vh;
  }
}

.file {
  position: relative;
  float: left;
  width: 100%;
  min-width: 335px;
  border-bottom: 1px solid #ccc;
}

.file-description {
  position: relative;
  text-overflow: ellipsis;
  float: left;
  margin: 15px 0;
  max-width: 130px;
}

.reel-file-image {
  width: 105px;
  float: left;
  position: relative;
  height: 60px;
  margin: 10px 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.video {
  float: left;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.reel-footer {
  position: relative;
  float: left;
  width: 100%;
  border-top: 1px solid #ccc;
  display: flex;
  justify-content: center;
  padding: 20px 30px;
}

.action-button {
  height: 50px;
  text-align: center;
  background: #000000;
  color: white;
  border-radius: 5px;
  min-width: 150px;
  line-height: 50px;
  margin: 0 10px;
  cursor: pointer;
}

.reels-button {
  padding: 10px 20px;
  margin: 0 15px;
  border-radius: 3px;
  background: black;
  color: white;
  min-width: 150px;
  text-align: center;
  cursor: pointer;
}

.minor-priority{
  background: #CCCCCC!important;
  color: #000000 !important;
  cursor: pointer;
}

input[type="color"] {
  -webkit-appearance: none;
  border: none;
  width: 32px;
  height: 32px;
  cursor: pointer;
}
input[type="color"]::-webkit-color-swatch-wrapper {
  padding: 0;
}
input[type="color"]::-webkit-color-swatch {
  border: none;
}

.filter-button {
  position: relative;
  float: left;
  width: 150px;
  border-radius: 3px;
  border: 1px solid #6C6C6C;
}

.reel-table-row{
  width: 100%;
  height: 50px;
  border-bottom: 1px solid #f2f2f2;
}

.reel-table-row:hover {
  background-color: #f4f4f4;
}

.reel-table-name {
  position: relative;
  float: left;
  height: 100%;
  width: 67%;
  text-overflow: ellipsis;
  font-size: 18px;
  padding: 18px;
}

.reel-table-actions {
  position: relative;
  float: left;
  width: 30%;
  height: 100%;
}

.reel-table-button {
  width: 25%;
  position: relative;
  float: left;
}

.empty-reel {
  width: 80%;
  height: 210px;
  margin: 10%;
  padding: 40px 0;
  background: #f5f4f4;
  font-size: 13px;
  color: #6b6b6b;
  border-radius: 9px;
}

.grab-file {
  position: relative;
  float: left;
  padding: 28px 0px;
  cursor: grab;
}

.min-play-button {
  z-index: 11;
  position: absolute;
  left: calc( 50% - 10px);
  top: calc(50% - 12px);
  transition: width 500ms;
  display: none;
}

.file-image:hover > .min-play-button{
  display: block;
}

.reel-file-image:hover > .min-play-button{
  display: block;
}